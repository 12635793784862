import React from "react";
import { Form } from "react-bootstrap";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

import { getIn, useField } from 'formik'
import { InputCurrency } from './InputCurrency';

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export const isRequiredField = ({ validationSchema }, name) =>
  !!getIn(validationSchema.describe().fields, name).tests.find(
    testName => testName === 'required'
  )

export function FieldInputCurrency(props2) {
  const {
    formik,
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, isValid, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = false,
    customFeedbackLabel,
    type = "text",
    hintText,
    required,
    ...props
  } = props2;

  const onChange = (value) => {
    setFieldValue(field.name, value);
  }

  return (
    <>
      {label && <label>{label}{required &&<span className="text-danger">*</span>}</label>}
      <InputCurrency
        type={type}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
        onChange={onChange}
        autoComplete="off"
      />
      {
        hintText &&
        (
          <Form.Text className="text-muted">
            {hintText}
          </Form.Text>
        )
      }
      {(withFeedbackLabel || errors[field.name]) && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}

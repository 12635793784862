import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { CODE_SUCCESS } from '../../../constants/system/code';
import reactToastify from '../../../plugin/react-toastify';
import { processErrorFormik } from '../../../utils/axios';
import { classList } from '../../../utils/DOM/class';
import { FormDialogHeader } from "./FormDialogHeader";

export function FormDialog({
  title,
  show,
  onHide,
  initData,
  onSubmit,
  onSuccess,
  messageSuccess,
  ComponentForm,
  size = "md",
  ...props
}) {
  // Permissions UI Context
  const [loading, setLoading] = useState(false);

  // server request for saving permission
  const saveData = async (data, formik) => {
    const { setErrors } = formik || {};
    try {
      setLoading(true);
      const response = await onSubmit({
        data,
        formik
      });
      setLoading(false);
      if (!response || response.data?.code === CODE_SUCCESS) {
        if (onSuccess) {
          onSuccess(response.data.data)
        }

        if (messageSuccess) {
          reactToastify.success(messageSuccess)
        } else {
          reactToastify.editSuccess();
        }
        setTimeout(() => {
          onHide();
        }, 300)
      } else if (response.data?.message){
        reactToastify.error(response.data?.message)
      } else {
        reactToastify.actionFail();
      }
    } catch (error) {
      processErrorFormik(error, formik);
      setLoading(false);
    }
  };
  return (
    <Modal size={size} show={show} onHide={onHide}>
      <FormDialogHeader
        item={initData}
        title={title}
        actionsLoading={loading}
      />
      <div className={classList("overlay", loading && "overlay-block")}>
        {loading && (
          <div className="overlay-layer zindex-2 bg-transparent">
            <div className="spinner-border text-success" />
          </div>
        )}

        <ComponentForm
          save={saveData}
          actionsLoading={loading}
          item={initData}
          onHide={onHide}
          {...props}
        />
      </div>
    </Modal>
  );
}

import Button from "@material-ui/core/Button";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { toDateTime } from "../../../../../utils/filter";
import { isEmpty } from "../../../../../utils/typeof";

const CustomToggle = React.forwardRef((props, ref) => {
  const {
    children,

    onClick
  } = props;
  const handleClick = e => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <Button ref={ref} size="sm" className="button-sm" onClick={handleClick}>
      {children}
    </Button>
  );
});

const AdvisoryItem = props => {
  const { showMenu = false, advisory, deleteItem, editItem } = props;
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  const {
    id: idAdvisory,
    content,
    staff,
    type,
    appointment,
    meet_up_at,
  } = advisory;

  return (
    <div className="d-flex align-items-center justify-content-between p-4">
      <div className="d-flex flex-column mr-2 w-100">
        <div className="d-flex align-items-center">
          <a
            href="#"
            className="font-weight-bold text-dark-75 font-size-lg text-hover-primary"
          >
            {staff?.full_name}
          </a>
          <span>&nbsp;&middot;&nbsp;</span>
          {!isEmpty(meet_up_at) && (
            <span className="text-muted">{toDateTime(meet_up_at)}</span>
          )}
          {!isEmpty(appointment) && (
            <a
              href="#"
              className="font-weight-bold text-dark-75 font-size-lg text-hover-primary ml-auto"
            >
              Hẹn {toDateTime(appointment)}
            </a>
          )}
        </div>
        <div className="d-flex align-items-center mt-1">
          <span className="font-weight-medium mr-1 text-dark-75 font-size-lg">
            {content}
          </span>
          {showMenu && (
            <Dropdown className="ml-2 d-inline-flex align-items-center ml-auto">
              <Dropdown.Toggle
                aria-describedby="popper-content-filter"
                size="xs"
                as={CustomToggle}
              >
                <i className="flaticon-more icon-nm" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="min-w-5rem py-0">
                <Dropdown.Item onClick={e => {
                  editItem(advisory);
                }}>Sửa</Dropdown.Item>
                <Dropdown.Item onClick={e => {
                  deleteItem(advisory);
                }}>Xóa</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        {advisory?.class && <div>Class: <a href={'/class/' + advisory?.class?.id}>{advisory?.class?.name}</a></div>}
      </div>
    </div>
  );
};

export default AdvisoryItem;

import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import AddIcon from "@material-ui/icons/Add";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from 'react-intl';
import { keyMessages } from '../../../../../../_theme/i18n';
import { ADD, EXPORT, IMPORT } from "../../../../../../constants/system/permission";
import { usePermissionContext } from "../../../../../components/PermissionContext";
import { useDataTableContext } from "../DataTableContext";
import { useDataTableUIContext } from "../DataTableUIContext";

const ToolBar = props => {
  const intl = useIntl();
  const messages = intl?.messages;
  const dataTableUIContext = useDataTableUIContext();
  const dataTableContext = useDataTableContext();
  const permissionContext = usePermissionContext();
  const pageName = dataTableContext?.pageName;
  const dataTableUIProps = useMemo(() => {
    return {
      isOpenSettingColumns: dataTableUIContext.isOpenSettingColumns,
      clickNewItem: dataTableUIContext.clickNewItem,
      clickImportData: dataTableUIContext.clickImportData,
      clickExportData: dataTableUIContext.clickExportData,
      setOpenSettingColumns: dataTableUIContext.setOpenSettingColumns,
      toolbars: dataTableUIContext.toolbars,
      ids: dataTableUIContext.ids
    };
  }, [dataTableUIContext]);
  const {
    ids,
    isOpenSettingColumns,
    clickNewItem,
    clickImportData,
    clickExportData,
    setOpenSettingColumns
  } = dataTableUIProps || {};
  const toggleSettingColumn = () => {
    setOpenSettingColumns(!isOpenSettingColumns);
  };
  const checkActionVisible = actionName => {
    // if (pageName && actionName) {
    //   return permissionContext.checkCanPage(pageName, actionName);
    // }
    return true;
  };
  return (
    <div className="d-inline-flex align-items-center">
      {clickNewItem && checkActionVisible(ADD) && (
        <Tooltip title={messages[keyMessages['DATATABLE.TOOLBAR.CREATE']]}>
          <div
            onClick={clickNewItem}
            className="d-flex flex-column align-items-center justify-content-center button-custom pointer px-1 ml-2"
          >
            <PlaylistAddIcon
              className="pointer"
              style={{ fontSize: 24, color: "#757575" }}
            />
            <div className="line-height-initial">{messages[keyMessages['DATATABLE.TOOLBAR.CREATE']]}</div>
          </div>
        </Tooltip>
      )}
      {
        <Tooltip title={messages[keyMessages['DATATABLE.TOOLBAR.SHOW_HIDDEN']]}>
          <div
            onClick={toggleSettingColumn}
            className="d-flex flex-column align-items-center justify-content-center button-custom pointer px-1 ml-2"
          >
            <ViewColumnIcon
              className="pointer"
              style={{ fontSize: 24, color: "#757575" }}
            />
            <div className="line-height-initial">{messages[keyMessages['DATATABLE.TOOLBAR.COLUMN']]}</div>
          </div>
        </Tooltip>
      }
      {clickImportData && checkActionVisible(IMPORT) && (
        <Tooltip title={messages[keyMessages['DATATABLE.TOOLBAR.IMPORT']]}>
          <div
            onClick={clickImportData}
            className="d-flex flex-column align-items-center justify-content-center button-custom pointer px-1 ml-2"
          >
            <SaveAltIcon
              className="pointer"
              style={{ fontSize: 24, color: "#757575" }}
            />
            <div className="line-height-initial">{messages[keyMessages['DATATABLE.TOOLBAR.IMPORT']]}</div>
          </div>
        </Tooltip>
      )}
      {clickExportData && checkActionVisible(EXPORT) && (
        <Tooltip title={messages[keyMessages['DATATABLE.TOOLBAR.EXPORT']]}>
          <div
            onClick={clickExportData}
            className="d-flex flex-column align-items-center justify-content-center button-custom pointer px-1 ml-2"
          >
            <GetAppIcon
              className="pointer"
              style={{ fontSize: 24, color: "#757575" }}
            />
            <div className="line-height-initial">{messages[keyMessages['DATATABLE.TOOLBAR.EXPORT']]}</div>
          </div>
        </Tooltip>
      )}
      {dataTableUIProps.toolbars.map((toolbar, index) => {
        const { actionName, label, title, icon, visible, event } = toolbar;
        return (
          (visible ? visible({ ids }) : checkActionVisible(actionName)) && (
            <Tooltip
              key={`toolbar-datatable-item-${index}`}
              title={title || label}
            >
              <div
                onClick={event}
                className="d-flex flex-column align-items-center justify-content-center button-custom pointer px-1 ml-2"
              >
                <Icon style={{ fontSize: 24, color: "#757575" }}>{icon}</Icon>
                <div className="line-height-initial">{label}</div>
              </div>
            </Tooltip>
          )
        );
      })}
    </div>
  );
};

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

export default ToolBar;

import React, {useState} from "react";
import * as columnFormatters from "../../../../../common/DataTable/pages/table/column-formatters";
import {DataTableUIProvider} from "../../../../../common/DataTable/pages/DataTableUIContext";
import {DataTableProvider} from "../../../../../common/DataTable/pages/DataTableContext";
import {EditDialog} from "../../../../../common/DataTable/pages/edit-dialog/EditDialog";
import {LoadingDialog} from "../../../../../common/DataTable/pages/loading-dialog/LoadingDialog";
import {DataTableCard} from "../../../../../common/DataTable/pages/DataTableCard";
import {CLASS_RESOURCE, CLASS_STUDENT_CHANGE} from "../../../../../../../constants/system/resource";
import * as requestFromServer from "../../../../../common/DataTable/_redux/entitiesCrud";
import {MAP_STUDENT_CHANGE_STATUS} from "../../../../../../../constants/MapConvert";
import {StudentChangeEditForm} from "./StudentChangeEditForm";
import {DataTableDeleteDialog} from "../../../../../common/DataTable/pages/delete-dialog/DataTableDeleteDialog";


const ListStudentChangeOfClass = (props) => {
  const {
    classId,
    classInformation
  } = props;

  const [students, setStudents] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [showDialogForm, setDialogForm] = useState(false);
  const [showDialogDelete, setDialogDeleteTeacher] = useState(false);
  const openDialogForm = () => setDialogForm(true);
  const closeDialogForm = () => {
    setSelectedData(null);
    setDialogForm(false);
  };
  const openDialogDelete = () => setDialogDeleteTeacher(true);
  const closeDialogDelete = () => {
    setSelectedData(null);
    setDialogDeleteTeacher(false);
  };

  const uiEvents = {
    clickNewItem: () => {
      setSelectedData(null);
      openDialogForm(true);
    },
    clickEditItem: dataEdit => {
      openDialogForm();
      setSelectedData(dataEdit);
    },

    clickDeleteItem: dataDelete => {
      openDialogDelete();
      setSelectedData(dataDelete);
    },

    fetchData: query => {
      const url = CLASS_RESOURCE + `/${classId}/student-change`;
      return requestFromServer.findEntities(url, query);
    },
    newData: data => {
      return requestFromServer.createEntity(CLASS_STUDENT_CHANGE, data);
    },
    editData: data => {
      return requestFromServer.updateEntity(CLASS_STUDENT_CHANGE, data);
    },
    deleteData: id => {
      return requestFromServer.deleteEntity(CLASS_STUDENT_CHANGE, id);
    }
  };

  const headers = [
    {
      dataField: "student.name",
      text: "Tên bài học sinh",
      sort: true
    },
    {
      dataField: "status",
      text: "Loại thay đổi",
      formatter: (cellContent) => {
        let cssClass;
        switch (cellContent) {
          case "transfer":
            cssClass = "mr-1 badge badge-primary";
            break;
          case "study":
            cssClass = "mr-1 badge badge-secondary";
            break;
          case "move_out":
            cssClass = "mr-1 badge badge-warning";
            break;
          case "reserve":
            cssClass = "mr-1 badge badge-danger";
            break;
          default:
            cssClass = "mr-1 badge badge-primary";
        }

        return (
          <span className={cssClass}>
            {MAP_STUDENT_CHANGE_STATUS[cellContent]}
          </span>
        );
      }
    },
    {
      dataField: "created_at",
      text: "Ngày",
      sort: true,
      formatter: columnFormatters.DateTimeColumnFormatter
    },
    {
      dataField: "num_hour",
      text: "Số giờ",
      sort: true,
      formatter: (cellContent, row) => {
        return columnFormatters.PriceColumnFormatter(cellContent, row, "VNĐ");
      }
    },
    {
      dataField: "amount",
      text: "Số tiền(VNĐ)",
      sort: true,
      formatter: (cellContent, row) => {
        return columnFormatters.PriceColumnFormatter(cellContent, row, "VNĐ");
      }
    },
    {
      dataField: "action",
      text: "Hành động",
      headerStyle: (column, colIndex) => {
        return { width: "120px", textAlign: "right" };
      },
      actions: [
        {
          color: "primary",
          icon: "edit",
          label: "Chỉnh sửa",
          event: uiEvents.clickEditItem
        },
        {
          color: "error",
          icon: "delete",
          label: "Xóa",
          event: uiEvents.clickDeleteItem
        }
      ]
    }
  ];

  const initData = {
    id: null,
    student: "",
    type_change: "reserve",
    amount: 1,
    class_move_in: "",
    date_change: (new Date()).getTime()
  };

  return (
    <DataTableUIProvider
      headers={headers}
      dataTableUIEvents={uiEvents}
      initData={initData}
    >
      <DataTableProvider>
        <LoadingDialog/>
        <EditDialog
          id={selectedData && selectedData.id}
          show={showDialogForm}
          onHide={closeDialogForm}
          item={selectedData}
          title={
            selectedData
              ? `Sửa thay đổi '${selectedData && selectedData.student && selectedData.student.name}'`
              : `Thay đổi lớp: ${classInformation && classInformation.name}`
          }
          ComponentForm={StudentChangeEditForm}
        />
        <DataTableDeleteDialog
          id={selectedData && selectedData.id}
          show={showDialogDelete}
          onHide={closeDialogDelete}
          title="Xóa thay đổi của học sinh"
          content={
            <div>
              Bạn chắc chắn muốn xóa thay đổi của &nbsp;
              <span className="text-danger">
                {selectedData && selectedData.student && selectedData.student.name}
              </span>
            </div>
          }
        />
        <DataTableCard/>
      </DataTableProvider>
    </DataTableUIProvider>
  );
}

ListStudentChangeOfClass.propTypes = {};

ListStudentChangeOfClass.defaultProps = {};

export default ListStudentChangeOfClass;
import moment from 'moment';
import { element } from 'prop-types';
import React, {useEffect} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DATE, DATE_TIME } from '../../constants/format';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import { classList } from '../../utils/DOM/class';

export default function DatePicker(props) {
  const {
    className,
    format,
    value,
    onChange,
    placeholder = format || "dd/mm/yyyy",
    timePicker,
    config = {}
  } = props;

  useEffect(() => {
    if (document?.querySelectorAll('[role="dialog"]')[0]) {
      document
        .querySelectorAll('[role="dialog"]')[0]
        .removeAttribute("tabIndex");
    }
  }, []);

  const onApply = (e, p) => {
    if (format) {
      onChange(moment(p.startDate || new Date()).format(timePicker ? DATE_TIME: DATE))
    } else {
      onChange(moment(p.startDate).toDate().getTime());
    }
  }

  const onClear = (e) => {
    onChange("");
  }

  let startDate;
  if (format) {
    if (value) {
      startDate = moment(value, format).format(timePicker ? DATE_TIME: DATE);
    } else {
      startDate = null;
    }
  } else {
    startDate = value ? moment(value).format(timePicker ? DATE_TIME: DATE) : "";
  }

  return (
    <DateRangePicker
      key={value}
      initialSettings={{
        showDropdowns: true,
        autoUpdateInput: true,
        singleDatePicker: true,
        timePicker: timePicker,
        timePicker24Hour: true,
        "drops": "up",
        autoApply: true,
        startDate: startDate || moment(Date.now()).format(DATE),
        // endDate: startDate,
        parentEl: ".modal-content",
        locale: {
          cancelLabel: 'Clear',
          format: format || (timePicker ? DATE_TIME : DATE)
        },
        ...config
      }}
      // onCancel={onClear}
      onApply={onApply}
    >
      <input type="text" defaultValue={startDate} className={classList(className, 'form-control')} placeholder={placeholder} onKeyDown={e => e.preventDefault()}/>
    </DateRangePicker>
  );
}

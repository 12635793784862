import React, {Suspense} from "react";
import {ContentRoute, LayoutSplashScreen} from "../../../../_theme/layout";
import ListTeacherPage from "./list/ListTeacherPage";
import {Redirect, Switch} from "react-router-dom";
import ScheduleTeacher from './schedule';
import ListTeacherStatisticPage from "./statistic/ListTeacherStatisticPage";
import {TeacherStatisticCheckinDetail} from "./statistic/detail/TeacherStatisticCheckinDetail";
import ListClassOfTeacherPage from "./class/ListClassOfTeacherPage";

export default function TeacherPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen/>}>
      <Switch>
        {
          /* Redirect from Admin root URL to /customers */
          <Redirect
            exact={true}
            from="/teacher"
            to="/teacher/list"
          />
        }
        <ContentRoute path="/teacher/list" component={ListTeacherPage}/>
        <ContentRoute path="/teacher/schedule" component={ScheduleTeacher}/>
        <ContentRoute path="/teacher/statistic-checkin" component={ListTeacherStatisticPage}/>
        <ContentRoute path="/teacher/:teacherId/statistic-checkin" component={TeacherStatisticCheckinDetail}/>
        <ContentRoute path="/teacher/class" component={ListClassOfTeacherPage}/>
        <Redirect to="/error/error-404"/>
      </Switch>
    </Suspense>
  );
}

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  resize: "[Resize] Action",
};

const DROP_WIDTH_MOBILE = 767;

const initialState = {
  width: window.innerWidth,
  height: window.innerHeight,
  isMobileScreen: window.innerWidth <= DROP_WIDTH_MOBILE
};

export const reducer = persistReducer(
  { storage, key: "v705-adminFE-ui" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.resize: {
        return {
          ...state,
          width: window.innerWidth,
          height: window.innerHeight,
          isMobileScreen: window.innerWidth <= DROP_WIDTH_MOBILE
        };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  resize: () => ({ type: actionTypes.resize, payload: {} }),
};

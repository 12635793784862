import React from "react";
import reactToastify from '../plugin/react-toastify';

export default function setupAxios(axios, store) {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.interceptors.request.use(
    config => {
      const {
        auth: {authToken}
      } = store.getState();

      if (authToken && !config?.headers?.Authorization) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      const {status, data} = error.response || {};
      switch (status) {
        case 401:
          if (data) {
            localStorage.removeItem('persist:v705-adminFE-auth');
            document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            window.location.href = '/auth/login'
          }
          break;
        case 403:
          reactToastify.noPermission();
          error({statusCode: 403});
          break;
        case 429:
          const {
            auth: {authToken}
          } = store.getState();
          if (authToken) {
            reactToastify.tooManyAction();
          }
          break;
        case 404:
          if (data?.message) {
            reactToastify.error(data?.message);
          } else {
            reactToastify.error('Đường dẫn không tồn tại');
          }
          break;
        case 400:
        case 432:
          if (data?.message) {
            reactToastify.error(data?.message);
          } else {
            reactToastify.error('Lỗi hệ thống');
          }
          break;
        case 422:
          const errors = error.response.data.errors;
          Object.keys(errors).forEach(key => {
            const keySame = key.replace(/([\w\W\d\D]+)(_id)$/gm, "$1");
            if (!errors[keySame]) {
              errors[keySame] = errors[key];
            }
          });
          return Promise.reject(error);
        case 1:
          break;
        default:
          reactToastify.actionFail();
          break;
      }
      return Promise.reject(error);
    }
  );

}

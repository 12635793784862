// Validation schema
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {Modal} from "react-bootstrap";
import {processErrorFormik} from '../../../utils/axios';
import {classList} from "../../../utils/DOM/class";
import {Input} from "../../../_theme/_partials/controls";
import React, {useState} from "react";
import {EditDialogHeader} from "../common/DataTable/pages/edit-dialog/EditDialogHeader";
import axios from "axios";
import {USER_SYSTEM_RESOURCE} from "../../../constants/system/resource";
import reactToastify from "../../../plugin/react-toastify";
import {useIntl} from "react-intl";
import {keyMessages} from "../../../_theme/i18n";

const getEditSchema =(messages) => {
  return Yup.object().shape({
    email: Yup.string()
        .email(messages[keyMessages["PROFILE.EMAIL.BAD_FORMAT"]])
        .required(messages[keyMessages["PROFILE.EMAIL.REQUIRED"]])
  });
}


export function ChangeEmailForm({dataInit, onHide, isShow}) {
  const intl = useIntl();
  const messages = intl?.messages;
  const [actionsLoading, setActionsLoading] = useState(false);

  const changeEmail = (entity, formik) => {
    const url = USER_SYSTEM_RESOURCE + "/request-change-email";
    setActionsLoading(true);
    axios.post(url, entity)
      .then(res => {
        setActionsLoading(false);
        reactToastify.success(`${messages[keyMessages["PROFILE.EMAIL.AUTH_CONFIRM_SENT"]]} "${entity?.email}"`);
        onHide();
      }).catch(err => {
      setActionsLoading(false);
      processErrorFormik(err, formik);
    });
  }

  return (
    <Modal
      // size={size}
      backdrop="static"
      show={isShow}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <EditDialogHeader
        title={messages[keyMessages["PROFILE.CHANGE_EMAIL"]]}
        actionsLoading={actionsLoading}
      />
      <>
        <Formik
          enableReinitialize={true}
          initialValues={dataInit}
          validationSchema={getEditSchema(messages)}
          onSubmit={(values, formikHelpers) => {
            changeEmail({
              email: values.email,
            }, formikHelpers);
          }}
        >
          {({handleSubmit}) => (
            <>
              <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success"/>
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-sm-12">
                      <Field
                        type="text"
                        name="email"
                        component={Input}
                        required
                        label={messages[keyMessages["PROFILE.EMAIL.NEW"]]}
                      />
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Hủy
                </button>
                <> </>
                <button
                  type="submit"
                  disabled={actionsLoading}
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  {actionsLoading ? messages[keyMessages["FORM.SAVING"]]:
                      messages[keyMessages["FORM.SAVE"]]}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    </Modal>
  );
}

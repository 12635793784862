import { isEmpty, isArray } from './typeof';

export const processHeaderTable = (headersRaw, option = {}) => {
  const {
    actions,
    renderCell
  } = option;

  if (isArray(actions)) {
    if (isEmpty(actions)) {
      headersRaw = headersRaw.filter(h => h.dataField !== "action")
    } else {
      const headerAction = headersRaw.find(h => h.dataField === "action")
      if (headerAction) {
        headerAction.actions = headerAction.actions.filter(action => actions.includes(action?.label))
      }
    }
  }

  if (!isEmpty(renderCell)) {
    Object.entries(renderCell).forEach(([key, value]) => {
      const headerCustomRenCell = headersRaw.find(header => header.dataField === key);
      headerCustomRenCell.formatter = value;
    })
  }

  return headersRaw;
}

export const processToolbarTable = (toolbarRaw, toolbars) => {
  if (isArray(toolbars)) {
    return toolbarRaw.filter((toolbar) => toolbars.includes(toolbar?.label));
  }

  return toolbarRaw;
}

export const processFilterTable = (filterRaw, filterShow) => {
  if (isArray(filterShow)) {
    return filterRaw.filter((filter) => filterShow.includes(filter?.name));
  }

  return filterRaw;
}

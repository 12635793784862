import {Formik} from "formik";
import {Modal} from "react-bootstrap";
import {classList} from "../../../../../utils/DOM/class";
import React from "react";
import { isFunction } from '../../../../../utils/typeof';
import {useDataTableContext} from "../../../common/DataTable/pages/DataTableContext";

export function ConfirmDialog({save, item, actionsLoading, onHide, onSuccess}) {
  const dataTableContext = useDataTableContext();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item}
        onSubmit={values => {

          save(values).then(() => {
            if (isFunction(dataTableContext?.reloadPage)) {
              dataTableContext.reloadPage();
            }
          });
        }}
      >
        {({handleSubmit}) => (
          <>
            <Modal.Body className={classList("overlay")}>
              <div className="">
                {item && item.content}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Hủy
              </button>
              <> </>
              <button
                type="submit"
                disabled={actionsLoading}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                {actionsLoading ? "Đang lưu..." : "Đồng ý"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

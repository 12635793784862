import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import usePrevious from '../usePrevious';

const FormikEffect = (props) => {
  const {
    onChange
  } = props;

  const context = useFormikContext();
  const values = context?.values;
  const prev = usePrevious(values);
  useEffect(() => {
    if (!isEqual(prev, values)) {
      onChange(values)
    }
  }, [values, onChange])

  return null;
};

FormikEffect.propTypes = {};

FormikEffect.defaultProps = {};

export default FormikEffect;

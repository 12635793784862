// Validation schema
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {Modal} from "react-bootstrap";
import {classList} from "../../../../../../../utils/DOM/class";
import {CLASS_RESOURCE} from "../../../../../../../constants/system/resource";
import React, {useEffect, useState} from "react";
import * as requestFromServer from "../../../../../common/DataTable/_redux/entitiesCrud";
import {ClassEditForm} from "../../ClassEditForm";
import {FormDialog} from "../../../../../../components/form-dialog/FormDialog";
import moment from "moment";
import {MMDDYYYY} from "../../../../../../../constants/system/dateformat";
import $ from "jquery";
import reactToastify from "../../../../../../../plugin/react-toastify";
import axios from "axios";

export function PickStudent2GrowUpClassForm({save, item, actionsLoading, onHide}) {

  const [students, setSetudent] = useState([]);
  const [studenGrowUp, setStudentGrowUp] = useState({});
  const [showDialogClassEdit, setShowDialogClassEdit] = React.useState(false);
  const openDialogClassEdit = () => setShowDialogClassEdit(true);
  const closeDialogClassEdit = () => {
    setShowDialogClassEdit(false);
  };

  useEffect(() => {
    if (item) {
      const url = CLASS_RESOURCE + `/${item.id}/student`;
      const body = {
        "page": 1,
        "page_size": -1
      }

      requestFromServer.findEntities(url, body)
        .then(res => {
          const data = res.data.data;
          console.log(data);
          data.forEach(item => {
            studenGrowUp[item.student_id] = true;
          })
          setSetudent(data);
        }).catch(err => console.log(err));
    }
  }, [item]);

  useEffect(()=> {}, [studenGrowUp]);

  const updateClassInfomation = ({data}) => {

    const studentIds = Object.keys(studenGrowUp).filter(k => studenGrowUp[k]);

    const entity = {
      ...data,
      old_class_id: item.id,
      student_ids: studentIds
    }

    return axios.post(`${CLASS_RESOURCE}/upgrade`, entity);
  };

  const handlePickStudent = (e) => {
    studenGrowUp[e.target.name] = e.target.checked;
    console.log(studenGrowUp);
  }

  const pickAllStudent = (e) => {
    Object.keys(studenGrowUp)
      .forEach(k => studenGrowUp[k] = true);
    console.log(studenGrowUp);

    $(".pick-student").prop("checked", true);
  }

  const unPickAllStudent = (e) => {
    Object.keys(studenGrowUp)
      .forEach(k => studenGrowUp[k] = false);
    console.log(studenGrowUp);

    $(".pick-student").prop("checked", false);
  }
  return (
    <>
      <FormDialog
        size="lg"
        show={showDialogClassEdit}
        title={'Thêm mới lớp học'}
        initData={{
          id: null,
          name: "",
          num_lesson: 1,
          start_time: moment(Date.now()).format(MMDDYYYY).toString(),
        }}
        messageSuccess="Chuyển lên lớp học mới thành công !!!"
        onSuccess={onHide}
        onHide={closeDialogClassEdit}
        onSubmit={updateClassInfomation}
        ComponentForm={ClassEditForm}
      />
      <Formik
        enableReinitialize={true}
        initialValues={item}
        onSubmit={values => {
          openDialogClassEdit();
        }}
      >
        {({handleSubmit}) => (
          <>
            <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-sm-6">
                    <b>Lớp:</b> {item.name}
                  </div>
                  <div className="col-sm-6">
                    <b>Chi nhánh:</b> {item.location_branch && item.location_branch.name}
                  </div>
                </div>
                <div className="form-group row">
                  {
                    students.map(student => {
                      return (
                        <span className="col-md-4">
                          <input type="checkbox"
                                 name={student.student_id}
                                 className="mr-2 pick-student"
                                 defaultChecked={studenGrowUp[student.student_id]}
                                 onClick={handlePickStudent}
                          />
                          {student.student && student.student.name}
                        </span>
                      )
                    })
                  }
                </div>
                <div className="form-group row">
                  <div className="col-md-12 text-right p-8">
                    <button type="button" className="mr-1 btn-primary small" onClick={pickAllStudent}>Chọn tất cả</button>
                    <button type="button" className="mr-1 btn-secondary small" onClick={unPickAllStudent}>Bỏ chọn tất cả</button>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Hủy
              </button>
              <> </>
              <button
                type="submit"
                disabled={actionsLoading}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Tiếp theo
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

// Icon in /public/media/svg/icons

import {
  ADD,
  APPROVE,
  ASSIGN_PERMISSION, CONSULT,
  CREATE_ACCOUNT,
  DELETE, DETAIL,
  DISABLE_ACCOUNT,
  EDIT, ENROLLMENT_TEST, EXPORT,
  IMPORT, PAY_FEE, REGISTER, RESULT_EXAM,
  VIEW
} from './permission';

export const ASIDE_MENU = [
  // {
  //   title: "Dashboard",
  //   group: "apps",
  //   icon: "Design/Layers",
  //   href: "/dashboard",
  //   name: "dashboard",
  //   actions: [VIEW]
  // },
  {
    title: "Tin tức",
    href: "/news",
    group: "new",
    icon: "Files/Selected-file",
    name: "new",
    actions: [VIEW, ADD, DELETE, EDIT]
  },
  {
    title: "Thể loại tin tức",
    icon: "Code/Compiling",
    href: "/category_new",
    name: "category_new",
    actions: [VIEW, ADD, DELETE, EDIT]
  },
  {
    title: "Tin trang chủ",
    icon: "Home/Home",
    href: "/home_page",
    name: "home_page",
    actions: [VIEW, ADD, DELETE, EDIT]
  },
  {
    title: "Tuyển dụng",
    icon: "Communication/Mail-notification",
    href: "/job_recruitment",
    name: "job_recruitment",
    actions: [VIEW, ADD, DELETE, EDIT]
  },
  {
    title: "Yêu cầu hợp tác",
    icon: "Communication/Mail",
    href: "/cooperation_contact",
    name: "cooperation_contact",
    actions: [VIEW, DELETE]
  },
  {
    title: "Ứng viên",
    icon: "General/User",
    href: "/applicant",
    name: "applicant",
    actions: [VIEW, DELETE]
  },
  {
    title: "Sản phẩm",
    icon: "Design/Layers",
    href: "/product",
    name: "product",
  },
  {
    title: "Giới thiệu",
    icon: "Layout/Layout-top-panel-3",
    href: "/introduction",
    name: "introduction",
  },
  {
    title: "Đồng sáng lập",
    icon: "Communication/Group",
    href: "/cofounders",
    name: "cofounders",
    actions: [VIEW, ADD, DELETE, EDIT]
  },
  {
    title: "Đối tác",
    icon: "Communication/Add-user",
    href: "/partner",
    name: "partner",
    actions: [VIEW, ADD, DELETE, EDIT]
  },
  {
    title: "Liên hệ",
    icon: "Communication/Mail-at",
    href: "/contacts",
    name: "contacts",
    actions: [VIEW, ADD, DELETE, EDIT]
  },
  {
    title: "Quản trị",
    group: "admin",
    icon: "General/User",
    href: "/admin",
    name: "admin",
    items: [
      {
        title: "Quản lý người dùng",
        href: "/admin/user",
        name: "user",
        actions: [VIEW, ADD, DELETE, EDIT]
      },
      {
        title: "Quản lý vai trò",
        href: "/admin/role",
        name: "role",
        actions: [VIEW, ADD, DELETE, EDIT, ASSIGN_PERMISSION]
      },
      {
        title: "Quản lý quyền",
        href: "/admin/permission",
        name: "permission",
        actions: [VIEW, ADD, DELETE, EDIT]
      }
    ]
  },
  // {
  //   title: "Cài đặt hệ thống",
  //   group: "system",
  //   icon: "General/Cog",
  //   href: "/system",
  //   name: "system",
  //   items: [
  //     {
  //       name: "notification-pattern",
  //       title: "Định dạng thông báo",
  //       href: "/system/notification-pattern",
  //       actions: [VIEW, ADD, DELETE, EDIT]
  //     }
  //   ]
  // },
];
export const PAGES = () => {
  let page = [];
    ASIDE_MENU.forEach(menu => {
      if (menu?.items) {
        menu.items.forEach(subMenu => {
          page.push({
            groupName: menu?.title,
            actions: subMenu.actions,
            value: `${subMenu?.name}`,
            text: subMenu?.title
          })
        })
      } else {
        page.push({
          groupName: menu?.title,
          value: menu?.name,
          actions: menu.actions,
          text: menu?.title
        })
      }
    });
    return page
}

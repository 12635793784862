import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from "clsx";
import React, {useState} from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { DATE_RANGE, NUMBER, SELECT, SELECT_SOURCE, TEXT, NUMBER_RANGE } from "../../../../../../constants/filterType";
import {classList} from "../../../../../../utils/DOM/class";
import { isEqual, cloneDeep } from "lodash";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { toDateSrt, toDatetime, formatFirstLetterUppercase } from '../../../../../../utils/filter';
import { isEmpty, isFunction } from '../../../../../../utils/typeof';
import * as requestFromServer from "../../_redux/entitiesCrud";

const cName = {className: "MuiAutocomplete-inputRootDense"};
const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  dense: {
    marginTop: theme.spacing(0)
  },
  menu: {
    width: 200
  }
}));

const FilterItem = props => {
  const classes = useStyles();
  const {item, onChange, onBlur, value, isClearable, onSelectChange} = props;
  const [optionsState, setOptionsState] = React.useState([]);

  const {
    type,
    label,
    name,
    options,
    attrs = {},
    params = {},
    resource,
    propertyName,
    propertyValue,
    mapConvert = null,
  } = item || {};

  const loadData = (inputSearch, loadedOptions, { page }) => {
    const pageSize = 15;
    const queryParam = {
      keyword: inputSearch,
      page: page,
      page_size: pageSize
    };

    return requestFromServer
      .selectEntities(resource, {
        ...queryParam,
        ...params
      })
      .then(response => {
        const { total, data, code } = response.data;
        if (code === 0) {
          setOptionsState(data);
          return {
            options: data,
            hasMore: total > page * pageSize,
            additional: {
              page: page + 1,
            }
          };
        }
      })
      .catch(error => console.log(error));
  };

  switch (type) {
    case SELECT:
      return (
        <Select
          isClearable={isClearable}
          name={name}
          options={options}
          onChange={(itemSelected) => {
            if (itemSelected) {
              onChange({
                value: itemSelected[propertyValue],
                text: itemSelected[propertyName],
              })
            } else {
              onChange(null);
            }
          }}
        />
      );
    case SELECT_SOURCE:
      const {
        _value
      } = value || {};
      return (
        <AsyncPaginate
          defaultOptions
          loadOptions={loadData}
          menuPortalTarget={document.body}
          styles={{ menu: styles => ({ ...styles, zIndex: 1000 }) }}
          options={optionsState}
          getOptionLabel={e => {
            return !isEmpty(mapConvert) ? mapConvert[e[propertyName]] : e[propertyName];
          }}
          isClearable={isClearable}
          getOptionValue={e => e[propertyValue]}
          placeholder={formatFirstLetterUppercase(`Chọn ${label}...`)}
          value={_value}
          menuPlacement="auto"
          onChange={(itemSelected) => {
            if (itemSelected) {
              onChange({
                _value: cloneDeep(itemSelected),
                value: itemSelected[propertyValue],
                text: !isEmpty(mapConvert) ? mapConvert[itemSelected[propertyName]] : itemSelected[propertyName],
              })
            } else {
              onChange(null);
            }
          }}
          additional={{
            page: 1
          }}
          {...attrs}
        />
      );
    case TEXT:
      return (
        <TextField
          className={classList(
            clsx(classes.textField, classes.dense, classes.root),
            "w-100"
          )}
          margin="dense"
          variant="outlined"
          value={value || ""}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          onBlur={onBlur}
          name={name}
          label={label}
        />
      );
    case NUMBER:
      return (
        <TextField
          type="number"
          className={classList(
            clsx(classes.textField, classes.dense, classes.root),
            "w-100"
          )}
          margin="dense"
          variant="outlined"
          value={value}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          onBlur={onBlur}
          name={name}
          label={label}
        />
      );
    case NUMBER_RANGE:
      return (
        <div>
          <div className="font-size-lg text-primary font-weight-bold mb-md-3">
            {label}
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <TextField
              type="number"
              className={classList(
                clsx(classes.textField, classes.dense, classes.root),
                "w-50"
              )}
              margin="dense"
              variant="outlined"
              value={value?.min}
              onChange={(e) => {
                const newValue = parseInt(e.target.value);
                if (!isEqual(value?.min, newValue)) {
                  onChange({
                    ...(value || {}),
                    min: newValue
                  })
                }
              }}
              name={`${name}-min`}
              label="min"
            />
            <TextField
              type="number"
              className={classList(
                clsx(classes.textField, classes.dense, classes.root),
                "w-50 ml-4"
              )}
              margin="dense"
              variant="outlined"
              value={value?.max}
              onChange={(e) => {
                const newValue = parseInt(e.target.value);
                if (!isEqual(value?.max, newValue)) {
                  onChange({
                    ...(value || {}),
                    max: newValue
                  })
                }
              }}
              onBlur={onBlur}
              name={`${name}-max`}
              label="max"
            />
          </div>
        </div>
      );
    case DATE_RANGE:
      const [startDate, endDate] = value || []
      return (
        <DateRangePicker
          initialSettings={{
            showDropdowns: true,
            locale: {
              cancelLabel: 'Clear'
            }
          }}
          onApply={(e, p) => {
            onChange([toDatetime(p?.startDate), toDatetime(p?.endDate)])
          }}
          onCancel={() => {
            onChange(null)
          }}
        >

          <input
            className="form-control"
            type="text"
            readOnly
            value={(startDate && endDate) ? (startDate !== endDate ? `${toDateSrt(startDate)} - ${toDateSrt(endDate)}` : toDateSrt(startDate)) : ''}
            placeholder={formatFirstLetterUppercase(`Chọn ${label}...`)}
          />
        </DateRangePicker>
      );
    default:
      return null;
  }
};

FilterItem.propTypes = {};

FilterItem.defaultProps = {};

export default FilterItem;

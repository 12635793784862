import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_theme/_partials/controls";
import CloseIcon from '@material-ui/icons/Close';

export function FormDialogHeader({ id, item, actionsLoading, title, onHide }) {
  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
        <div className="ml-auto">
          <IconButton size="small" onClick={onHide}>
            <CloseIcon style={{ fontSize: '2rem' }} />
          </IconButton>
        </div>
      </Modal.Header>
    </>
  );
}

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Badge } from "react-bootstrap";
import { isEmpty, isString } from '../../../../../../../utils/typeof';

export const BadgeColumnFormatter = ({ data, field, option }) => {
  const { color = "primary" } = option || {};
  if (isEmpty(data)) return null

  return (data || []).map((item, index) => {
    let badgeName;
    if (!isEmpty(field)) {
      badgeName = item?.[field] || "";
    } else if (isString(item)) {
      badgeName = item;
    }
    return (
      <Badge key={`role-item-${index}`} variant={color} className="px-2 mx-1 mt-1">
        {badgeName}
      </Badge>
    )
  });
};

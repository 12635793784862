import { Avatar, Popper, Grow, ClickAwayListener, Paper, MenuList, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState, useRef } from "react";
import { Modal, Card } from "react-bootstrap";
import { Field, Form, Formik, replace, useFormikContext } from "formik";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Yup from "yup";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DatePickerField, Select } from '../../../../../_theme/_partials/controls';
import { SelectionSearch } from '../../../../../_theme/_partials/controls/forms/SelectionSearch';
import { TextArea } from '../../../../../_theme/_partials/controls/forms/TextArea';
import { CODE_SUCCESS } from '../../../../../constants/system/code';
import { EDIT_CONSULT } from '../../../../../constants/system/permission';
import {
  ADVISORY_RESOURCE, ADVISORY_TYPE_RESOURCE,
  CANDIDATE_POTENTIAL_RESOURCE, CLASS_RESOURCE, ROLE_RESOURCE, STUDENT_RESOURCE
} from "../../../../../constants/system/resource";
import reactToastify from '../../../../../plugin/react-toastify';
import { processError, processErrorFormik } from "../../../../../utils/axios";
import { classList } from "../../../../../utils/DOM/class";
import { toDateTime, toTimestamp } from '../../../../../utils/filter';
import { isEmpty } from '../../../../../utils/typeof';
import { FormDialog } from '../../../../components/form-dialog/FormDialog';
import { usePermissionContext } from '../../../../components/PermissionContext';
import * as requestFromServer from "../../../common/DataTable/_redux/entitiesCrud";
import { DataTableConfirmDialog } from '../../../common/DataTable/pages/confirm-dialog/DataTableConfirmDialog';
import { AdvisoryForm as AdvisoryFormEdit } from '../id/advisory/AdvisoryForm';
import AdvisoryItem from './AdvisoryItem';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

// Validation schema
const EditSchema = Yup.object().shape({
  class: Yup.string()
    .nullable(),
  content: Yup.string()
    .nullable()
    .required("Nội dung tư vấn là bắt buộc"),
  type: Yup.string()
    .nullable()
    .required("Hình thức tư vấn là bắt buộc"),
  meet_up_at: Yup.string()
    .nullable()
    .required("Thời gian gặp là bắt buộc")
});

export function AdvisoryForm({
  save,
  item,
  actionsLoading,
  onHide,
  selectedData,
  maxHeightViewBox = '20rem',
  isShowInformation = true
}) {
  const permissionContext = usePermissionContext();
  const {
    checkCanPage,
    permissionPages,
    fetchPermissions
  } = permissionContext || {};
  useEffect(() => {
    fetchPermissions(['student-candidate'])
  }, []);

  const isUpdateAdvisory = checkCanPage('student-candidate', EDIT_CONSULT);
  const refForm = useRef();
  const [showDialogConfirm, setDialogConfirm] = useState(false);
  const [showDialogEdit, setShowDialogEdit] = useState(false);
  const [advisoryItemSelected, setAdvisoryItemSelected] = useState(false);
  const [showDialogConfirmDelete, setDialogConfirmDelete] = useState(null);
  const openDialogConfirm = () => setDialogConfirm(true);
  const closeDialogConfirm = () => {
    setDialogConfirm(false);
  };

  const openDialogEdit = () => setShowDialogEdit(true);
  const closeDialogEdit = () => {
    setShowDialogEdit(false);
    setAdvisoryItemSelected(null);
  };

  const openDialogConfirmDelete = () => setDialogConfirmDelete(true);
  const closeDialogConfirmDelete = () => {
    setDialogConfirmDelete(false);
    setAdvisoryItemSelected(null)
  };
  const perfectScrollbarRef = React.useRef();
  const [listAdvisory, setListAdvisory] = React.useState([]);
  const { id, name, parent_name, email, phone } = selectedData || {};

  const scrollToBottom = () => {
    const _ps = perfectScrollbarRef.current?._ps;
    if (_ps) {
      if (_ps.element) {
        _ps.element.scrollTop = _ps.element.scrollHeight;
        _ps.update();
      }
    }
  };

  useEffect(() => {
    if (id) {
      requestFromServer
        .findEntities(`${STUDENT_RESOURCE}/${id}/advisory`, { page_size: -1, sorts: [{
            direction: "asc",
            property: "meet_up_at"
          }] })
        .then(response => {
          if (response?.data?.code === 0) {
            setListAdvisory(response?.data?.data);
            scrollToBottom();
          }
        })
        .catch(e => {
          processError(e);
        });
    }
  }, [id]);

  const addAdvisory = (data) => {
    setListAdvisory([
      ...listAdvisory,
      data
    ]);
    if (refForm?.current?.resetForm) {
      refForm.current.resetForm(item);
    }
    scrollToBottom();
  }

  const updateAdvisory = (data) => {
    const indexAdvisory = listAdvisory.findIndex(advisory => advisory?.id === data?.id);
    if (indexAdvisory !== -1) {
      setListAdvisory([
        ...listAdvisory.slice(0, indexAdvisory),
        data,
        ...listAdvisory.slice(indexAdvisory + 1),
      ])
    }
    setTimeout(() => {
      const _ps = perfectScrollbarRef.current?._ps;
      if (_ps) {
        _ps.update();
      }
    })
  }

  const deleteAdvisory = (data) => {
    const indexAdvisory = listAdvisory.findIndex(advisory => advisory?.id === advisoryItemSelected?.id);
    if (indexAdvisory !== -1) {
      setListAdvisory([
        ...listAdvisory.slice(0, indexAdvisory),
        ...listAdvisory.slice(indexAdvisory + 1),
      ])
    }
    setTimeout(() => {
      const _ps = perfectScrollbarRef.current?._ps;
      if (_ps) {
        _ps.update();
      }
    })
  }

  const deleteAdvisoryToServer = () => {
    return requestFromServer.deleteEntity(ADVISORY_RESOURCE, advisoryItemSelected?.id)
  }

  const editAdvisoryToServer = ({ data }) => {
    return requestFromServer.updateEntity(ADVISORY_RESOURCE, {
      ...data,
      student_id: selectedData?.id || null
    });
  };

  const onClickDeleteAdvisory = (advisory) => {
    setAdvisoryItemSelected(advisory);
    openDialogConfirmDelete();
  }

  const onClickEditAdvisory = (advisory) => {
    setAdvisoryItemSelected(advisory);
    openDialogEdit();
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item}
        validationSchema={EditSchema}
        innerRef={refForm}
        onSubmit={(values, formik) => {
          openDialogConfirm();
        }}
      >
        {({ handleSubmit, values, ...props }) => (
          <>
            <Modal.Body
              className={classList(
                "overlay",
                actionsLoading && "overlay-block"
              )}
            >
              {
                isShowInformation &&
                (
                  <div className="form-group row">
                    <div className="col-sm-1 d-none d-md-block">
                      <AccountCircleIcon style={{ fontSize: '3.8rem' }} />
                    </div>
                    <div className="col-sm-12 col-md-5 d-flex flex-column justify-content-center">
                      <div className="font-weight-bold text-dark-75 font-size-lg">Học viên: {name}</div>
                      <div className="font-weight-bold text-dark-75 font-size-lg">Số điện thoại: {phone}</div>
                    </div>
                    <div className="col-sm-12 col-md-5 d-flex flex-column justify-content-center">
                      <div className="font-weight-bold text-dark-75 font-size-lg">Email: {email}</div>
                      <div className="font-weight-bold text-dark-75 font-size-lg">Phụ huynh: {parent_name}</div>
                    </div>
                  </div>
                )
              }
              <div className="form-group row">
                <div className="col-sm-12">
                  <Card>
                    <PerfectScrollbar
                      ref={perfectScrollbarRef}
                      options={perfectScrollbarOptions}
                      className="scroll"
                      style={{ minHeight: maxHeightViewBox, maxHeight: maxHeightViewBox, position: "relative" }}
                    >
                      {listAdvisory.map((advisory, index) => {
                        const {
                          id: idAdvisory,
                        } = advisory;
                        return (
                          <div key={`advisory-item-${index}-${idAdvisory}`}>
                            <AdvisoryItem
                              advisory={advisory}
                              showMenu={isUpdateAdvisory}
                              deleteItem={onClickDeleteAdvisory}
                              editItem={onClickEditAdvisory}
                            />
                            <div className="separator separator-solid"/>
                          </div>
                        );
                      })}
                    </PerfectScrollbar>
                  </Card>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12 col-md-3">
                  <DatePickerField
                    timePicker
                    name="meet_up_at"
                    label="Ngày gặp"
                    placeholder="Chọn ngày gặp"
                    require
                  />
                </div>
                <div className="col-sm-12 col-md-3">
                  <Field
                    name="type"
                    component={SelectionSearch}
                    resource={ADVISORY_TYPE_RESOURCE}
                    propertyName="name"
                    propertyValue="id"
                    required
                    placeholder="Chọn hình thức"
                    label="Hình thức"
                  />
                </div>
                <div className="col-sm-12 col-md-3">
                  <DatePickerField
                    timePicker
                    name="appointment"
                    label="Lần hẹn tới"
                    placeholder="Chọn ngày hẹn tới"
                  />
                </div>
                <div className="col-sm-12 col-md-3">
                  <Field
                    name="class"
                    component={SelectionSearch}
                    resource={CLASS_RESOURCE}
                    propertyName="name"
                    propertyValue="id"
                    placeholder="Chọn lớp"
                    label="Lớp"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12 col-md-10">
                  <Field
                    name="content"
                    required
                    component={TextArea}
                    placeholder="Nhập nội dung tư vấn"
                    label="Nội dung tư vấn"
                  />
                </div>
                <div className="col-sm-12 col-md-2 d-flex align-items-start mt-md-8">
                  <div className="form-group">
                    <button
                      type="submit"
                      disabled={actionsLoading}
                      onClick={() => handleSubmit()}
                      className="btn btn-primary btn-elevate"
                    >
                      {actionsLoading ? "Đang lưu..." : "Gửi"}
                    </button>
                  </div>
                </div>
              </div>
              <DataTableConfirmDialog
                centered={true}
                show={showDialogConfirm}
                onHide={closeDialogConfirm}
                noShowMessage
                title="Xác nhận thêm tư vấn"
                labelConfirm="Thêm"
                content={
                  <div>
                    Bạn có muốn thêm tư vấn có nội dung&nbsp;
                    <span className="text-danger">
                      {values?.content}
                    </span>
                    <small className="d-block text-danger">
                      Lưu ý: Tư vấn sau khi thêm sẽ không được chỉnh sửa
                    </small>
                  </div>
                }
                event={() => save(values, props, addAdvisory)}
              />
              <DataTableConfirmDialog
                centered={true}
                show={showDialogConfirmDelete}
                onHide={closeDialogConfirmDelete}
                title="Bạn muốn xóa tư vấn"
                labelConfirm="Xóa tư vấn"
                messageSuccess="Xóa tư vấn thành công"
                type="btn-danger"
                content={
                  <div>
                    Bạn có muốn thêm tư vấn có nội dung&nbsp;
                    <span className="text-danger">
                      {advisoryItemSelected?.content}
                    </span>
                  </div>
                }
                event={deleteAdvisoryToServer}
                onSuccess={deleteAdvisory}
              />
              <FormDialog
                show={showDialogEdit}
                title={'Chỉnh sửa tư vấn'}
                messageSuccess="Cập nhật tư vấn thành công"
                initData={advisoryItemSelected}
                item={advisoryItemSelected}
                onHide={closeDialogEdit}
                onSubmit={editAdvisoryToServer}
                onSuccess={updateAdvisory}
                ComponentForm={AdvisoryFormEdit}
              />
            </Modal.Body>
          </>
        )}
      </Formik>
    </>
  );
}

import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from '../../../../app/components/DatePicker';
import { classList } from "../../../../utils/DOM/class";
import { toDateSrt, toDateTime } from '../../../../utils/filter';

const getFieldCSSClasses = (touched, errors, isClassForm = true) => {
  const classes = [];
  if (isClassForm) {
    classes.push("form-control");
  }
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ required, ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);

  const onBlur = field.onBlur;
  return (
    <>
      {props.label && (
        <label>
          {props.label} {required && <span className="text-danger"> *</span>}
        </label>
      )}
      <span
        className={getFieldCSSClasses(touched[field.name], errors[field.name], false)}
      >
        {
          props.disabled &&
          (
            <input type="text" disabled value={props.timePicker ? toDateTime(field?.value) : toDateSrt(field?.value)} className="form-control" placeholder={props.placeholder}/>
          )
        }
        {
          !props.disabled &&
          (
            <DatePicker
              {...field}
              {...props}
              className={classList(
                getFieldCSSClasses(touched[field.name], errors[field.name]),
                "w-full"
              )}
              value={field?.value}
              onChange={val => {
                setFieldValue(field.name, val);
              }}
              onBlur={onBlur}
            />
          )
        }
      </span>
      {errors[field.name] && touched[field.name] && (
        <div className="invalid-feedback">{errors[field.name].toString()}</div>
      )}
    </>
  );
}

import React, {useEffect, useMemo, useState} from "react";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {useIntl} from 'react-intl';
import {useParams} from "react-router-dom";
import moment from "moment";
import * as shortid from 'shortid';
import { getDayOfWeek } from '../../../../../../constants/date';
import {TYPE_STUDY_STATUS_SELECT} from '../../../../../../constants/system/dataSelect';
import {isEmpty} from '../../../../../../utils/typeof';
import TabsCustom from "../../../../../components/Tabs";
import ScheduleOfClass from './ScheduleOfClass';
import ListStudentOfClass from "./student/ListStudentOfClass";
import {CLASS_RESOURCE} from "../../../../../../constants/system/resource";
import axios from "axios";
import ListExamOfClass from "./examination/ListExamOfClass";
import ListStudentViewOfClass from './student/ListStudentViewOfClass';
import StudentLearnTry from './StudentLearnTry';
import ListStudentChangeOfClass from "./StudentChange/ListStudentChangeOfClass";
import {MAP_CLASS_STATUS} from "../../../../../../constants/MapConvert";
import SettingsIcon from "@material-ui/icons/Settings";
import {FormDialog} from "../../../../../components/form-dialog/FormDialog";
import {ClassEditForm} from "../ClassEditForm";
import * as requestFromServer from "../../../../common/DataTable/_redux/entitiesCrud";
import reactToastify from "../../../../../../plugin/react-toastify";
import {keyMessages} from "../../../../../../_theme/i18n";


export function ClassDetail(props) {
    const intl = useIntl();
    const messages = intl?.messages;

    const dayOfWeek = getDayOfWeek();

    const {
        isSettingInformation = false,
        showTabs = [],
        paramsComponents = {}
    } = props;

  const {classId} = useParams();

  const [isLoadingClassInfo, setLoadingClassInfo] = useState(false);
  const [classInfo, setClassInfo] = useState({});
  const [keyTab, setKeyTab] = useState(shortid.generate());
  const [showDialogEdit, setShowDialogEdit] = React.useState(false);
  const openDialogEdit = () => setShowDialogEdit(true);
  const closeDialogEdit = () => {
    setShowDialogEdit(false);
  };

  const fetchClassInformation = (id) => {
    setLoadingClassInfo(true);
    let url = CLASS_RESOURCE + "/detail?id=" + id;
    axios.get(url)
      .then(res => {
        setLoadingClassInfo(false);
        setClassInfo(res.data.data);
      }).catch(err => {
        setLoadingClassInfo(false);
        console.log(err);
    });
  }

    useEffect(() => {
        if (classId) {
            fetchClassInformation(classId);
        }
    }, [classId]);

    const tabs = useMemo(() => {
        let tabsRaw = [
            {
                id: `student-${keyTab}`,
                key: 'student',
                title: messages[keyMessages["TEACHER.EXAM_FORM.TABLE.STUDENT"]],
                content: <ListStudentOfClass
                    classId={classId}
                    classInformation={classInfo}
                    {...(paramsComponents?.student || {})}
                >
                    Học sinh
                </ListStudentOfClass>
            },
            {
              id: `student-view-${keyTab}`,
              key: 'student-view',
              title: messages[keyMessages["TEACHER.EXAM_FORM.TABLE.STUDENT"]],
              content: <ListStudentViewOfClass
                classId={classId}
                classInformation={classInfo}
                {...(paramsComponents?.student || {})}
              >
                Học sinh
              </ListStudentViewOfClass>
            },
            {
                id: `schedule-${keyTab}`,
                key: 'schedule',
                title: messages[keyMessages["TEACHER.CLASS.TAB.SCHEDULE"]],
                content: <ScheduleOfClass
                    classId={classId}
                    startTimeClass={classInfo?.start_time}
                    numLesson={classInfo.num_lesson}
                    classInfo={classInfo}
                    {...(paramsComponents?.schedule || {})}
                />
            },
            {
                id: `exam-${keyTab}`,
                key: 'exam',
                title: messages[keyMessages["TEACHER.CLASS.TAB.EXAMS"]],
                content: <ListExamOfClass classId={classId} {...(paramsComponents?.exam || {})}/>
            },
            {
                id: `list-student-learn-try-${keyTab}`,
                key: 'list-student-learn-try',
                title: 'Danh sách học thử',
                content: <StudentLearnTry
                    classId={classId}
                    classInformation={classInfo}
                    {...(paramsComponents?.student || {})}
                />
            },
            {
                id: `list-student-change-${keyTab}`,
                key: 'list-student-change',
                title: 'Thay đổi',
                content: <ListStudentChangeOfClass
                    classId={classId}
                    classInformation={classInfo}
                    {...(paramsComponents?.['list-student-change'] || {})}
                />
            },
        ]

        if (!isEmpty(showTabs)) {
            tabsRaw = tabsRaw.filter(({key}) => showTabs?.includes(key))
        }

        const isExistTabStudent = tabsRaw.find(tab => tab?.key === 'student');

        if (isExistTabStudent) {
          tabsRaw = tabsRaw.filter(({key}) => key !== 'student-view');
        }

        return tabsRaw;
    }, [showTabs, paramsComponents])

    const formatStatus = (status) => {
        let cssClass;
        switch (status) {
            case "waiting":
                cssClass = "mr-1 badge badge-warning";
                break;
            default:
                cssClass = "mr-1 badge badge-primary";
        }

        return (
            <span className={cssClass}>
            {messages[MAP_CLASS_STATUS[status]]}
      </span>
        );
    }

    const updateClassInfomation = ({data}) => {
        requestFromServer.updateEntity(CLASS_RESOURCE, data)
            .then(res => {
                setClassInfo(data);
                fetchClassInformation(data?.id)
                setKeyTab(shortid.generate());
                // reactToastify.success("Cập nhật thông tin lớp học thành công !!!");
                closeDialogEdit();
            })
            .catch(err => {
                console.log(err);
                reactToastify.error("Cập nhật thông tin lớp học thất bại !!!");
            })
    }

  const typeStudy = TYPE_STUDY_STATUS_SELECT.find(item => item.value === classInfo?.type_study)

  return (
    <div>
      <FormDialog
        size="lg"
        show={showDialogEdit}
        title={'Cập nhật thông tin lớp học'}
        initData={classInfo}
        onHide={closeDialogEdit}
        onSubmit={updateClassInfomation}
        ComponentForm={ClassEditForm}
      />
      <div className="bg-white border m-5">
        <div className="row">
          <div className="col-md-6">
            <h4 className="pl-4 pt-3">{messages[keyMessages["TEACHER.CLASS.DETAIL.TITLE"]]}</h4>
          </div>
          {
            !isLoadingClassInfo &&
            isSettingInformation &&
            (
              <div className="col-md-6 text-right pr-8 pt-2">
                <span type="button" onClick={openDialogEdit}>
                  <SettingsIcon/>
                </span>
              </div>
            )
          }
        </div>
        <div className="row">
          {
            isLoadingClassInfo &&
            (
              <div className="overlay-layer zindex-2 bg-transparent min-h-130px d-flex align-items-center justify-content-center m-auto">
                <div className="spinner-border text-success" />
              </div>
            )
          }
          {
            !isLoadingClassInfo &&
            (<>
              <div className="col-md-4 pl-8 pr-5 pb-5 pt-2">
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.NAME"]]}: </b> {classInfo.name}
                </div>
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.LEVEL"]]}: </b> {(classInfo.program && (classInfo.program.name))}
                </div>
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.SYLLABUS"]]}: </b> {(classInfo.program && (classInfo.syllabus.name))}
                </div>
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.CURRICULUM"]]}: </b> {classInfo?.book_name}
                </div>
              </div>
              <div className="col-md-4 pl-5 pr-5 pb-5 pt-2">
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.START_TIME"]]}: </b> {moment(new Date(classInfo.start_time)).format("DD-MM-yyyy")}
                </div>
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.END_TIME"]]}: </b> {moment(new Date(classInfo.end_time)).format("DD-MM-yyyy")}
                </div>
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.STYLE"]]}: </b> {typeStudy?.text}
                </div>
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.STAFF"]]}: </b> {(classInfo.staff && (classInfo.staff.full_name))}
                </div>
              </div>
              <div className="col-md-4 pl-5 pr-5 pb-5 pt-2">
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.NUM_PERIOD"]]}: </b> {classInfo.num_lesson}
                </div>
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.LOCATION"]]}: </b> {(classInfo.location_branch && (classInfo.location_branch.name))}
                </div>
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.NUM_STUDENT"]]}: </b> {classInfo.num_student}
                </div>
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.STATUS"]]}: </b> {formatStatus(classInfo.status)}
                </div>
              </div>
            </>)
          }
        </div>
        {
          !isLoadingClassInfo &&
          (
            <div className="row">
              <div className="col-md-12 pl-8 pr-5 pb-5 pt-2">
                <div>
                  <b>{messages[keyMessages["TEACHER.CLASS.DETAIL.SCHEDULE"]]}: </b> {classInfo?.weekly_schedule?.map((schedule, indexSchedule) => {
                  return (<div key={`schedule-${indexSchedule}`}>
                    <div>
                      <b>{dayOfWeek[schedule.day_of_week] + ": "}</b>
                      {
                        schedule.times.map((time, index) => {
                          const isLastIndex = (index === (schedule.times.length - 1));
                          return (<span key={`schedule-index-${indexSchedule}-${index}`}>
                        {time.start_hour + " => " + time.end_hour + ", " }<b>{messages[keyMessages["TEACHER.CLASS.DETAIL.TEACHER"]]}: </b> {" " + time?.teacher && time?.teacher?.name}
                            {!isLastIndex && <span>; </span>}
                      </span>);
                        })
                      }
                    </div>
                  </div>);
                })}
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className="row bg-white border m-5">
        <TabsCustom
          key={`tab-class-${classId}`}
          tabs={tabs}
          idActive={1}
        />
      </div>
    </div>
  );
}

/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import supportOldBrowser from './utils/supportOldBrowser';
import React from "react";
import { registerLocale } from 'react-datepicker';
import ReactDOM from "react-dom";
import axios from "axios";
import { ToastContainer } from 'react-toastify';
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_theme/_assets/plugins/icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_theme/_assets/plugins/flaticon/flaticon.css";
import "./_theme/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {
  ThemeLayoutProvider,
  ThemeSplashScreenProvider,
  ThemeSubheaderProvider
} from "./_theme/layout";
import {ThemeI18nProvider} from "./_theme/i18n";
import vi from 'date-fns/locale/vi';
import 'react-toastify/dist/ReactToastify.css';
import { registerServiceWorker } from './serviceWorker';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Theme mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */ _redux.mockAxios(axios);

/**
 * Inject theme interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);
registerLocale('vi', vi)
supportOldBrowser();

ReactDOM.render(
  <ThemeI18nProvider>
    <ThemeLayoutProvider>
      <ThemeSubheaderProvider>
        <ThemeSplashScreenProvider>
          <ToastContainer />
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </ThemeSplashScreenProvider>
      </ThemeSubheaderProvider>
    </ThemeLayoutProvider>
  </ThemeI18nProvider>,
  document.getElementById("root")
);

registerServiceWorker();

import React, {useMemo, useState} from "react";
import {SELECT, SELECT_SOURCE, TEXT} from "../../../../../constants/filterType";
import {TYPE_STUDY_STATUS_SELECT} from '../../../../../constants/system/dataSelect';
import {CLASS_RESOURCE, LOCATION_BRANCH_RESOURCE, PROGRAM_RESOURCE,} from "../../../../../constants/system/resource";
import {processHeaderTable} from '../../../../../utils/table';
import * as requestFromServer from "../../../common/DataTable/_redux/entitiesCrud";
import {DataTableCard} from "../../../common/DataTable/pages/DataTableCard";
import {DataTableProvider} from "../../../common/DataTable/pages/DataTableContext";
import {DataTableUIProvider} from "../../../common/DataTable/pages/DataTableUIContext";
import {DataTableDeleteDialog} from "../../../common/DataTable/pages/delete-dialog/DataTableDeleteDialog";
import {EditDialog} from "../../../common/DataTable/pages/edit-dialog/EditDialog";
import {LoadingDialog} from "../../../common/DataTable/pages/loading-dialog/LoadingDialog";
import * as columnFormatters from "../../../common/DataTable/pages/table/column-formatters";
import {ClassEditForm} from "../list/ClassEditForm";
import {MAP_CLASS_STATUS} from "../../../../../constants/MapConvert";
import {COUNTRY} from "../../../../../constants/country";
import {Link} from "react-router-dom";
import {DELETE, EDIT} from "../../../../../constants/system/permission";
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../_theme/i18n";
import {usePermissionContext} from "../../../../components/PermissionContext";

const TableListClass = (props) => {
  const permissionContext = usePermissionContext();

  const intl = useIntl();
  const messages = intl?.messages;
  const {
    actions,
    renderCell
  } = props;
  const [selectedData, setSelectedData] = useState();
  const [showDialogForm, setDialogForm] = useState(false);
  const [showDialogDelete, setDialogDelete] = useState(false);
  const openDialogForm = () => setDialogForm(true);
  const closeDialogForm = () => {
    setSelectedData(null);
    setDialogForm(false);
  };
  const openDialogDelete = () => setDialogDelete(true);
  const closeDialogDelete = () => {
    setSelectedData(null);
    setDialogDelete(false);
  };

  const uiEvents = {
    clickNewItem: () => {
      setSelectedData(null);
      openDialogForm(true);
    },
    clickEditItem: dataEdit => {
      setSelectedData(JSON.parse(JSON.stringify(dataEdit)));
      openDialogForm();
    },
    clickDeleteItem: dataDelete => {
      openDialogDelete();
      setSelectedData(dataDelete);
    },
    fetchData: query => {
      return requestFromServer.findEntities(CLASS_RESOURCE, query);
    },
    newData: data => {
      return requestFromServer.createEntity(CLASS_RESOURCE, data);
    },
    editData: data => {
      return requestFromServer.updateEntity(CLASS_RESOURCE, data);
    },
    deleteData: id => {
      return requestFromServer.deleteEntity(CLASS_RESOURCE, id);
    }
  };

  let headers = useMemo(() => {
    let headersRaw = [
      {
        dataField: "name",
        headerStyle: (column, colIndex) => {
          return { minWidth: "150px" };
        },
        text: messages[keyMessages['CLASS.NAME']],
        sort: true,
        formatter: (cellContent, row) => {
          return(
            <Link to={"/class/" + row.id}>{cellContent}</Link>
          )
        }
      },
      {
        dataField: "program.name",
        headerStyle: (column, colIndex) => {
          return { minWidth: "150px" };
        },
        text: messages[keyMessages['CLASS.PROGRAM']],
      },
      {
        dataField: "type_study",
        headerStyle: (column, colIndex) => {
          return { minWidth: "150px" };
        },
        text: messages[keyMessages['CLASS.TYPE_STUDY']],
        formatter: (cellContent, row) => {
          const typeStudy = TYPE_STUDY_STATUS_SELECT.find(item => item.value === cellContent)
          return <span>{typeStudy?.text}</span>
        }
      },
      {
        dataField: "start_time",
        text: messages[keyMessages['START_TIME']],
        headerStyle: (column, colIndex) => {
          return { minWidth: "120px" };
        },
        formatter: columnFormatters.DateTimeColumnFormatter
      },
      {
        dataField: "end_time",
        text: messages[keyMessages['END_TIME']],
        headerStyle: (column, colIndex) => {
          return { minWidth: "120px" };
        },
        formatter: columnFormatters.DateTimeColumnFormatter
      },

      {
        dataField: "num_student",
        text: messages[keyMessages['CLASS.NUMBER_STUDENT']],
      },
      {
        dataField: "location_branch.name",
        text: messages[keyMessages['CLASS.LOCATION']],
        headerStyle: (column, colIndex) => {
          return { minWidth: "150px" };
        },
      },
      {
        dataField: "staff.full_name",
        text: messages[keyMessages['CLASS.STAFF']],
        headerStyle: (column, colIndex) => {
          return { minWidth: "170px" };
        },
      },
      {
        dataField: "status",
        text: messages[keyMessages['STATUS']],
        sort: true,
        formatter: (cellContent) => {
          let cssClass;
          switch (cellContent) {
            case "waiting":
              cssClass = "mr-1 badge badge-warning";
              break;
            case "stop":
              cssClass = "mr-1 badge badge-primary";
              break;
            case "running":
              cssClass = "mr-1 badge badge-success";
              break;
            default:
              cssClass = "mr-1 badge badge-primary";
          }

          return (
            <span className={cssClass}>
            {messages[MAP_CLASS_STATUS[cellContent]]}
          </span>
          );
        }
      },
      {
        dataField: "action",
        text: messages[keyMessages['DATATABLE.COLUMN.ACTION']],
        headerStyle: (column, colIndex) => {
          return { width: "120px", textAlign: "right" };
        },
        actions: [
          {
            color: "primary",
            icon: "edit",
            label: "Chỉnh sửa",
            event: uiEvents.clickEditItem,
            actionName: EDIT
          },
          {
            color: "error",
            icon: "delete",
            label: "Xóa",
            event: uiEvents.clickDeleteItem,
            actionName: DELETE
          }
        ]
      }
    ];

    headersRaw = processHeaderTable(headersRaw, {
      actions,
      renderCell
    })

    return headersRaw;
  },  [actions, renderCell])

  const filters = [
    {
      type: SELECT_SOURCE,
      label: "Chi nhánh",
      name: "location_branch_id",
      resource: LOCATION_BRANCH_RESOURCE,
      propertyName: "name",
      propertyValue: "id",
    },
    {
      type: TEXT,
      label: "Sĩ số",
      name: "num_student"
    },
    {
      type: SELECT,
      label: "Trạng thái",
      name: "status",
      options: COUNTRY,
      propertyValue: "value",
      propertyName: "label"
    },
    {
      type: SELECT_SOURCE,
      label: "Khóa học",
      name: "program_id",
      resource: PROGRAM_RESOURCE,
      propertyName: "name",
      propertyValue: "id",
    },
  ];

  const initData = {
    id: null,
    name: "",
    num_lesson: 1,
    start_time: (new Date()).getTime(),
    location_branch: "",
    program: "",
    syllabus: "",
    fee: 0,
    document_cost: 0,
    staff:""
  };
  return (
    <DataTableUIProvider
      headers={headers}
      filters={filters}
      dataTableUIEvents={uiEvents}
      initData={initData}
    >
      <DataTableProvider pageName="class-list">
        <LoadingDialog/>
        <EditDialog
          size="lg"
          id={selectedData && selectedData.id}
          show={showDialogForm}
          item={selectedData}
          title={
            selectedData ? "Cập nhật thông tin lớp học" : "Thêm lớp học"
          }
          onHide={closeDialogForm}
          ComponentForm={/*permissionContext.checkRole(STAFF) || permissionContext.checkRole(SELLER) ?
              StaffClassEditForm :*/ ClassEditForm}
        />
        <DataTableDeleteDialog
          id={selectedData && selectedData.id}
          show={showDialogDelete}
          onHide={closeDialogDelete}
          title="Xóa lớp học"
          content={
            <div>
              Bạn chắc chắn muốn xóa lớp học&nbsp;
              <span className="text-danger">
                {selectedData && selectedData.name}
              </span>
            </div>
          }
        />
        <DataTableCard/>
      </DataTableProvider>
    </DataTableUIProvider>
  );
};

TableListClass.propTypes = {};

TableListClass.defaultProps = {};

export default TableListClass;

/*global firebase, a*/
import firebase from "firebase/app";
import "firebase/messaging";
import { FIREBASE_CONFIG } from "../../../constants/system/configFirebase";
let requestFirebaseNotificationPermission = null;
let onMessageListener = null;
try {
  firebase.initializeApp(FIREBASE_CONFIG);
  const messaging = firebase.messaging();

  requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
      messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then(firebaseToken => {
          resolve(firebaseToken);
        })
        .catch(err => {
          reject(err);
        });
    });

  onMessageListener = () =>
    new Promise(resolve => {
      messaging.onMessage(payload => {
        resolve(payload);
      });
    });
} catch (e) {
  console.log(e);
}

export {
  requestFirebaseNotificationPermission,
  onMessageListener
}

import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from '@material-ui/icons/GetApp';
import React, {useEffect, useMemo, useRef, useState} from "react";
import Spinner from "react-bootstrap/Spinner";
import {useDropzone} from "react-dropzone";
import {isEqual} from "lodash";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_theme/_helpers";
import {CODE_SUCCESS} from "../../../constants/system/code";
import {UPLOAD_RESOURCE} from "../../../constants/system/resource";
import reactToastify from "../../../plugin/react-toastify";
import {responseErrorToString} from "../../../utils/array";
import {processError} from "../../../utils/axios";
import {classList} from "../../../utils/DOM/class";
import {getFileNameByUrl, getFileTypeByUrl} from "../../../utils/file";
import * as requestFromServer from "../../modules/common/DataTable/_redux/entitiesCrud";
import * as shortid from "shortid";
import "./style.scss";

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16
};

const thumb = {
    position: "relative",
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 70,
    height: 70,
    boxSizing: "border-box"
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden"
};

const img = {
    display: "block",
    width: "auto",
    height: "100%"
};

const baseStyle = {
    flex: 1,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out"
};

const activeStyle = {
    borderColor: "#2196f3"
};

const acceptStyle = {
    borderColor: "#00e676"
};

const rejectStyle = {
    borderColor: "#ff1744"
};

function UploadFile(props) {
    const {
        className,
        isNoUpdate,
        accept = "image/*",
        maxFiles = 1,
        options = {},
        maxSize = 20000000,
        onChange,
        onBlur,
        value
    } = props;

    const refTimeout = useRef();
    const refTimeoutValueChange = useRef();
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState({});
    const listUrl = useMemo(() => {
        if (isNoUpdate) {
            return files.filter(file => !!file.preview).map(f => f.preview);
        } else {
            return files.filter(file => !!file.url).map(f => f.url);
        }
    }, [files]);

    useEffect(() => {
        if (onChange) {
            clearTimeout(refTimeout.current)
            clearTimeout(refTimeoutValueChange.current)
            refTimeout.current = setTimeout(() => {
                if (isNoUpdate) {
                    if (maxFiles === 1 || !maxFiles) {
                        if (!isEqual(value, files[0]) && (!!value !== !!files[0])) {
                            onChange(files[0]);
                        }
                    } else {
                        if (!isEqual(value, files)) {
                            onChange(files);
                        }
                    }
                } else {
                    if (maxFiles === 1 || !maxFiles) {
                        if (!isEqual(value, listUrl[0])) {
                            onChange(listUrl[0]);
                        }
                    } else {
                        if (!isEqual(value, listUrl)) {
                            onChange(listUrl);
                        }
                    }
                }
            }, 300)
        }
    }, [listUrl]);

    useEffect(() => {
        clearTimeout(refTimeoutValueChange.current)
        clearTimeout(refTimeout.current)
        refTimeoutValueChange.current = setTimeout(() => {
            if (isNoUpdate) {
                if (maxFiles === 1 || !maxFiles) {
                    if (!isEqual(value, files[0])) {
                        if (value) {
                            setFiles([value]);
                        } else {
                            setFiles([]);
                        }
                    }
                } else {
                    if (!isEqual(value, files)) {
                        if (value) {
                            setFiles(value);
                        } else {
                            setFiles([]);
                        }
                    }
                }
            } else {
                if (maxFiles === 1 || !maxFiles) {
                    if (!isEqual(value, listUrl[0])) {
                        if (value) {
                            setFiles([
                                {
                                    id: shortid.generate(),
                                    preview: value,
                                    type: getFileTypeByUrl(value),
                                    name: getFileNameByUrl(value),
                                    url: value
                                }
                            ]);
                        } else {
                            setFiles([]);
                        }
                    }
                } else {
                    if (!isEqual(value, listUrl)) {
                        if (value) {
                            setFiles(
                                value.map(v => ({
                                    id: shortid.generate(),
                                    type: getFileTypeByUrl(v),
                                    name: getFileNameByUrl(v),
                                    preview: v,
                                    url: v
                                }))
                            );
                        } else {
                            setFiles([]);
                        }
                    }
                }
            }
        }, 300);
    }, [value]);

    useEffect(() => {
        if (!isNoUpdate) {
            const fileNew = files.filter(
                file => !file.url
            );
            if (fileNew?.length > 0) {
                upload(fileNew).then(rs => {
                    setFiles(
                        files
                            .map(file => {
                                const urlUpload = rs.find(r => r.id === file.id);
                                if (!urlUpload) {
                                    return file;
                                } else {
                                    return {
                                        ...file,
                                        url: urlUpload?.url,
                                        type: getFileTypeByUrl(urlUpload?.url),
                                    };
                                }
                            })
                            .filter(file => !!file.url)
                    );
                });
            }
        }
    }, [files]);

    const removeFile = index => {
        setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
    };

    const upload = async (files, result = []) => {
        if (!files || files.length === 0) return result;
        const file = files.shift();
        const fileId = file.id;
        if (!uploading[fileId]) {
            let formData = new FormData();
          formData.append("file", file);
          formData.append("end_point", 'https://cdn.mhdigital.vn');
            setUploading({
                ...uploading,
                [fileId]: true
            });
            try {
                const response = await requestFromServer.uploadImage(
                    formData
                );
                setUploading({
                    ...uploading,
                    [fileId]: false
                });
                if (response.data?.code === CODE_SUCCESS) {
                    result.push({id: fileId, url: response.data?.result?.file_url});
                } else {
                    reactToastify.error(`Tệp ${file?.name} tải lên thất bại`);
                    result.push({id: fileId, url: null});
                }
            } catch (error) {
                setUploading({
                    ...uploading,
                    [fileId]: false
                });
                reactToastify.error(
                    `Tệp ${file?.name} lỗi: ${responseErrorToString(
                        error.response.data.errors
                    )}`
                );
                result.push({id: fileId, url: null});
            }
            return upload(files, result);
        }
    };

    useEffect(() => {
        return () => {
            clearTimeout(refTimeout.current);
            clearTimeout(refTimeoutValueChange.current);
        };
    }, []);

    const {
        isDragActive,
        isDragAccept,
        isDragReject,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept,
        maxFiles: maxFiles || 1,
        maxSize: maxSize,
        ...options,
        onDrop: acceptedFiles => {
            const isUploading = Object.values(uploading).some(v => !!v);
            if (isUploading) {
                reactToastify.error("Có tệp đang tải lên máy chủ");
                return;
            }
            if (maxFiles === 1) {
                setFiles(
                    acceptedFiles.map(file =>
                        Object.assign(file, {
                            id: shortid.generate(),
                            preview: URL.createObjectURL(file)
                        })
                    )
                );
            } else {
                let newFiles = [...files, ...acceptedFiles];
                if (newFiles.length > maxFiles) {
                    reactToastify.error(`Chỉ được chọn tối đa ${maxFiles} tệp`);
                } else {
                    setFiles(
                        newFiles.map(file => {
                            if (file.preview) {
                                return file;
                            } else {
                                return Object.assign(file, {
                                    id: shortid.generate(),
                                    preview: URL.createObjectURL(file)
                                });
                            }
                        })
                    );
                }
            }
        },
        onDropRejected: fileRejections => {
            fileRejections.forEach(({file, errors}) => {
                console.log(file, errors);
                const messages = errors.map(({code}) => {
                    switch (code) {
                        case "file-invalid-type":
                            return "không đúng định dạng";
                        case "file-too-large":
                            //413 Request Entity Too Large
                            return "kích thước quá lớn";
                        case "file-too-small":
                            return "kích thước quá nhỏ";
                        case "too-many-files":
                        default:
                            return "vượt quá số lượng cho phép";
                    }
                });
                reactToastify.error(`Tệp ${file.name}: ${messages.join(", ")}`, {
                    autoClose: 10000
                });
            });
        }
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isDragActive, isDragReject, isDragAccept]
    );
    const thumbs = files.map((file, index) => {
        return (
            <div style={thumb} key={file.id}>
                <div
                    style={thumbInner}
                    className={classList(
                        uploading[file.id] && "uploading-item",
                        "w-100 h-100"
                    )}
                >
                    <Tooltip title={file.name || file.path}>
          <span className="w-100 h-100">
            {uploading[file.id] && (
                <div className="loading-wrapper">
                    <Spinner animation="border" variant="primary" size="sm"/>
                </div>
            )}
              {file.type?.includes("video") && (
                  <video
                      width="100%"
                      height="100%"
                      controls={false}
                      title={file.name}
                  >
                      <source src={file.preview}/>
                      Your browser does not support the video tag.
                  </video>
              )}
              {file?.url && (
                  <img src={file.url} style={img} alt=""/>
              )}
              {!file?.url && (
                  <span className="svg-icon menu-icon w-100 h-100">
                <SVG
                    src={toAbsoluteUrl(`/media/svg/icons/Files/File.svg`)}
                    className="w-100 h-100"
                />
              </span>
              )}
          </span>
                    </Tooltip>
                    <div
                        className="offcanvas-close"
                        style={{position: "absolute", top: 0, right: 0}}
                    >
                        <Tooltip title="Xóa">
                            <a
                                href="#"
                                className="btn btn-xs btn-icon btn-light btn-hover-primary"
                                id="kt_quick_panel_close"
                                onClick={() => removeFile(index)}
                            >
                                <i className="ki ki-close icon-xs text-muted"></i>
                            </a>
                        </Tooltip>
                    </div>
                    {
                        file?.url &&
                        (
                            <div
                                className="offcanvas-close"
                                style={{position: "absolute", bottom: 0, left: 0}}
                            >
                                <Tooltip title="Tải xuống">
                                    <a
                                        href={file.url}
                                        target="_blank"
                                        className="btn btn-xs btn-icon btn-light btn-hover-primary"
                                        id="kt_quick_panel_close"
                                    >
                                        <i className="ki ki-bold-wide-arrow-down icon-xs text-muted"></i>
                                    </a>
                                </Tooltip>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    });

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return (
        <section className={classList(className, "upload-file")}>
            <div {...getRootProps({style, className: "dropzone"})}>
                <input {...getInputProps()} onBlur={onBlur}/>
                <p className="mx-0 my-0">
                    Kéo, thả một số tệp vào đây hoặc nhấp để chọn
                </p>
                {accept && <em>(Chỉ chấp nhận tệp có định dạng {accept})</em>}
                <em>(Dung lượng file tối đa 20MB)</em>
                {maxFiles > 1 && (
                    <em>(Bạn có thể chọn hoặc thả tối đa {maxFiles} tệp)</em>
                )}
            </div>
            {files?.length > 0 && <aside style={thumbsContainer}>{thumbs}</aside>}
        </section>
    );
}

export default UploadFile;

import {
  ADD,
  APPROVE,
  ASSIGN_PERMISSION,
  CONSULT,
  CREATE_ACCOUNT,
  DELETE,
  DETAIL,
  DISABLE_ACCOUNT,
  EDIT,
  ENROLLMENT_TEST,
  EXPORT,
  IMPORT,
  REGISTER,
  RESULT_EXAM,
  VIEW,
  SORT,
  ASSIGNMENT,
  TO_CANDIDATE,
  REGISTER_TRY,
  RESULT_EXAM_INPUT,
  ASSIGN_COURSE_CLASS,
  ASSIGN_COURSE_SKILL,
  SETTING_SCHEDULE, EDIT_CONSULT,
  UPDATE
} from './permission';

export const ENROLLMENT_STATUS_SELECT = [
  {
    value: "partime",
    text: "Bán thời gian"
  },
  {
    value: "fulltime",
    text: "Toàn thời gian"
  }
];

export const TYPE_STUDY_STATUS_SELECT = [
  {
    value: "online",
    text: "Online"
  },
  {
    value: "onsite",
    text: "On-site"
  }
];

export const USER_STATUS = [
  {
    value: "inactive",
    text: "In Active"
  },
  {
    value: "active",
    text: "Active"
  }
];

export const SEX_SELECT = [
  {
    value: "male",
    text: "Nam"
  },
  {
    value: "female",
    text: "Nữ"
  }
];

export const PER_PERIOD_SELECT = [
  {
    value: 1.5,
    text: "1,5 giờ/buổi"
  },
  {
    value: 2,
    text: "2 giờ/buổi"
  }
];

export const PAGE_PERMISSION_SELECT = [
  {
    value: VIEW,
    text: "Hiển thị"
  },
  {
    value: ADD,
    text: "Thêm mới"
  },
  {
    value: UPDATE,
    text: "Sửa"
  },
  {
    value: DELETE,
    text: "Xóa"
  },
  
];

export const SCHEDULE_CLASS_STATUS = [
  {
    value: "waiting",
    color: "warning",
    text: "Waiting for Verifying"
  },
  {
    value: "approve",
    color: "success",
    text: "Verified"
  },
  {
    value: "lesson_off",
    color: "danger",
    text: "Off"
  },
  {
    value: "created",
    color: "info",
    text: "Waiting"
  },
];

export const TEMPLATE_EMAIL = [
  {
    value: 1,
    text: "Mẫu mail buổi học có kèm nhận xét cho học sinh"
  },
  {
    value: 2,
    text: "Mẫu mail buổi học không kèm nhận xét cho học sinh"
  },
  {
    value: 3,
    text: "Mẫu mail thông tin bài kiểm tra Unit Test"
  },
  {
    value: 4,
    text: "Mẫu mail thông tin bài kiểm tra cuối kỳ"
  },
];

import { deepPurple } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState, useEffect, useRef } from "react";
import {Modal} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { SelectionCheckbox } from '../../../../../../../_theme/_helpers';
import { CODE_SUCCESS } from '../../../../../../../constants/system/code';
import { CLASS_SCHEDULE_RESOURCE } from '../../../../../../../constants/system/resource';
import reactToastify from '../../../../../../../plugin/react-toastify';
import { processError } from '../../../../../../../utils/axios';
import { toDateSrt, toTimeSrt, toDateTime } from '../../../../../../../utils/filter';
import { isEmpty } from '../../../../../../../utils/typeof';
import * as requestFromServer from '../../../../../common/DataTable/_redux/entitiesCrud';
import { FormDialog } from '../../../../../common/DataTable/pages/form-dialog/FormDialog';
import { MailDetailForm } from './MailDetailForm';

export function HistorySendMailForm({scheduleId, onHide}) {
  const fetchId = useRef(null);
  const [listLogSendMail, setListLogSendMail] = useState([]);
  const [isShowDetail, setShowDetail] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const openShowEmailDetail = (log) => {
    setItemSelected(log);
    setShowDetail(true);
  }

  const closeShowEmailDetail = (log) => {
    setItemSelected(null);
    setShowDetail(false);
  }

  const fetchDate = () => {
    fetchId.current = (fetchId.current || 0) + 1;
    const fetchIdCurr = fetchId.current;
    setLoading(true);
    requestFromServer.post(
      CLASS_SCHEDULE_RESOURCE, `${scheduleId}/email-log/search`,
      {
        page: 1,
        page_size: -1
      }
    ).then(response => {
        if (fetchIdCurr === fetchId.current) {
          setLoading(false);
          if (!response || response.data?.code === CODE_SUCCESS) {
            const { total, data } = response.data;
            setListLogSendMail(data);
          } else if (response.data?.message){
            reactToastify.error(response.data?.message)
          } else {
            reactToastify.actionFail();
          }
        }
      })
      .catch(error => {
        if (fetchId === fetchId.current) {
          setLoading(false);
          processError(error);
        }
      });
  }

  useEffect(() => {
    if (scheduleId) {
      fetchDate();
    }
  }, [scheduleId]);

  return (
    <div>
      <Modal.Body>
        <FormDialog
          size="lg"
          show={isShowDetail}
          title="Thông tin chi tiết gửi mail"
          item={itemSelected}
          onHide={closeShowEmailDetail}
          ComponentForm={MailDetailForm}
        />
        <div className="overflow-auto border-secondary border" style={{maxHeight: "550px"}}>
          <Table bordered hover className="mb-0">
            <thead>
            <tr>
              <th style={{ minWidth: '60px', maxWidth: '60px' }}>
                STT
              </th>
              <th style={{ minWidth: '120px', maxWidth: '120px' }}>
                Thời gian gửi
              </th>
              <th style={{ minWidth: '250px', maxWidth: '250px' }}>
                To
              </th>
              <th style={{ minWidth: '250px', maxWidth: '250px' }}>
                CC
              </th>
              <th style={{ minWidth: '250px', maxWidth: '250px' }}>
                BCC
              </th>
              <th style={{ minWidth: '120px', maxWidth: '120px' }}>
                Type
              </th>
              <th style={{ minWidth: '60px', maxWidth: '60px' }}>
                Status
              </th>
              <th style={{ minWidth: '60px', maxWidth: '60px' }}>
              </th>
            </tr>
            </thead>
            <tbody>
            {
              isLoading &&
              (
                <tr><td colSpan="8"><div className="w-100 h-25px d-flex align-items-center justify-content-center"><div className="spinner spinner-primary mr-10"/></div></td></tr>
              )
            }
            {
              !isLoading &&
              isEmpty(listLogSendMail) &&
              (
                <tr><td colSpan="8"><div className="text-center">Không có dữ liệu để hiển thị</div></td></tr>
              )
            }
            {
              !isLoading && listLogSendMail.map((log, index) => {
                return (
                  <tr key={`log-${index}`}>
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      {log?.created_at && toDateTime(log?.created_at)}
                    </td>
                    <td>
                      {(log?.receiver || []).join(', ')}
                    </td>
                    <td>
                      {(log?.cc || []).join(', ')}
                    </td>
                    <td>
                      {(log?.bcc || []).join(', ')}
                    </td>
                    <td>
                      {log?.type}
                    </td>
                    <td>
                      {log?.status}
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <Tooltip title="Xem chi tiết">
                          <div
                            onClick={(e) => {
                              openShowEmailDetail(log);
                            }}
                            className="d-inline-block pointer px-1"
                          >
                            <Icon style={{ fontSize: 20, color: deepPurple[700] }}>
                              visibility
                            </Icon>
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Đóng
        </button>
      </Modal.Footer>
    </div>
  );
}

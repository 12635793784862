import { forIn } from 'lodash';
import { HTTP_CODE_422 } from "../constants/system/code";
import reactToastify from "../plugin/react-toastify";
import { responseErrorToString } from "./array";
import { setError } from './formik';

export const processError = (error, setErrors) => {
  console.log(JSON.stringify(error))
  if (error?.response?.status === HTTP_CODE_422) {
    if (setErrors) {
      setErrors(error.response.data.errors);
    } else {
      reactToastify.error(responseErrorToString(error.response.data.errors));
    }
  }
};
export const processErrorFormik = (error, formik) => {
  if (error?.response?.status === HTTP_CODE_422) {
    if (formik) {
      forIn(error?.response?.data?.errors || {}, function(value, key) {
        setError(key, value, formik);
      });
    } else {
      reactToastify.error(responseErrorToString(error.response.data.errors));
    }
  }
};

import { ASIDE_MENU } from "../../../../constants/system/menu";
import axios from "axios";
import reactToastify from '../../../../plugin/react-toastify';
import { processError } from '../../../../utils/axios';
import { isString } from '../../../../utils/typeof';

export const LOGIN_URL = "/user/authenticate";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = "/user/me";
export const USER_INVITE_URL = "/api/user-info";
export const UPDATE_PASSWORD_URL = "/api/user/update-password";
export const LOGOUT_URL = "/cms/auth/logout";





export function login(username, password) {
  return axios.post(LOGIN_URL, { 'phone': username, 'plainPassword': password })
    .then((response) => {
      return response;
    }).catch(error => {
      console.log(error);
      return [401];
    })
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(token, isNoToastify) {
  const authorization = "Bearer " + token;

  const config = {};
  if (token && isString(token)) {
    config.headers = { 'Authorization': authorization };
  }

  return axios.get(ME_URL, config)
    .then(response => {
      if (response?.data?.code === 0) {
        const user = response?.data?.result?.user || {};
        user.menus = ASIDE_MENU;
        user.permissions = [
          "admin-user.view",
          "admin-role.view",
          "admin-permission.view",
          "student-candidate.view",
          "course-list.view",
          "course-skill.view",
          "teacher-list.view",
          "student-data-source.view",
          "class-list.view",
          "class-schedule.view",
          "location-branch-list.view",
          "course-lesson.view",
          "student-raw.view",
          "admin.view",
          "dacnp.view",
          "dashboard.view",
          "system-notification-pattern.view",
          "teacher-schedule.view",
          "student-file-import.view",
          "class-lesson-checkin.view",
          "course-exam-input.view",
          "teacher-statistic-checkin.view",
          "manage-promotion.view",
          "course-result-exam-input.view",
          "student-studying.view",
          "teacher-class.view",
          "admin-menu.view",
          "course-syllabus.view",
          "reservation-student.view",
          "course-list.view",
          "course-skill.view",
          "teacher-list.view",
          "class-list.view",
          "location-branch-list.view",
          "dashboard.view",
          "teacher-schedule.view",
          "course-exam-input.view",
          "teacher-statistic-checkin.view",
          "course-result-exam-input.view",
          "teacher-class.view",
        ];
        return user;
      } else if (response?.data?.message) {
        if (!isNoToastify) {
          reactToastify.error(response?.data?.message);
        }
      } else {
        if (!isNoToastify) {
          reactToastify.error('Lỗi không lấy được thông tin người dùng');
        }
      }
      return Promise.reject(response);
    }).catch(error => {
      if (!isNoToastify) {
        processError(error);
      }
      return error;
    })
}

export function getUserInfoByInviteToken(token) {
  const authorization = "Bearer " + token;

  return axios.get(USER_INVITE_URL, { headers: { 'Authorization': authorization } })
    .then(response => {
      if (response?.data?.code === 0) {
        return response?.data?.data;
      }
      return Promise.reject(response);
    });
}

export function updatePasswordByToken(token, password) {
  const authorization = "Bearer " + token;

  return axios.post(UPDATE_PASSWORD_URL, { password: password }, { headers: { 'Authorization': authorization } })
    .then(response => {
      if (response?.data?.code === 0) {
        return response?.data?.data;
      }
      return Promise.reject(response);
    })
}

import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {Field, Form, Formik, useFormikContext} from "formik";
import * as Yup from "yup";
import {Input, Select} from "../../../../../../../_theme/_partials/controls";
import {SelectionSearch} from "../../../../../../../_theme/_partials/controls/forms/SelectionSearch";
import {SKILL_RESOURCE, TEACHER_RESOURCE} from "../../../../../../../constants/system/resource";
import {classList} from "../../../../../../../utils/DOM/class";
import {TextArea} from "../../../../../../../_theme/_partials/controls/forms/TextArea";
import {useParams} from "react-router-dom";
import {toDateTime} from '../../../../../../../utils/filter';
import {ExaminationType} from "../../../../../../../constants/ExaminationType";
import {isEmpty, isObject} from '../../../../../../../utils/typeof';
import {Upload} from "../../../../../../../_theme/_partials/controls/forms/Upload";
import TextField from "@material-ui/core/TextField";
import {getUnique} from "../../../../../../../utils/array";
import {setError} from "../../../../../../../utils/formik";
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../../../_theme/i18n";

// Validation schema
const getEditSchema = (isShowTeacher, isSelectClassSchedule, messages) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(messages[keyMessages["TEACHER.EXAM_FORM.VALIDATE.NAME_REQUIRE"]]),
    teacher: isShowTeacher ?
      Yup.string()
        .nullable(true)
        .required(messages[keyMessages["TEACHER.EXAM_FORM.VALIDATE.TEACHER_REQUIRE"]]) :
      Yup.string()
        .nullable(true),
    skills: Yup.string()
        .nullable()
      .required(messages[keyMessages["TEACHER.EXAM_FORM.VALIDATE.SKILL_REQUIRE"]]),
    class_schedule: isSelectClassSchedule ?
      Yup.string()
        .required(messages[keyMessages["TEACHER.EXAM_FORM.VALIDATE.DATE_REQUIRE"]]) :
      Yup.string().nullable(true),
    type: Yup.string()
      .required(messages[keyMessages["TEACHER.EXAM_FORM.VALIDATE.TYPE_REQUIRE"]]),
  });
}

const getMedia = values => {
  let media = values?.media || []
  if (!isEmpty(values?.input_url) && values?.input_url?.split) {
    media = [
      ...values?.input_url.split("\n")
        .map(f => f.replace(/\s/, ""))
        .filter(f => !!f)
    ]
  }
  return getUnique(media);
}

const FormContent = (props) => {

  const intl = useIntl();
  const messages = intl?.messages;
  const {
    isNoSelectTeacher,
    classId,
    lesson
  } = props;

  const [render, setRender] = useState(false);
  const [skillsSelected, setSkillSelect] = useState([]);
  const formikContext = useFormikContext();
  const {
    initialValues,
    values,
    submitForm,
    setFieldValue,
    setErrors,
    setFieldTouched
  } = formikContext;

  const {
    skills
  } = values || {};


  useEffect(() => {
  }, [render]);
  useEffect(() => {
    if (skills) {
      const data = skills.map(item => {
        let num_question = item.num_question > 0 ? item.num_question : 1;
        skillsSelected.forEach(selected => {
          if (selected.code === item.code) {
            num_question = selected.num_question;
          }
        });

        return {
          code: item.code,
          name: item.name,
          num_question: parseInt(num_question)
        }
      });

      setSkillSelect(data);
      values.skillsSelected = data;
    } else {
      setSkillSelect([]);
      values.skillsSelected = [];
    }
  }, [skills])

  const onChangeNumQuestion = (code, e) => {
    let numQuestion = parseInt(e.target.value);
    if (numQuestion <= 0)
      numQuestion = 1;
    skillsSelected.forEach(skill => {
      if (skill.code === code)
        skill.num_question = numQuestion;
    });
    values.skillsSelected = skillsSelected;
    setRender(!render);
  }

  return (
    <Form className="form form-label-right">
      <div className="form-group row">
        {/* First Name */}
        <div className={isNoSelectTeacher ? "col-sm-12" : "col-sm-6"}>
          <Field
            name="name"
            component={Input}
            required
            placeholder={messages[keyMessages["TEACHER.EXAM_FORM.NAME"]]}
            label={messages[keyMessages["TEACHER.EXAM_FORM.NAME"]]}
          />
        </div>
        {
          !isNoSelectTeacher &&
          (<div className="col-sm-6">
            <Field
              name="teacher"
              required
              component={SelectionSearch}
              resource={TEACHER_RESOURCE}
              propertyName="name"
              propertyValue="name"
              placeholder={messages[keyMessages["TEACHER.EXAM_FORM.TEACHER"]]}
              label={messages[keyMessages["TEACHER.EXAM_FORM.TEACHER"]]}
            />
          </div>)
        }
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          {
            isEmpty(lesson) &&
            classId &&
            (<Field
              name="class_schedule"
              component={SelectionSearch}
              resource={`api/class/${classId}"/schedule`}
              required
              propertyName="start_time"
              propertyValue="id"
              placeholder={messages[keyMessages["TEACHER.EXAM_FORM.DATE"]]}
              label={messages[keyMessages["TEACHER.EXAM_FORM.DATE"]]}
              formatLabel={(e, a) => {
                return `${toDateTime(e.start_time)} - ${toDateTime(e.end_time)}`;
              }}
            />)
          }
          {
            !isEmpty(lesson) &&
            (<Field
              name="lesson"
              component={Input}
              disabled
              required
              placeholder="Chọn buổi học"
              label="Buổi học"
            />)
          }
        </div>
        <div className="col-sm-6">
          <Field
            name="type"
            required
            component={Select}
            placeholder={messages[keyMessages["TEACHER.EXAM_FORM.TYPE"]]}
            label={messages[keyMessages["TEACHER.EXAM_FORM.TYPE"]]}
            options={ExaminationType}
            style={{zIndex: "0"}}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <Field
            name="media"
            accept=""
            required
            component={Upload}
            maxFiles={10}
            label={messages[keyMessages["TEACHER.EXAM_FORM.ATTACH"]]}
            // isNoUpdate="false"
          />
          <div className="d-flex justify-content-center text-muted">Hoặc</div>
          <Field
            name="input_url"
            component={TextArea}
            placeholder={messages[keyMessages["TEACHER.EXAM_FORM.ATTACH"]]}
            rows={2}
          />
        </div>

        <div className="col-sm-6">
          <Field
            name="skills"
            required
            component={SelectionSearch}
            resource={SKILL_RESOURCE}
            propertyName="name"
            propertyValue="name"
            placeholder={messages[keyMessages["TEACHER.EXAM_FORM.SKILL"]]}
            label={messages[keyMessages["TEACHER.EXAM_FORM.SKILL"]]}
            isMulti={true}
          />
          <br/>
          <div className="row">
            {
              skillsSelected.map((item, index) => {
                return (
                  <div
                    key={`standard-number-${index}`}
                    className="col-md-6"
                  >
                    <TextField
                      className="small p-2"
                      value={item.num_question}
                      id="standard-number"
                      label={`${item.name}`}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                          onChangeNumQuestion(item.code, e)}
                    />
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-12">
          <Field
            name="note"
            component={TextArea}
            placeholder={messages[keyMessages["TEACHER.EXAM_FORM.NOTE"]]}
            label={messages[keyMessages["TEACHER.EXAM_FORM.NOTE"]]}
          />
        </div>
      </div>
    </Form>
  )
}

const Footer = (props) => {
  const intl = useIntl();
  const messages = intl?.messages;
  const {
    actionsLoading,
    handleSubmit,
    onHide
  } = props;

  const formikContext = useFormikContext();

  const {
    initialValues,
    values,
    submitForm,
    setFieldValue,
    setErrors,
    setFieldTouched
  } = formikContext;

  const submit = () => {
    if (isEmpty(getMedia(values))) {
      setError("media", "Bạn chưa thêm tệp đính kèm", formikContext);
    }
    handleSubmit()
  }

  return (
    <>
      <button
        type="button"
        onClick={onHide}
        className="btn btn-light btn-elevate"
      >{messages[keyMessages["FORM.CANCEL"]]}
      </button>
      <> </>
      <button
        type="submit"
        disabled={actionsLoading}
        onClick={() => submit()}
        className="btn btn-primary btn-elevate"
      >
        {actionsLoading ? messages[keyMessages["FORM.SAVING"]]
            : messages[keyMessages["FORM.SAVE"]]}
      </button>
    </>
  )
}


export function ExamEditForm({save, item, actionsLoading, onHide, lesson, isNoSelectTeacher}) {
  const intl = useIntl();
  const messages = intl?.messages;
  const {classId} = useParams();
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item}
        validationSchema={getEditSchema(!isNoSelectTeacher, isEmpty(lesson), messages)}
        onSubmit={(values, formikHelpers) => {
          const entity = {
            id: values.id,
            name: values.name,
            type: values.type,
            skills: values.skillsSelected,
            note: values.note,
            media: getMedia(values)
          };

          if (isEmpty(entity?.media)) {
            return;
          }

          if (values.teacher) {
            entity.teacher_id = values.teacher?.id;
          } else if (item?.teacher_id) {
            entity.teacher_id = item?.teacher_id;
          }

          if (isEmpty(lesson)) {
            if (isObject(values.class_schedule)) {
              entity.class_schedule_id = values.class_schedule.id;
            }
          } else {
            entity.class_schedule_id = lesson.id;
          }

          if (classId) {
            entity.class_id = parseInt(classId);
          }

          save(entity, formikHelpers);
        }}
      >
        {({handleSubmit}) => (
          <>
            <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
              <FormContent classId={classId} lesson={lesson} isNoSelectTeacher={isNoSelectTeacher}/>
            </Modal.Body>
            <Modal.Footer>
              <Footer
                actionsLoading={actionsLoading}
                handleSubmit={handleSubmit}
                onHide={onHide}
              />
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

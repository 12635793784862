import React, {useEffect, useMemo, useState} from "react";
import {Modal} from "react-bootstrap";
import {Field, Form, Formik, useFormikContext} from "formik";
import * as Yup from "yup";
import {DatePickerField, Input, Select,} from "../../../../../_theme/_partials/controls";
import {SelectionSearch} from '../../../../../_theme/_partials/controls/forms/SelectionSearch';
import { DAY_OF_WEEK, getDayOfWeek } from '../../../../../constants/date';
import {TYPE_STUDY_STATUS_SELECT} from '../../../../../constants/system/dataSelect';
import {
  LOCATION_BRANCH_RESOURCE,
  PROGRAM_RESOURCE,
  STAFF_RESOURCE,
  SYLLABUS_RESOURCE,
  TEACHER_RESOURCE
} from '../../../../../constants/system/resource';
import 'bootstrap-daterangepicker/daterangepicker.css';
import BackspaceIcon from '@material-ui/icons/Backspace';
import {MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {CheckBox, PlaylistAdd} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {toDatetime} from '../../../../../utils/filter';
import Checkbox from "@material-ui/core/Checkbox";

const EditSchema = Yup.object().shape({
  location_branch: Yup.string()
    .nullable()
    .required("Chi nhánh là bắt buộc"),
  program: Yup.string()
    .nullable()
    .required("Chương trình học là bắt buộc"),
  syllabus: Yup.string()
    .nullable()
    .required("Syllabus là bắt buộc"),
  start_time: Yup.string()
    .required("Thời gian bắt đầu là bắt buộc"),
  name: Yup.string()
    .required("Tên lớp học là bắt buộc"),
  type_study: Yup.string()
    .nullable()
    .required("Hình thức học là bắt buộc"),
  book_name: Yup.string()
    .nullable()
});

const ruleEditTeacher = ['admin', 'manager', 'staff_leader', "program_designer"]

const FormContent = (props) => {
  const {
    item
  } = props;

  const enable_edit_schedule = item && item.enable_edit_schedule !== undefined
    ? item.enable_edit_schedule : true;

  const roles = useSelector(state => state.auth?.user?.roles);
  const isEditTeacher = useMemo(() => {
    return roles.some(item => ruleEditTeacher.includes(item.name));
  }, [roles])

  const isUpdate = item && item.id !== undefined && item.id !== null;

  const dayOfWeek = {}
  DAY_OF_WEEK.forEach((currentValue) => {
    const {value, code, name} = currentValue;
    dayOfWeek[code] = name;
  }, {});

  const dayOfWeekField = "day_of_week";
  const times = "times";
  const start_hour = "start_hour";
  const end_hour = "end_hour";
  const teacher_id = "teacher_id";
  const teacher_name = "teacher_name";

  const [dayChecked, setDayChecked] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [change, setChange] = useState(true);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [isReGenerateLesson, setIsGenerateLesson] = React.useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleRegenerateLesson = (isRegenerate) => {
    setIsGenerateLesson(isRegenerate);
    values.is_regenerate_lesson = isRegenerate;
  };

  const formikContext = useFormikContext();
  const {
    values,
    setFieldValue,
  } = formikContext;
  const {
    program,
  } = values || {};
  const filterSyllabus = {
    filter_program_id: program?.id
  };

  useEffect(() => {

  }, [change]);

  useEffect(() => {
    if (item.id) {
      item.weekly_schedule.forEach((lesson, index) => {
        const day = lesson[dayOfWeekField];
        dayChecked[day] = true;
        let id = 1;
        lesson[times].forEach((t, indexTime) => {
          t["id"] = id;
          id++;
        })
      });

      setSchedule(item.weekly_schedule)
    }
  }, [item.id]);

  useEffect(() => {
    setFieldValue('class', null, false);

    if (program) {
      setFieldValue('fee', program?.fee, false);
      setFieldValue('document_cost', program?.document_cost, false);
    }
  }, [program]);

  const onCheckDay = (e, a) => {
    setDayChecked({...dayChecked, [a]: e.target.checked});
    const days = {...dayChecked, [a]: e.target.checked};
    const data = Object.keys(days)
      .filter(item => days[item])
      .map(day => {
        let obj = {
          "day_of_week": day,
          "times": []
        };

        schedule.forEach(item => {
          if (item[dayOfWeekField] === day) {
            obj = item;
          }
        });

        return obj;
      });

    let officialWeekSchedule = [];

    Object.keys(dayOfWeek).forEach(dayCode => {
      data.forEach(ws => {
        if (ws["day_of_week"] === dayCode) {
          officialWeekSchedule.push(ws);
        }
      })
    });

    setSchedule(officialWeekSchedule);

    values.weekly_schedule = officialWeekSchedule;
  }

  const deleteTimeInDay = (day, id) => {
    schedule.forEach(item => {
      if (item[dayOfWeekField] === day) {
        item[times] = item[times].filter(t => t["id"] !== id);
      }
    });
    setSchedule(schedule);
    values.weekly_schedule = schedule;
    setChange(!change);
  }

  const updateTeacher = (day, indexTime, keyInput, teacher) => {
    if(teacher !== null) {
    schedule.forEach(item => {
      if (item[dayOfWeekField] === day) {
        item[times].forEach(t => {
          if (t["id"] === indexTime) {
            if (teacher) {
              t["teacher"] = {
                id: teacher.id,
                name: teacher.name
              };
            } else {
              t["teacher"] = null;
            }

            setFieldValue(keyInput, teacher);
          }
        });
      }
    });

    setSchedule(schedule);
    values.weekly_schedule = schedule;
    setChange(!change);
    }
  }

  const updateTime = (
    day,
    id,
    hStart,
    mStart,
    hEnd,
    mEnd,
    isChangeEndTime = false
  ) => {
    let rangeTime = convertDate2String(hStart, mStart, hEnd, mEnd, isChangeEndTime);

    if (rangeTime === null)
      return;

    let startTime = rangeTime[start_hour];
    let endTime = rangeTime[end_hour];

    schedule.forEach(item => {
      if (item[dayOfWeekField] === day && item[times]) {
        item[times].forEach(t => {
          if (t["id"] === id) {
            t[start_hour] = startTime;
            t[end_hour] = endTime;
          }
        });
      }
    });

    setSchedule(schedule);
    values.weekly_schedule = schedule;
    setChange(!change);
  }

  const saveTime = (day, hStart, mStart, hEnd, mEnd) => {
    let rangeTime = convertDate2String(hStart, mStart, hEnd, mEnd);

    let startTime = rangeTime[start_hour];
    let endTime = rangeTime[end_hour];

    schedule.forEach(item => {
      if (item[dayOfWeekField] === day) {
        item[times].push({
          "id": (new Date()).getTime(),
          "start_hour": startTime,
          "end_hour": endTime,
          "teacher": null
        })

        let nameTeacherLesson = `teacher-${day}-${startTime}`;
        setFieldValue(nameTeacherLesson, null, false);
      }
    });

    let weekly_schedule = schedule.filter(day => day.times.length !== 0);

    let officialWeekSchedule = [];

    Object.keys(dayOfWeek).forEach(dayCode => {
      weekly_schedule.forEach(ws => {
        if (ws["day_of_week"] === dayCode) {
          officialWeekSchedule.push(ws);
        }
      })
    });

    setSchedule(officialWeekSchedule);
    values.weekly_schedule = officialWeekSchedule;
    setChange(!change);
  }

  const convertDate2String = (
    hStart,
    mStart,
    hEnd,
    mEnd,
    isChangeEndtime = false
  ) => {
    let valid = true;
    if (parseInt(hStart) > parseInt(hEnd))
      valid = false;

    if (parseInt(hStart) === parseInt(hEnd) && parseInt(mStart) > parseInt(mEnd))
      valid = false;

    if (!valid && isChangeEndtime) {
      alert("Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc !!!");
      return null;
    }


    hStart = hStart < 10 ? "0" + hStart : hStart;
    mStart = mStart < 10 ? "0" + mStart : mStart;
    hEnd = hEnd < 10 ? "0" + hEnd : hEnd;
    mEnd = mEnd < 10 ? "0" + mEnd : mEnd;
    let isExits = false;

    let startTime = hStart + ":" + mStart + ":00";
    let endTime = hEnd + ":" + mEnd + ":00";

    return {
      start_hour: startTime,
      end_hour: endTime
    }
  }

  return (
    <Form className="form form-label-right">
      <div className="form-group row">
        <div className="col-sm-6">
          <Field
            name="name"
            required
            component={Input}
            placeholder="Nhập tên lớp"
            label="Tên lớp"
          />
        </div>

        <div className="col-sm-3">
          <DatePickerField
            required
            name="start_time"
            label="Ngày bắt đầu"
            disabled={!enable_edit_schedule}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <Field
            name="location_branch"
            required
            component={SelectionSearch}
            resource={LOCATION_BRANCH_RESOURCE}
            propertyName="name"
            propertyValue="id"
            placeholder="Chọn chi nhánh"
            label="Chi nhánh"
          />
        </div>

        <div className="col-sm-3">
          <Field
            name="program"
            required
            component={SelectionSearch}
            resource={PROGRAM_RESOURCE}
            propertyName="name"
            propertyValue="id"
            placeholder="Chọn khóa học"
            label="Khóa học"
            isDisabled={!enable_edit_schedule}
          />
        </div>
        <div className="col-sm-3">
          <Field
            name="syllabus"
            required
            component={SelectionSearch}
            resource={SYLLABUS_RESOURCE}
            propertyName="name"
            propertyValue="id"
            placeholder="Chọn syllabus"
            label="Syllabus"
            params={filterSyllabus}
            isDisabled={!enable_edit_schedule}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <Field
            name="book_name"
            component={Input}
            placeholder="Nhập tên giáo trình"
            label="Tên giáo trình"
          />
        </div>
        <div className="col-sm-3">
          <Field
            name="type_study"
            component={Select}
            placeholder="Chọn hình thức học"
            label="Hình thức học"
            required
            options={TYPE_STUDY_STATUS_SELECT}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6" hidden={!isEditTeacher}>
          <Field
            name="staff"
            required
            component={SelectionSearch}
            resource={STAFF_RESOURCE}
            propertyName="full_name"
            propertyValue="id"
            placeholder="Chọn giáo vụ"
            label="Giáo vụ"
          />
        </div>

        <div className="col-sm-6" hidden={!isUpdate}>
          <div>
            Sinh lại các buổi học khi thay đổi lịch học:
            <Field
              name="is_regenerate_syllabus"
              component={Checkbox}
              chec={isReGenerateLesson}
              onChange={e => {
                handleRegenerateLesson(e.target.checked);
              }}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-6">
          <h5>Lịch học tuần</h5>
          {
            Object.keys(dayOfWeek).map((day, index) => {
              return (
                <div key={`day-of-week-${index}`}>
                  <input
                    checked={dayChecked[day]}
                    type="checkbox"
                    name={day}
                    onChange={e => onCheckDay(e, day)}
                  />
                  <span className="font-size-lg"> {dayOfWeek[day]}</span>
                </div>
              )
            })
          }
        </div>
        <div className="col-sm-6">
          {
            schedule.map((obj, index) => {
              return (
                <div key={`schedule-${index}`}>
                  <hr/>
                  <span className="font-weight-bold font-size-lg pb-3">
                    {` Lịch học: ${dayOfWeek[obj[dayOfWeekField]]}`}
                   </span>
                  {
                    obj[times].map((time, indexTime) => {
                      let startTime = new Date("2014-08-18T" + time[start_hour]);
                      let endTime = new Date("2014-08-18T" + time[end_hour]);
                      let keyLesson = `schedule-${index}-${indexTime}`;
                      let nameTeacherLesson = `teacher-${obj["day_of_week"]}-${time["start_hour"]}`;

                      return (
                        <div key={keyLesson} className="row mt-5 ml-1 bg-light"
                             style={{"border-radius": "5%"}}>
                          <div className="col-md-10">
                            <div>
                              <Field
                                name={nameTeacherLesson}
                                component={SelectionSearch}
                                resource={TEACHER_RESOURCE}
                                propertyName="name"
                                propertyValue="id"
                                placeholder="Chọn giáo viên"
                                label="Giáo viên"
                                onChange={(e) => {
                                  updateTeacher(obj[dayOfWeekField], time["id"], nameTeacherLesson, e);
                                }}
                              />
                            </div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container justify="space-around" className="row">
                                <TimePicker
                                  className="col-md-5"
                                  margin="normal"
                                  label="Giờ bắt đầu"
                                  value={startTime}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  onChange={(date) => {
                                    updateTime(
                                      obj[dayOfWeekField],
                                      time["id"],
                                      date && date.getHours(),
                                      date && date.getMinutes(),
                                      date && endTime.getHours(),
                                      date && endTime.getMinutes()
                                    )
                                  }}
                                />
                                <TimePicker
                                  className="col-md-5"
                                  margin="normal"
                                  label="Giờ kết thúc"
                                  value={endTime}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  onChange={(date) => {
                                    updateTime(
                                      obj[dayOfWeekField],
                                      time["id"],
                                      date && startTime.getHours(),
                                      date && startTime.getMinutes(),
                                      date && date.getHours(),
                                      date && date.getMinutes(),
                                      true
                                    )
                                  }}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className="col-md-2">
                                <span type="button"
                                      className="mt-10"
                                      onClick={() => {
                                        if (window.confirm("Bạn có chắc muốn xóa thời gian học này?")) {
                                          deleteTimeInDay(obj[dayOfWeekField], time["id"])
                                        }
                                      }}
                                >
                              <BackspaceIcon fontSize="small" color={"error"}/>
                            </span>
                          </div>
                        </div>
                      )
                    })
                  }

                  <div className={obj[dayOfWeekField]}>
                    <span className="row mt-3" type="button" onClick={() => {
                      saveTime(obj[dayOfWeekField], 8, 0, 10, 0);
                    }}>
                      <PlaylistAdd fontSize="small"/>
                    </span>
                  </div>

                </div>
              )
            })
          }
        </div>
      </div>
    </Form>
  );
}

export function ClassEditForm({save, item, actionsLoading, onHide}) {
  const dayOfWeek = getDayOfWeek();

  if (item.weekly_schedule) {
    item.weekly_schedule.forEach((lesson, index) => {
      if (lesson["times"]) {
        lesson["times"].forEach((t, indexTime) => {
          let nameTeacherLesson = `teacher-${lesson["day_of_week"]}-${t["start_hour"]}`;
          item[nameTeacherLesson] = t["teacher"];
        })
      }
    });
  }

  console.log(item);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item}
        validationSchema={EditSchema}
        onSubmit={(values, formikHelpers) => {
          const schedule = values.weekly_schedule;
          if (schedule === undefined || schedule.length === 0) {
            alert("Lớp chưa có lịch học !!!");
            return;
          }

          if (schedule.filter(day => day.times.length !== 0).length === 0) {
            alert("Lớp chưa có lịch học !!!");
            return;
          }

          let weekly_schedule = schedule.filter(day => day.times.length !== 0);

          let officialWeekSchedule = [];

          Object.keys(dayOfWeek).forEach(dayCode => {
            weekly_schedule.forEach(ws => {
              if (ws["day_of_week"] === dayCode) {
                officialWeekSchedule.push(ws);
              }
            })
          });

          const entity = {
            ...values,
            location_branch_id: values.location_branch.id,
            program_id: values.program.id,
            start_hour: new Date(values.start_hour).getTime(),
            staff_id: values?.staff?.id,
            start_time: toDatetime(values?.start_time),
            teacher_id: values?.teacher?.id,
            syllabus_id: values.syllabus.id,
            weekly_schedule: officialWeekSchedule,
            is_regenerate_lesson: values.is_regenerate_lesson !== undefined ? values.is_regenerate_lesson : false
          };

          console.log(entity);

          save(entity);
        }}
      >
        {({handleSubmit}) => (
          <>
            <Modal.Body>
              <FormContent item={item}/>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Hủy
              </button>
              <> </>
              <button
                type="submit"
                disabled={actionsLoading}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                {actionsLoading ? "Đang lưu..." : "Lưu lại"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Modal, Card } from "react-bootstrap";
import { Field, Form, Formik, replace, useFormikContext } from "formik";
import * as Yup from "yup";
import { DatePickerField, Select } from '../../../../../../_theme/_partials/controls';
import { SelectionSearch } from '../../../../../../_theme/_partials/controls/forms/SelectionSearch';
import { TextArea } from '../../../../../../_theme/_partials/controls/forms/TextArea';
import {
  ADVISORY_TYPE_RESOURCE,
  CLASS_RESOURCE
} from "../../../../../../constants/system/resource";
import { classList } from "../../../../../../utils/DOM/class";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

// Validation schema
const EditSchema = Yup.object().shape({
  class: Yup.object()
    .nullable(),
  content: Yup.string()
    .nullable()
    .required("Nội dung tư vấn là bắt buộc"),
  type: Yup.object()
    .nullable()
    .required("Hình thức tư vấn là bắt buộc"),
  meet_up_at: Yup.number()
    .nullable()
    .required("Thời gian gặp là bắt buộc")
});

export function AdvisoryForm({
  save,
  item,
  actionsLoading,
  onHide,
  selectedData,
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item}
        validationSchema={EditSchema}
        onSubmit={(values, formik) => {

          const entity = {
            ...values,
            type: values?.type?.code,
            class_id: values?.class?.id,
          }

          save(entity, formik);
        }}
      >
        {({ handleSubmit, values, ...props }) => (
          <>
            <Modal.Body
              className={classList(
                "overlay",
                actionsLoading && "overlay-block"
              )}
            >
              <div className="form-group row">
                <div className="col-sm-12 col-md-6">
                  <DatePickerField
                    timePicker
                    name="meet_up_at"
                    label="Ngày gặp"
                    placeholder="Chọn ngày gặp"
                    require
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <Field
                    name="type"
                    component={SelectionSearch}
                    resource={ADVISORY_TYPE_RESOURCE}
                    propertyName="name"
                    propertyValue="id"
                    required
                    placeholder="Chọn hình thức"
                    label="Hình thức"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <Field
                    name="class"
                    component={SelectionSearch}
                    resource={CLASS_RESOURCE}
                    propertyName="name"
                    propertyValue="id"
                    placeholder="Chọn lớp"
                    label="Lớp"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <DatePickerField
                    timePicker
                    name="appointment"
                    label="Lần hẹn tới"
                    placeholder="Chọn ngày hẹn tới"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <Field
                    name="content"
                    required
                    component={TextArea}
                    placeholder="Nhập nội dung tư vấn"
                    label="Nội dung tư vấn"
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Hủy
              </button>
              <> </>
              <button
                type="submit"
                disabled={actionsLoading}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                {actionsLoading ? "Đang lưu..." : "Lưu lại"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

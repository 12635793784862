import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilterDefault, sizePerPageListDefault } from "./DataTableUIHelpers";
import { isEmpty } from '../../../../../utils/typeof';

const DataTableUIContext = createContext();

export function useDataTableUIContext() {
  return useContext(DataTableUIContext);
}

export const DataTableUIConsumer = DataTableUIContext.Consumer;

export function DataTableUIProvider(props) {
  const { dataTableUIEvents, headers, filters = [], toolbars = [], children, initData, buttons, isShowIndex = true, isSelectRow = false, initialFilter = initialFilterDefault, sizePerPageList = sizePerPageListDefault } = props;
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [isOpenSettingColumns, setOpenSettingColumns] = useState(false);
  const [ids, setIds] = useState([]);
  const fetchDataServer = (params) => {
    // xử lý chuyển đổi param
    const {
      filters = [],
      sorts = [],
      page = 1,
      page_size = 10,
      keyword = "",
    } = params;

    const newParams = {
      filters,
      sorts,
      page,
      page_size,
      keyword,
    }

    if (!isEmpty(filters)) {
      newParams.filters = filters.map(filter => {
        return {
          name: filter?.property,
          operation: filter?.operator,
          value: filter?.value,
        }
      })
    }

    if (!isEmpty(sorts)) {
      newParams.sorts = sorts;
    }

    return dataTableUIEvents.fetchData(newParams);
  }
  const reloadData = () => {
    fetchDataServer(queryParams);
  }

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    toolbars,
    queryParams,
    sizePerPageList,
    setQueryParamsBase,
    ids,
    setIds,
    initData,
    setQueryParams,
    headers,
    filters,
    buttons,
    isOpenSettingColumns,
    isShowIndex,
    isSelectRow,

    //Call event button click
    setOpenSettingColumns: setOpenSettingColumns,
    dataTableUIEvents,
    clickNewItem: dataTableUIEvents.clickNewItem,
    clickEditItem: dataTableUIEvents.clickEditItem,
    clickDeleteItem: dataTableUIEvents.clickDeleteItem,
    clickImportData: dataTableUIEvents.clickImportData,
    clickExportData: dataTableUIEvents.clickExportData,
    clickViewDetailItem: dataTableUIEvents.clickViewDetailItem,

    //Call data event
    reloadData,
    fetchData: fetchDataServer,
    getDetail: dataTableUIEvents.getDetail,
    newData: dataTableUIEvents.newData,
    editData: dataTableUIEvents.editData,
    deleteData: dataTableUIEvents.deleteData,
    signUp2Learn: dataTableUIEvents.signUp2Learn,
  };

  return (
    <DataTableUIContext.Provider value={value}>
      {children}
    </DataTableUIContext.Provider>
  );
}

import axios from "axios";

export function uploadImage(entity) {
  return axios.post("/api/v1/upload/general_cdn", entity, { baseURL: "https://api.alert.vn" });
}

// CREATE =>  POST: add a new product to the server
export function createEntity(resource, entity) {
  return axios.post(`${resource}`, entity);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findEntities(resource, queryParams) {
  return axios.post(`${resource}/search`, queryParams);
}
export function post(resource, path, body) {
  return axios.post(`${resource}${!!path ? `/${path}` : ''}`, body);
}

export function detail(resource, id) {
  return axios.get(`${resource}/detail?id=${id}`);
}

export function put(resource, path, body) {
  return axios.put(`${resource}/${path}`, body);
}

export function deleteP(resource, path, data) {
  return axios.delete(`${resource}/${path}`, { data: data });
}

export function get(resource, path, queryParams) {
  return axios.get(`${resource}${!!path ? `/${path}` : ''}`, {
    params: queryParams
  });
}

// UPDATE => PUT: update the procuct on the server
export function updateEntity(resource, entity) {
  return axios.post(`${resource}/update`, entity);
}

// DELETE => delete the product from the server
export function deleteEntity(resource, entityId) {
  return axios.delete(`${resource}/delete`, { data: { id: entityId } });
}

export function selectEntities(resource, queryParams) {
  return axios.get(`${resource}/select`, {
    params: queryParams
  });
}

// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, {useEffect, useMemo, useState} from "react";
import {Modal} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {DatePickerField,} from "../../../../../../../_theme/_partials/controls";
import {CheckboxField} from '../../../../../../../_theme/_partials/controls/forms/CheckboxField';
import {SelectionSearch} from '../../../../../../../_theme/_partials/controls/forms/SelectionSearch';
import {TextArea} from '../../../../../../../_theme/_partials/controls/forms/TextArea';
import { getDayOfWeek, DAY_OF_WEEK } from '../../../../../../../constants/date';
import {CLASS_SCHEDULE_RESOURCE, ROOM_RESOURCE, TEACHER_RESOURCE} from '../../../../../../../constants/system/resource';
import { toTimeFull24, toDateSrt, getDateTimeLessonNextFromLastLesson } from '../../../../../../../utils/filter';
import {isEmpty} from '../../../../../../../utils/typeof';
import * as requestFromServer from '../../../../../common/DataTable/_redux/entitiesCrud';
import {useSelector} from "react-redux";

// Validation schema
const EditSchema = Yup.object().shape({
  start_time: Yup.string()
    .nullable()
    .required("Thời gian học là bắt buộc"),
  // lesson_name: Yup.string()
  //   .nullable()
  //   .required("Tên buổi học là bắt buộc"),
  lesson_content: Yup.string()
    .nullable()
    .required("Nội dung buổi học là bắt buộc"),
  lesson_homework: Yup.string()
    .nullable()
    .required("Nội dung BTVN buổi học là bắt buộc"),
  // room: Yup.object()
  //   .nullable()
  //   .required("Bạn chưa chọn phòng học"),
  // teacher: Yup.object()
  //   .nullable()
  //   .required("Bạn chưa chọn giáo viên")
});

export function ClassScheduleForm({
                                    save,
                                    classInfo,
                                    item,
                                    actionsLoading,
                                    onHide,
                                    show,
                                    classId,
                                    startTimeClass,
                                    isNoChangeTime,
                                    isHiddenFieldTeacher,
                                  }) {

  const ruleEditTeacher = ['admin', "program_designer"]
  const roles = useSelector(state => state.auth?.user?.roles);
  const weeklySchedule = classInfo?.weekly_schedule || [];
  isNoChangeTime = useMemo(() => {
    return !roles.some(item => ruleEditTeacher.includes(item.name));
  }, [roles])


  const [lastLesson, setLastLesson] = useState(null);

  const lastScheduleSuggest = useMemo(() => {
    if (lastLesson && !isEmpty(weeklySchedule)) {
      try {
        const scheduleList = [];
        weeklySchedule.forEach(schedule => {
          const times = schedule?.times || [];
          times.forEach(time => {
            scheduleList.push({
              day_of_week: schedule?.day_of_week,
              start_hour: time?.start_hour,
              end_hour: time?.end_hour,
              teacher: time?.teacher,
              teacher_id: time?.teacher?.id,
              ...getDateTimeLessonNextFromLastLesson(
                lastLesson?.start_time,
                {
                  day_of_week: schedule?.day_of_week,
                  start_hour: time?.start_hour,
                  end_hour: time?.end_hour,
                }
              )
            })
          })
        });

        if (isEmpty(scheduleList)) {
          return {};
        }

        const dateStartTime = new Date(lastLesson?.start_time);
        const indexDayOfWeek = dateStartTime.getDay();
        const timeStart = toTimeFull24(lastLesson?.start_time);

        const dayOfLastLesson = DAY_OF_WEEK.find(day => day.value === indexDayOfWeek);
        const codeDayOfLastLesson = dayOfLastLesson?.code;

        const indexDayScheduleOfLastLesson = scheduleList.findIndex(schedule => {
          return schedule?.day_of_week === codeDayOfLastLesson && schedule?.start_hour === timeStart;
        })

        if (indexDayScheduleOfLastLesson !== -1) {
          const indexNext = (indexDayScheduleOfLastLesson + 1)%scheduleList?.length;
          return scheduleList[indexNext];
        }

        return scheduleList[0];
      } catch (e) {
        console.log(e);
      }
    }

    return {};
  }, [lastLesson, weeklySchedule])

  useEffect(() => {
    requestFromServer
      .get(CLASS_SCHEDULE_RESOURCE, 'last-lesson', {class_id: classId})
      .then(rs => {
        setLastLesson(rs?.data?.data);
      }).catch(error => {
      setLastLesson(null);
    })
  }, [show, item?.id])

  const isEdit = useMemo(() => {
    return isEmpty(item?.id);
  }, [item?.id]);

  const configDate = {}

  // if (item?.previous_schedule?.end_time) {
  //   configDate.minDate = moment(item?.previous_schedule?.end_time + 1000).toDate();
  // } else if (lastLesson?.end_time) {
  //   configDate.minDate = moment(lastLesson?.end_time + 1000).toDate();
  // } else if (startTimeClass) {
  //   configDate.minDate = moment(startTimeClass).toDate();
  // }

  // if (item?.next_schedule?.start_time) {
  //   configDate.maxDate = moment(item?.next_schedule?.start_time - 1000).toDate();
  // }

  return (
    <>
      <Formik
        key={`edit-class-${item?.id}`}
        enableReinitialize={true}
        initialValues={!item?.id ? { ...item, ...lastScheduleSuggest }: item}
        validationSchema={EditSchema}
        onSubmit={(values, formikHelpers) => {
          const data = {
            ...values,
            room_id: values?.room?.id,
            teacher_id: values?.teacher?.id || null,
          }

          save(data, formikHelpers);
        }}
      >
        {({handleSubmit}) => (
          <>
            <Modal.Body>
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="room"
                      component={SelectionSearch}
                      resource={ROOM_RESOURCE}
                      propertyName="name"
                      propertyValue="id"
                      placeholder="Chọn phòng học"
                      label="Phòng học"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-6 col-sm-12">
                    <DatePickerField
                      key={`start_time-${lastLesson?.start_time || startTimeClass}`}
                      timePicker
                      name="start_time"
                      label="Thời gian bắt đầu"
                      placeholder="Chọn thời gian"
                      // config={configDate}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <DatePickerField
                      key={`end_time-${lastLesson?.start_time || startTimeClass}`}
                      timePicker
                      name="end_time"
                      label="Thời gian kết thúc"
                      placeholder="Chọn thời gian"
                      // config={configDate}
                    />
                  </div>
                </div>
                {
                  !isHiddenFieldTeacher &&
                  (<div className="form-group row">
                    <div className="col-sm-12">
                      <Field
                        name="teacher"
                        component={SelectionSearch}
                        resource={TEACHER_RESOURCE}
                        propertyName="name"
                        propertyValue="id"
                        placeholder="Chọn giáo viên"
                        label="Giáo viên"
                      />
                    </div>
                  </div>)
                }
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="lesson_content"
                      required
                      component={TextArea}
                      placeholder="Nhập nội dung buổi học"
                      label="Nội dung buổi học"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="lesson_homework"
                      required
                      component={TextArea}
                      placeholder="Nhập nội dung BTVN"
                      label="Nội dung BTVN"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6">
                    <CheckboxField
                      name="require_take_care"
                      label="Cần chăm sóc"
                    />
                  </div>

                  <div className="col-sm-6">
                    <CheckboxField
                      name="require_teacher_comment"
                      label="Yêu cầu giáo viên nhận xét"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Hủy
              </button>
              <> </>
              <button
                type="submit"
                disabled={actionsLoading}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                {actionsLoading ? "Đang lưu..." : "Lưu lại"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

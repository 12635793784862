export const PERMISSION_RESOURCE = "/cms/permission";
export const USER_SYSTEM_RESOURCE = "/cms/user";
export const MENU_SYSTEM_RESOURCE = "/api/admin/menu";
export const ROLE_RESOURCE = "/cms/role";
export const STUDENT_RESOURCE = "/api/student";
export const CANDIDATE_RESOURCE = "/api/candidate";
export const CANDIDATE_POTENTIAL_RESOURCE = "/api/candidate/potential";
export const SOURCE_RESOURCE = "/api/source";
export const SKILL_RESOURCE = "/api/skill";
export const EXAM_RESOURCE = "/api/exam";
export const PROGRAM_RESOURCE = "/api/program";
export const ENROLLMENT_STATUS = "/api/enrollment_status";
export const PROGRAM_SKILL_RESOURCE = "/api/program-skill";
export const LOCATION_BRANCH_RESOURCE = "/api/location-branch";
export const SELLER_RESOURCE = "/api/seller";
export const CANDIDATE_RAW_RESOURCE = "/api/candidate/raw-data";
export const CANDIDATE_STATUS = "/api/candidate/status";
export const TEACHER_RESOURCE = "/api/teacher";
export const DEGREE_RESOURCE = "/api/degree";
export const UPLOAD_RESOURCE = "/api/upload";
export const CLASS_RESOURCE = "/api/class";
export const CLASS_CANDIDATE_RESOURCE = "/api/class-candidate";
export const CLASS_SCHEDULE_RESOURCE = "/api/class-schedule";
export const CLASS_SCHEDULE_OFF_RESOURCE = "/api/class-schedule/off";
export const CLASS_STUDENT_RESOURCE = "/api/class-student";
export const COUNTRY_RESOURCE = "/api/country";
export const LESSON_RESOURCE = "/api/lesson";
export const STUDENT_PAYMENT_RESOURCE = "/api/student-payment";
export const STUDENT_PAYMENT_CLASS_RESOURCE = "/api/student-payment-class";
export const STUDENT_EXAMINATION_RESOURCE = "/api/student-examination";
export const EXAMINATION_RESOURCE = "/api/examination";
export const ROOM_RESOURCE = "/api/room";
export const STUDENT_TEST_RESOURCE = "/api/student/test-input";
export const ADVISORY_RESOURCE = "/api/advisory";
export const ADVISORY_TYPE_RESOURCE = "/api/advisory-type";
export const NOTIFICATION_PATTERN_RESOURCE = "/api/notification-pattern";
export const CLASS_STUDENT_RESERVE = "/api/class-student/reserve";
export const CLASS_STUDENT_TRANSFER = "/api/class-student/transfer";
export const CLASS_STUDENT_CHANGE = "/api/class-student/change-class";
export const PAGE_RESOURCE = "/api/page";
export const FILE_IMPORT_RESOURCE = "/api/candidate/import";
export const NOTIFICATION_RESOURCE = "/api/notification";
export const FCM_RESOURCE = "/api/fcm";
export const CLASS_STUDENT_CHECKIN_RESOURCE = "/api/class-student-checkin";
export const STAFF_RESOURCE = "/api/staff";
export const STUDY_TARGET_RESOURCE = "/api/study-target";
export const ENTRANCE_EXAM_RESOURCE = "/api/entrance-exam";
export const TEACHER_CHECKIN_RESOURCE = "/api/teacher/checkin";
export const TEACHER_UPDATE_STUDENT_RESOURCE = "/api/teacher/update-student";
export const PROMOTION_RESOURCE = "/api/promotion";
export const STUDENT_WAITING_CLASS_RESOURCE = "/api/student/waiting-class";
export const TEACHER_SCHEDULE_RESOURCE = "/api/teacher/schedule";
export const STUDENT_STUDYING_RESOURCE = "/api/student/studying";
export const RESERVATION_STUDENT = "/api/student/reserve";
export const SYLLABUS_RESOURCE = "/api/syllabus";
export const CATEGORY_NEW_TYPE = "/cms/category-new";
export const HOMEPAGE_NEWS = "cms/home-page";
export const APPLICANT_RESOURCE = "/cms/applicant"
export const JOB_RECRUITMENT_RESOURCE = "/cms/job-recruitment";
export const INTRODUCTION_RESOURCE = "/cms/introduction"
export const PARTNER_RESOURCE = "/cms/partner";
export const COOPERATION_CONTACT_RESOURCE = "/cms/cooperation"
export const PRODUCT = "cms/product"
export const CONTACT = "/cms/contact";
export const CO_FOUNDER = "cms/co-founder";
export const NEWS = '/cms/news'
export const UPDATENEWS = '/news'


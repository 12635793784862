import React from 'react';
import { flatten } from 'lodash';
import { isArray, isObject } from './typeof';

export const responseErrorToString = errors => {
  return (
    <>
      {
        flatten(Object.values(errors)).map((text, index) => {
          return <div key={`message-${index}`}>{text}</div>
        })
      }
    </>
  )
};

export const getUnique = (arr, ...comps) => {
  if (arr && isArray(arr)) {
    if (isArray(comps) && comps.length > 0) {
      return arr
        .map(e => comps.reduce((key, comp) => {
          const prototypes = comp.split('.');
          const value = prototypes.reduce((object, prototype) => {
            if (isObject(object) || isArray(object)) {
              return object[prototype];
            }
            return null;
          }, e);
          return `${key}-${value}`;
        }, ''))

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);
    } else {
      return arr
        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);
    }
  }
  return [];
};

import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { isFunction } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import { DELETE, EDIT } from '../../../../../../constants/system/permission';
import { usePermissionContext } from '../../../../../components/PermissionContext';
const colorMUI = ["inherit","primary","secondary","action","error","disabled"];
const DataTableAction = (props) => {
  const {
    1: row,
    actions = []
  } = props;
  const checkPermissionEdit = () => {
    if (row.item_permissions) {
      return row.item_permissions.includes(EDIT);
    }
    return true;
  };
  const checkPermissionDelete = () => {
    if (row.item_permissions) {
      return row.item_permissions.includes(DELETE);
    }
    return true;
  }
  const checkVisibleRow = (action) => {
    const { actionName, visible } = action;
    if (isFunction(visible)) {
      return visible(row);
    }
    //  else if (actionName === EDIT) {
    //   return checkPermissionEdit();
    // } else if (actionName === DELETE) {
    //   return checkPermissionDelete();
    // }
    return true;
  }
  return (
    <div className="nowrap text-center">
      {
        actions.map((action, index) => {
          const { color, label, icon, visible, event } = action;
          return (
            checkVisibleRow(action) && (
              <Tooltip key={`toolbar-datatable-action-${index}`} title={label}>
                <div
                  onClick={(e) => event(row)}
                  className="d-inline-block pointer px-1"
                >
                  <Icon color={(colorMUI.includes(color) && color) || "inherit"} style={{ fontSize: 20, color: !colorMUI.includes(color) && color }}>
                    {icon}
                  </Icon>
                </div>
              </Tooltip>
            )
          );
        })
      }
    </div>
  );
};

DataTableAction.propTypes = {};

DataTableAction.defaultProps = {};

export default DataTableAction;

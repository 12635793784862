import React, {useEffect, useMemo, useState} from 'react';
import {
  ADVISORY_RESOURCE,
  CLASS_RESOURCE,
  CLASS_STUDENT_RESOURCE,
  USER_SYSTEM_RESOURCE
} from "../../../../../../../constants/system/resource";
import {processHeaderTable} from '../../../../../../../utils/table';
import {isEmpty} from '../../../../../../../utils/typeof';
import {DataTableProvider} from "../../../../../common/DataTable/pages/DataTableContext";
import {DataTableDeleteDialog} from '../../../../../common/DataTable/pages/delete-dialog/DataTableDeleteDialog';
import {LoadingDialog} from "../../../../../common/DataTable/pages/loading-dialog/LoadingDialog";
import {EditDialog} from "../../../../../common/DataTable/pages/edit-dialog/EditDialog";
import {DataTableCard} from "../../../../../common/DataTable/pages/DataTableCard";
import {DataTableUIProvider} from "../../../../../common/DataTable/pages/DataTableUIContext";
import {Link} from "react-router-dom";
import * as columnFormatters from "../../../../../common/DataTable/pages/table/column-formatters";
import {PriceColumnFormatter} from "../../../../../common/DataTable/pages/table/column-formatters";
import * as requestFromServer from "../../../../../common/DataTable/_redux/entitiesCrud";
import moment from "moment";
import {MMDDYYYY} from "../../../../../../../constants/system/dateformat";
import $ from "jquery";
import DescriptionColumnFormatter
  from '../../../../../common/DataTable/pages/table/column-formatters/DescriptionColumnFormatter';
import {SignUpLearnForm} from '../../../../../Student/pages/candidate/SignUpLearnForm';
import {ConfirmSendEmailDialog} from './ConfirmSendEmailDialog';
import {StudentPayFeeForm} from "./StudentPayFeeForm";
import {FormDialog} from "../../../../../common/DataTable/pages/form-dialog/FormDialog";
import {PickStudent2GrowUpClassForm} from "./PickStudent2GrowUpClassForm";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {keyMessages} from "../../../../../../../_theme/i18n";
import {AdvisoryForm} from "../../../../../Student/pages/candidate/AdvisoryForm";
import {useIntl} from "react-intl";
import {CONSULT} from "../../../../../../../constants/system/permission";

const ListStudentOfClass = (props) => {

  const intl = useIntl();
  const messages = intl?.messages;
  const {
    actions,
    renderCell,
    classId,
    classInformation
  } = props;

  const [selectedData, setSelectedData] = useState();
  const [showDialogDelete, setDialogDelete] = useState(false);
  const [showDialogForm, setDialogForm] = useState(false);
  const [showDialogConfirmFormSendEmail, setDialogConfirmFormSendEmail] = useState(false);
  const [showDialogDetailFee, setDialogDetailFee] = useState(false);
  const [showDialogGrowUpClass, setShowDialogGrowUpClass] = useState(false);
  const [showAdvisoryDialog, setShowAdvisoryDialog] = useState(false);

  const closeDialogAdvisory = () => {
    setSelectedData(null);
    setShowAdvisoryDialog(false);
  };
  const openDialogAdvisory = () => setShowAdvisoryDialog(true);

  const addAdvisory = ({ data }) => {
    return requestFromServer.createEntity(ADVISORY_RESOURCE, {
      ...data,
      class_id: data.class?.id,
      type: data.type?.code,
    });
  };

  const openDialogDelete = () => setDialogDelete(true);
  const closeDialogDelete = () => {
    setSelectedData(null);
    setDialogDelete(false);
  };

  const openDialogConfirmFormSendEmail = () => setDialogConfirmFormSendEmail(true);
  const closeDialogConfirmFormSendEmail = () => {
    setDialogConfirmFormSendEmail(false);
  };

  const openDialogForm = () => setDialogForm(true);
  const closeDialogForm = () => {
    setSelectedData(null);
    setDialogForm(false);
  };
  const openDialogDetailFee = () => setDialogDetailFee(true);
  const closeDialogDetailFee = () => {
    setSelectedData(null);
    setDialogDetailFee(false);
  };
  const openDialogGrowUpClass = () => setShowDialogGrowUpClass(true);
  const closeDialogGrowUpClass = () => {
    setSelectedData(null);
    setShowDialogGrowUpClass(false);
  };

  const uiEvents = {
    clickAdvisory: (data) => {
      setSelectedData(data);
      openDialogAdvisory(true);
    },
    clickNewItem: () => {

      setSelectedData({
        class: classInformation,
        program: classInformation?.program,
        location_branch: classInformation?.location_branch,
        fee: classInformation?.fee,
        document_cost: classInformation?.document_cost,
        start_time: classInformation?.start_time
      });
      openDialogForm(true);
    },
    clickEditItem: dataEdit => {
      openDialogForm();
      setSelectedData(dataEdit);
    },
    clickDeleteItem: data => {
      openDialogDelete();
      setSelectedData(data);
    },
    clickViewDetailItem: data => {
      openDialogDetailFee();
      setSelectedData(data);
    },
    fetchData: query => {
      const url = CLASS_RESOURCE + `/${classId}/student`;
      return requestFromServer.findEntities(url, query);
    },
    newData: data => {
      return requestFromServer.createEntity(CLASS_STUDENT_RESOURCE, data);
    },
    editData: data => {
      return requestFromServer.updateEntity(CLASS_STUDENT_RESOURCE, data);
    },
    deleteData: id => {
      return requestFromServer.deleteEntity(CLASS_STUDENT_RESOURCE, id);
    },
  };

  const generateProver = (info) => {
    return <Popover
        id={`popper-id-${info?.id}`}
        style={{width: '300px'}}
    >
      <Popover.Title as="h3">{info?.name}</Popover.Title>
      <Popover.Content>
        <div>
          <b>Tên tiếng anh: </b>
          {info?.english_name}
        </div>
        <div>
          <b>Email: </b>
          {info?.email}
        </div>
        <div>
          <b>Số điện thoại: </b>
          {info?.phone}
        </div>
      </Popover.Content>
    </Popover>;
  }

  let headers = useMemo(() => {
    let headersRaw = [
      {
        dataField: "student.name",
        text: "Học sinh",
        headerStyle: (column, colIndex) => {
          return { minWidth: "250px" };
        },
        sort: true,
        formatter: (cellContent, row) => {
          const overlay = generateProver(row.student)
          return (
              <OverlayTrigger overlay={overlay} placement="auto" trigger={['hover', 'focus']}>
                <Link to={`/student/${row?.student_id}`}>
                  <div className="d-flex align-items-center" style={{"width": "200px"}}>
                    <div className="symbol symbol-40 symbol-sm flex-shrink-0">
                      <img src={row?.student?.avatar_url || '/media/users/blank.png'} className="border rounded-circle" alt={`avatar-of-${cellContent}`} />
                    </div>
                    <div className="ml-4">
                <span className="text-dark-75 text-hover-primary font-weight-bolder font-size-lg mb-0 link">
                  <u>{cellContent}</u>
                </span>
                    </div>
                  </div>
                </Link>
              </OverlayTrigger>)
        }
      },
      {
        dataField: "start_time",
        text: "Thời gian học bắt đầu",
        formatter: columnFormatters.DateTimeColumnFormatter
      },
      {
        dataField: "num_period",
        text: "Số buổi đã đóng",
      },
      {
        dataField: "used_period",
        text: "Số buổi đã học",
      },
      {
        dataField: "remain_period",
        text: "Số buổi còn lại",
      },
      {
        dataField: "fee",
        text: "Học phí gốc",
        formatter: ((cellContent, row) => {
          return PriceColumnFormatter(cellContent, row, "")
        })
      }, {
        dataField: "discount",
        text: "Học phí được trừ",
        formatter: ((cellContent, row) => {
          return PriceColumnFormatter(cellContent, row, "")
        })
      },
      {
        dataField: "document_cost",
        text: "Tài liệu",
        formatter: ((cellContent, row) => {
          return PriceColumnFormatter(cellContent, row, "")
        })
      },
      {
        dataField: "must_pay",
        text: "Tổng HP, TL phải nộp",
        formatter: ((cellContent, row) => {
          return PriceColumnFormatter(row.fee - row.discount + row.document_cost, row, "")
        })
      },
      {
        dataField: "paid",
        text: "Thực nộp",
        formatter: ((cellContent, row) => {
          return PriceColumnFormatter(cellContent, row, "")
        })
      },
      {
        dataField: "debt",
        text: "Thừa, thiếu HP",
        formatter: ((cellContent, row) => {
          return PriceColumnFormatter(cellContent, row, "")
        })
      },
      {
        dataField: "fee_description",
        text: "Diễn giải học phí",
        headerStyle: (column, colIndex) => {
          return { minWidth: "300px" };
        },
        formatter: ((cellContent, row) => {
          return <DescriptionColumnFormatter shortNumWord={99} content={cellContent}/>
        })
      },
      {
        dataField: "action",
        text: "Hành động",
        headerStyle: (column, colIndex) => {
          return {width: "120px", textAlign: "right"};
        },
        actions: [
          {
            color: "primary",
            icon: "edit",
            label: "Chỉnh sửa",
            event: uiEvents.clickEditItem
          },
          {
            color: "error",
            icon: "attach_money",
            label: "Chi tiết học phí",
            event: uiEvents.clickViewDetailItem,
            visible: (row) => row?.class_fee,
          },
          {
            color: "error",
            icon: "delete",
            label: "Xóa học sinh khỏi lớp",
            event: uiEvents.clickDeleteItem
          },
          {
            color: 'primary',
            icon: "chat",
            label: messages[keyMessages['USER_CANDIDATE.ACTION.ADVISORY']],
            actionName: CONSULT,
            // visible: (row) => row?.item_permissions?.includes(CONSULT),
            event: uiEvents.clickAdvisory
          },
        ]
      }
    ];

    headersRaw = processHeaderTable(headersRaw, {
      actions,
      renderCell
    })

    return headersRaw;
  }, [actions, renderCell])

  const initData = {
    id: null,
    fee: classInformation?.fee,
    document_cost: classInformation?.document_cost,
    discount: classInformation?.discount,
    start_time: moment(Date.now()).format(MMDDYYYY).toString(),
    fee_description: "",
    class: classInformation,
    student: "",
    program: classInformation?.program,
    location_branch: classInformation?.location_branch
  };

  useEffect(() => {
    if (classId) {
      // loadStudents();

      $(".tab-content").addClass("col-md-12")
    }
  }, [classId])

  const toolbars = [
    {
      icon: "trending_up",
      label: "Lên lớp mới",
      title: "Chọn học sinh chuyển lên lớp mới sau khi kết thúc lớp này",
      event: openDialogGrowUpClass
    },
    {
      icon: "group_add",
      label: "Tạo tài khoản",
      title: "Kích hoạt tài khoản cho học sinh được chọn",
      visible: ({ids}) => !isEmpty(ids),
      event: openDialogConfirmFormSendEmail
    }
  ];

  const savePickStudent2GrowUp = () => {

  }

  const createUserStudents = ({data}) => {
    return requestFromServer.post(USER_SYSTEM_RESOURCE, 'link-student', data);
  }

  return (
    <DataTableUIProvider
      headers={headers}
      dataTableUIEvents={uiEvents}
      initData={initData}
      toolbars={toolbars}
      isSelectRow
      initialFilter={{
        filters: [],
        sorts: [],
        page: 1,
        page_size: -1
      }}
      sizePerPageList={[
        { text: "Tất cả", value: -1 },
      ]}
    >
      <DataTableProvider>
        <LoadingDialog/>
        <EditDialog
          size="lg"
          id={selectedData && selectedData.id}
          show={showDialogDetailFee}
          onHide={closeDialogDetailFee}
          showRegisterForm={() => setDialogForm(true)}
          item={{
            ...selectedData,
            created_at: moment(Date.now()).format(MMDDYYYY).toString()
          }}
          title={"Chi tiết học phí: " + selectedData?.student?.name}
          ComponentForm={StudentPayFeeForm}
        />
        <EditDialog
          isStudent
          id={selectedData && selectedData.id}
          size={"lg"}
          show={showDialogForm}
          onHide={closeDialogForm}
          initData={initData}
          item={selectedData}
          title={"Đăng ký lớp học"}
          ComponentForm={SignUpLearnForm}
        />
        <FormDialog
          size="lg"
          noShowMessage
          show={showDialogGrowUpClass}
          title={`Chọn học sinh lên lớp`}
          initData={classInformation}
          selectedData={classInformation}
          onHide={closeDialogGrowUpClass}
          onSubmit={savePickStudent2GrowUp}
          ComponentForm={PickStudent2GrowUpClassForm}
        />
        <FormDialog
          size="lg"
          messageSuccess="Kích hoạt tài khoản thành công"
          show={showDialogConfirmFormSendEmail}
          title={`Tạo tài khoản cho học sinh`}
          onHide={closeDialogConfirmFormSendEmail}
          onSubmit={createUserStudents}
          ComponentForm={ConfirmSendEmailDialog}
        />

        <FormDialog
            show={showAdvisoryDialog}
            size="lg"
            title={messages[keyMessages['USER_CANDIDATE.FORM.TITLE_ADVISORY']]?.create(selectedData?.student)}
            initData={{
              "student": selectedData?.student,
              "student_id": selectedData?.student_id,
              "class": selectedData?.class,
              "class_id": selectedData?.class_id,
              "content": "",
              "type": null,
              "meet_up_at": Date.now(),
              "appointment": null
            }}
            messageSuccess={messages[keyMessages['USER_CANDIDATE.FORM.MESSAGE_SUCCESS_ADVISORY']]}
            selectedData={selectedData?.student}
            onHide={closeDialogAdvisory}
            onSubmit={addAdvisory}
            ComponentForm={AdvisoryForm}
            autoHide={false}
        />
        <DataTableDeleteDialog
          id={selectedData?.id}
          show={showDialogDelete}
          onHide={closeDialogDelete}
          title="Bạn có muốn xóa học sinh ra khỏi lớp"
          content={
            <div>
              Bạn có muốn xóa học sinh&nbsp;
              <span className="text-danger">
                {selectedData?.student_name}
              </span>
              &nbsp;ra khỏi lớp này ?
            </div>
          }
        />
        <DataTableCard/>
      </DataTableProvider>
    </DataTableUIProvider>
  );
}

ListStudentOfClass.propTypes = {};

ListStudentOfClass.defaultProps = {};

export default ListStudentOfClass;

import { Button } from '@material-ui/core';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useIntl } from 'react-intl';
import { keyMessages } from '../../../../../../_theme/i18n';

const CustomToggle = React.forwardRef((props, ref) => {
  const {
    children,

    onClick
  } = props;
  const handleClick = e => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <Button
      ref={ref}
      size="medium"
      className="button-filter"
      onClick={handleClick}
    >
      {children}
    </Button>
  );
});

const DropdownItem = (props) => {
  const {
    filter,
    clickDropdown
  } = props;

  const handleClickDropdown = (e) => {
    clickDropdown({
      target: e?.target,
      filter
    })
  }

  return (
    <Dropdown.Item
      onClick={handleClickDropdown}
    >
      {filter.label}
    </Dropdown.Item>
  );
};

const DropdownFilter = (props) => {
  const intl = useIntl();
  const messages = intl?.messages;
  const refButtonFilterDetail = React.useRef(null);

  const {
    filters,
    clickDropdown
  } = props;

  const handleClickDropdown = ({ target, filter }) => {
    clickDropdown({
      target,
      filterSelected: filter,
      targetDropdown: refButtonFilterDetail?.current
    })
  }

  return (
    <Dropdown className="d-inline ml-2 mt-1">
      <Dropdown.Toggle
        aria-describedby="popper-content-filter"
        size="sm"
        as={CustomToggle}
        ref={refButtonFilterDetail}
      >
        {messages[keyMessages['DATATABLE.TOOLBAR.ADD_FILTER']]}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filters.map((filter, index) => {
          return (
            <DropdownItem
              filter={filter}
              key={`filter-shortcut-item-${index}`}
              clickDropdown={handleClickDropdown}
            />
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownFilter;

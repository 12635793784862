/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { isFunction, isObject, isArray } from '../../../../../../../utils/typeof';

export const MobileColumnFormatter = ({ formatter, column, row, rowIndex, dataExtract }) => {
  const {
    hidden,
    dataField,
    text
  } = column || {};
  if (hidden) return null;
  const cellContent = row?.[dataField];
  return (
    <React.Fragment>
      <div className="v-data-table__mobile-row__header">{text}</div>
      <div className="v-data-table__mobile-row__cell">
        {
          isFunction(formatter) ? formatter(row?.[dataField], row, rowIndex, dataExtract) : (isObject(cellContent) || isArray(cellContent) ? JSON.stringify(cellContent) : cellContent)
        }
      </div>
    </React.Fragment>
  );
};

/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_theme/_partials/controls";
import { CODE_SUCCESS } from '../../../../../../constants/system/code';
import reactToastify from '../../../../../../plugin/react-toastify';
import { processError } from '../../../../../../utils/axios';
import { useDataTableContext } from "../DataTableContext";
import { useDataTableUIContext } from "../DataTableUIContext";
import _ from "lodash";

export function DataTableDeleteDialog(props) {
  const { id, show, onHide, title, content } = props;
  const dataTableUIContext = useDataTableUIContext();
  const dataTableContext = useDataTableContext();

  const actionsLoading = dataTableContext.state.actionsLoading;


  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // server request for saving permission
  const deleteItem = () => {
    // server request for updating permission
    dataTableContext.startAction();
    dataTableUIContext
      .deleteData(id)
      .then(response => {
        if (response.data?.code === CODE_SUCCESS) {
          reactToastify.deleteSuccess();
          dataTableContext.action(dataTableContext.reducers.entityDeleted, {
            payload: {
              id: id
            }
          });
          onHide();
        } else {
          reactToastify.actionFail();
        }
      })
      .catch(error => {
        processError(error);
      })
      .then(rs => {
        dataTableContext.endAction();
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!actionsLoading &&
          (_.isString(content) ? <span>{content}</span> : content)}
        {actionsLoading && <span>Đang xóa...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Hủy
          </button>
          <> </>
          <button
            type="button"
            disabled={actionsLoading}
            onClick={deleteItem}
            className="btn btn-primary btn-elevate"
          >
            {actionsLoading ? 'Đang xóa...' : 'Xóa'}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

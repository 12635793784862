import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import { useFormikContext } from 'formik';
import { groupBy } from "lodash";
import React, { useMemo } from "react";
import { useIntl } from 'react-intl';
import { keyMessages } from "../../../../../../_theme/i18n";
import { classList } from "../../../../../../utils/DOM/class";
import { isArray, isEmpty } from "../../../../../../utils/typeof";
import DropdownFilter from "./DropdownFilter";

const FilterDetailChip = (props) => {
  const {
    filters,
    items,

    setAnchorElPopper,
    setFilterSelected,
    openPopperFilter
  } = props;

  const { values, setValues } = useFormikContext();

  if (isArray(items) && !isEmpty(items)) {
    const textShow = items
      .map(f => {
        const { operator, name, text } = f;
        let operatorStr = ` ${operator} `;
        if (operator === "like") {
          operatorStr = ": ";
        } else if (operator === "eq") {
          operatorStr = " = ";
        } else if (operator === "gte") {
          operatorStr = " >= ";
        } else if (operator === "lte") {
          operatorStr = " <= ";
        }
        return `${name}${operatorStr}${text}`;
      })
      .join(" & ");

    const { property, name } = items[0];

    const onClick = (e) => {
      const filterSelected = filters.find(f => f.name === property);
      if (filterSelected) {
        setAnchorElPopper(e.target);
        setFilterSelected(filterSelected);
        openPopperFilter();
      }
    }

    const onDelete = () => {
      const newValues = { ...values };
      delete newValues[property];
      setValues({ ...values, [property]: null })
    }

    return (
      <Chip
        className="font-size-13 bg-primary text-white filter-item-chip mx-1 mt-1"
        label={textShow}
        clickable
        color="secondary"
        onClick={onClick}
        onDelete={onDelete}
      />
    );
  }

  return null;
}

const DataTableFilterDetail = props => {
  const intl = useIntl();
  const messages = intl?.messages;

  const {
    filters,
    valueFilter,
    queryFilter,

    isOpenDetailFilter,
    closeDetailFilter,

    setAnchorElPopper,
    setFilterSelected,
    openPopperFilter,
    resetFilter
  } = props;

  const { setValues } = useFormikContext();

  const refFilterContentDetail = React.useRef(null);

  const isFilterSelectedEmpty = useMemo(() => {
    return isEmpty(valueFilter)
  }, [valueFilter])

  if (!isOpenDetailFilter) {
    return null;
  }

  const handleClickDropdownFilter = ({ target, filterSelected, targetDropdown }) => {
    setAnchorElPopper(targetDropdown);
    setFilterSelected(filterSelected);
    openPopperFilter();
  }

  const onReset = () => {
    setValues({});
    resetFilter();
  }

  return (
    <div className="row row-filter-content">
      <div className="col-sm-9 pl-0">
        <div
          className="d-inline-flex align-items-center justify-content-center height-2"
          style={{ marginLeft: "1.5rem" }}
        >
          <span className="svg-icon menu-icon">
            <i
              className={classList(
                "fas fa-filter mt-1",
                !isFilterSelectedEmpty && "text-primary"
              )}
            />
          </span>
        </div>
        <div
          ref={refFilterContentDetail}
          className="d-inline-flex flex-wrap ml-2"
        >
          {Object.entries(groupBy(queryFilter, "property")).map(
            ([property, valueGroup], index) => {
              return (
                <FilterDetailChip
                  key={`chip-value-filter-${index}`}
                  filters={filters}
                  items={valueGroup}

                  setAnchorElPopper={setAnchorElPopper}
                  setFilterSelected={setFilterSelected}
                  openPopperFilter={openPopperFilter}
                />
              )
            }
          )}
          <DropdownFilter
            filters={filters}
            clickDropdown={handleClickDropdownFilter}
          />
        </div>
      </div>
      <div
        className="col-sm-3 d-flex align-items-center justify-content-end height-2-5"
        style={{ paddingRight: "1.5rem" }}
      >
        <Tooltip title={messages[keyMessages["DATATABLE.TOOLBAR.RESET"]]}>
          <div
            onClick={onReset}
            className="d-flex flex-column align-items-center justify-content-center button-custom pointer"
          >
            <i className="fas fa-redo" />
            <div className="line-height-initial">
              {messages[keyMessages["DATATABLE.TOOLBAR.RESET"]]}
            </div>
          </div>
        </Tooltip>
        <Tooltip title={messages[keyMessages["DATATABLE.TOOLBAR.CLOSE"]]}>
          <CloseIcon
            className="pointer ml-2"
            style={{ fontSize: 22, color: "#757575" }}
            onClick={closeDetailFilter}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default DataTableFilterDetail;

import React, {useState} from "react";
import * as requestFromServer from "../../../common/DataTable/_redux/entitiesCrud";
import {
  CLASS_RESOURCE,
  PROGRAM_RESOURCE,
  TEACHER_SCHEDULE_RESOURCE,
} from "../../../../../constants/system/resource";
import * as columnFormatters from "../../../common/DataTable/pages/table/column-formatters";
import {DATE_RANGE, SELECT_SOURCE} from "../../../../../constants/filterType";
import {DataTableUIProvider} from "../../../common/DataTable/pages/DataTableUIContext";
import {DataTableProvider} from "../../../common/DataTable/pages/DataTableContext";
import {LoadingDialog} from "../../../common/DataTable/pages/loading-dialog/LoadingDialog";
import {DataTableCard} from "../../../common/DataTable/pages/DataTableCard";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../_theme/i18n";

const ListClassOfTeacherPage = ({history}) => {
  const intl = useIntl();
  const messages = intl?.messages;
  const uiEvents = {

    clickViewSchedule: dataEdit => {

    },

    fetchData: query => {
      return requestFromServer.findEntities(TEACHER_SCHEDULE_RESOURCE, query);
    },
  };

  const headers = [
    {
      dataField: "class.name",
      text: messages[keyMessages['CLASS.NAME']],
      formatter: (cellContent, row) => {
        return(
          <Link to={"/class/" + row.class_id}>{cellContent}</Link>
        )
      }
    },
    {
      dataField: "program.name",
      text: messages[keyMessages['PROGRAM.NAME']],
    },
    {
      dataField: "location_branch.name",
      text: messages[keyMessages['LOCATION_BRANCH']],
    },
    {
      dataField: "room.name",
      text: messages[keyMessages['ROOM.NAME']],
    },
    {
      dataField: "start_time",
      text: messages[keyMessages['START_TIME']],
      formatter: columnFormatters.DateTimeColumnFormatter,
      sort: true,
      direction: "desc"
    },
    {
      dataField: "class.end_time",
      text: messages[keyMessages['END_TIME']],
      formatter: columnFormatters.DateTimeColumnFormatter
    },

    {
      dataField: "action",
      text: messages[keyMessages['DATATABLE.COLUMN.ACTION']],
      headerStyle: (column, colIndex) => {
        return {width: "120px", textAlign: "right"};
      },
      actions: [
        {
          color: "error",
          icon: "date_range",
          label: messages[keyMessages['SCHEDULE']],
          event: uiEvents.clickDeleteItem,
          // actionName: DELETE,
        }
      ]
    }
  ];

  const filters = [
    {
      type: DATE_RANGE,
      label: messages[keyMessages['DATE_RANGE']],
      name: "checked_at"
    },
    {
      type: SELECT_SOURCE,
      label: messages[keyMessages['CLASS.NAME']],
      name: "class_id",
      resource: CLASS_RESOURCE,
      propertyName: "name",
      propertyValue: "id",
    },
    {
      type: SELECT_SOURCE,
      label: messages[keyMessages['PROGRAM.NAME']],
      name: "program_id",
      resource: PROGRAM_RESOURCE,
      propertyName: "name",
      propertyValue: "id",
    },
  ];

  return (
    <DataTableUIProvider
      headers={headers}
      dataTableUIEvents={uiEvents}
      filters={filters}
    >
      <DataTableProvider pageName="teacher-class">
        <LoadingDialog/>
        <DataTableCard dataTableUIEvents={uiEvents}/>
      </DataTableProvider>
    </DataTableUIProvider>
  );
};

ListClassOfTeacherPage.propTypes = {};

ListClassOfTeacherPage.defaultProps = {};

export default ListClassOfTeacherPage;

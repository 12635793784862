import React from "react";


export const formatMoney = (amount, unit) => {
  const decimalCount = 0, decimal = ".", thousands = ","

  try {
    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '')
      + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands)
      + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "") + " " + unit;
  } catch (e) {
    console.log(e)
  }
};

export const formatMoneyNotUnit = (amount) => {
  return amount.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const PriceColumnFormatter = (cellContent, row, unit) => {
  return formatMoney(cellContent, unit);
}


// Validation schema
import {Field, Form, Formik} from "formik";
import {Modal} from "react-bootstrap";
import {classList} from "../../../utils/DOM/class";
import {Input} from "../../../_theme/_partials/controls";
import React, {useState} from "react";
import {EditDialogHeader} from "../common/DataTable/pages/edit-dialog/EditDialogHeader";
import Avatar from "react-avatar-edit";
import * as requestFromServer from "../common/DataTable/_redux/entitiesCrud";
import {UPLOAD_RESOURCE} from "../../../constants/system/resource";
import axios from "axios";
import reactToastify from "../../../plugin/react-toastify";
import {CODE_SUCCESS} from "../../../constants/system/code";
import * as Yup from "yup";

const EditSchema = Yup.object().shape({
  full_name: Yup.string()
    .required("Tên là bắt buộc")
})

export function UserProfileEditForm({dataInit, actionsLoading, onHide, isShow}) {
  const [avatarFile, setAvatarFile] = useState();

  const onBeforeFileLoad = (elem) => {
    // if (elem.target.files[0].size > 71680) {
    //   alert("File is too big!");
    //   elem.target.value = "";
    // }
  }

  const onFileLoad = (file) => {
    setAvatarFile(file);
    console.log(file);
  }
  const onCrop = (file) => {
    if(file && avatarFile.name){
      fetch(file)
        .then(res => res.blob())
        .then(blob => {
          const f = new File([blob], avatarFile?.name, {type: avatarFile?.type});
          console.log(f);
          setAvatarFile(f);
        });
    }
  }

  const uploadAvatar = async () => {
    console.log(avatarFile);
    let formData = new FormData();
    formData.append("file", avatarFile);
    return await requestFromServer.post(UPLOAD_RESOURCE, "file", formData);
  }

  return (
    <Modal
      // size={size}
      backdrop="static"
      show={isShow}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <EditDialogHeader
        title="Update profile"
        actionsLoading={actionsLoading}
      />
      <>
        <Formik
          enableReinitialize={true}
          initialValues={dataInit}
          validationSchema={EditSchema}
          onSubmit={values => {
            console.log(avatarFile);
            console.log(values);
            if(avatarFile){
              uploadAvatar(values)
                .then(response => {
                  if (response.data?.code === CODE_SUCCESS) {
                    values.avatar = response.data?.data?.url;
                    console.log(values);
                    axios.post("/api/user/update-self", values)
                      .then(res => {
                        reactToastify.success("Cập nhật dữ liệu thành công !!!");
                        onHide();
                      })
                      .catch(err => console.log(err));
                  }
                  else {
                    reactToastify.error("Upload avatar thất bại !!!");
                  }
                });
            }
            else {
              axios.post("/api/user/update-self", values)
                .then(res => {
                  reactToastify.success("Cập nhật dữ liệu thành công !!!");
                  onHide();
                })
                .catch(err => console.log(err));
            }
          }}
        >
          {({handleSubmit}) => (
            <>
              <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success"/>
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-sm-12">
                      <div>Avatar</div>
                      <div align="center">
                        <Avatar
                          className="symbol symbol-150 mr-5 ml-5 "
                          // onClose={onClose}
                          width={200}
                          height={200}
                          onCrop={onCrop}
                          onFileLoad={onFileLoad}
                          onBeforeFileLoad={onBeforeFileLoad}
                          minCropRadius={0}
                          cropRadius={100}
                          label={"Choose avatar"}
                          src={dataInit.avatar}
                          shadingOpacity={0.3}
                        />
                      </div>
                      {/*<i className="symbol-badge bg-success"/>*/}
                    </div>
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-sm-12">
                      <Field
                        name="full_name"
                        component={Input}
                        required
                        label="Full Name"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-sm-12">
                      <Field
                        name="phone_number"
                        component={Input}
                        label="Phone Number"
                      />
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >Cancel</button>
                <> </>
                <button
                  type="submit"
                  disabled={actionsLoading}
                  onClick={handleSubmit}
                  className="btn btn-primary btn-elevate"
                >
                  {actionsLoading ? "Saving..." : "Save"}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    </Modal>
  );
}

import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from '../../../../app/components/DatePicker';

import { classList } from "../../../../utils/DOM/class";
import { Checkbox } from './Checkbox';

const getFieldCSSClasses = (touched, errors, isClassForm = true) => {
  const classes = [];
  if (isClassForm) {
    classes.push("form-control");
  }
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function CheckboxField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field, required] = useField(props);
  const onBlur = field.onBlur;
  return (
    <>
      <span
        className={getFieldCSSClasses(touched[field.name], errors[field.name], false)}
      >
        <Checkbox
          isSelected={!!field?.value}
          onChange={e => {
            setFieldValue(field.name, e.target.checked);
          }}
          onBlur={onBlur}
        >
          {props.label}
        </Checkbox>
      </span>
      {errors[field.name] && touched[field.name] && (
        <div className="invalid-feedback">{errors[field.name].toString()}</div>
      )}
    </>
  );
}

export function isObject (value) {
  return value && typeof value === 'object' && value.constructor === Object;
}

export function isArray (value) {
  return value && typeof value === 'object' && value.constructor === Array;
}

export function isString (value) {
  return typeof value === 'string' || value instanceof String;
}

export function isEmpty(value) {
  if (isString(value)) {
    return value === '' || value === undefined || value === null;
  } else if (isObject(value)) {
    return value === null || value === undefined || Object.keys(value).length === 0;
  } else if (isArray(value)) {
    return value === undefined || value === null || value.length === 0;
  }
  return value === undefined || value === null;
}

export function isNumber (value) {
  // eslint-disable-next-line no-restricted-globals
  return typeof value === 'number' && isFinite(value);
}

export function isNumberStr (value) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(value);
}

export function isFunction (value) {
  return typeof value === 'function';
}

// Returns if a value is null
export function isNull (value) {
  return value === null;
}

// Returns if a value is undefined
export function isUndefined (value) {
  return typeof value === 'undefined';
}

export function isBoolean (value) {
  return typeof value === 'boolean';
}

export function isRegExp (value) {
  return value && typeof value === 'object' && value.constructor === RegExp;
}

export function isError (value) {
  return value instanceof Error && typeof value.message !== 'undefined';
}

export function isDate (value) {
  return value instanceof Date;
}

export function isSymbol (value) {
  return typeof value === 'symbol';
}

export function isExistKey(object, key) {
  // eslint-disable-next-line no-prototype-builtins
  return isObject(object) && object.hasOwnProperty(key);
}

export function isEmptyObject(object) {
  // eslint-disable-next-line no-prototype-builtins
  return isObject(object) && Object.keys(object).length === 0;
}

export function isErrorResponse (response) {
  return !(!isExistKey(response, 'code') || response.code === 0);
}

export function isAsyncFunction (value) {
  return isFunction(value) && value.constructor.name === 'AsyncFunction';
}

export function isPromise (value) {
  return value instanceof Promise;
}

import React from "react";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {ClassDetail} from './ClassDetail';

export function TeacherClassDetail() {
  const tabs = ["student-view", "schedule", "exam"]
  const paramsComponents = {
    schedule: {
      actions: ["Check Attendance", "Chấm điểm"]
    }
  }

  return (
    <ClassDetail
      showTabs={tabs}
      paramsComponents={paramsComponents}
      isSettingInformation={false}
    />
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import {
  CODE_SUCCESS,
  HTTP_CODE_422
} from "../../../../../../constants/system/code";
import reactToastify from "../../../../../../plugin/react-toastify";
import { responseErrorToString } from "../../../../../../utils/array";
import { processError, processErrorFormik } from "../../../../../../utils/axios";
import { classList } from "../../../../../../utils/DOM/class";
import { useDataTableContext } from "../DataTableContext";
import { useDataTableUIContext } from "../DataTableUIContext";
import { EditDialogHeader } from "./EditDialogHeader";

export function EditDialog({
  id,
  title,
  show,
  onHide,
  item,
  onSubmit,
  ComponentForm,
  size = "md",
  ...attrs
}) {
  const [formData, setFormData] = useState({});
  // Permissions UI Context
  const dataTableUIContext = useDataTableUIContext();
  const dataTableContext = useDataTableContext();
  const dataTableUIProps = useMemo(() => {
    return {
      initData: dataTableUIContext.initData,
      getDetail: dataTableUIContext.getDetail,
      newData: dataTableUIContext.newData,
      editData: dataTableUIContext.editData
    };
  }, [dataTableUIContext]);

  const actionsLoading = dataTableContext.state.actionsLoading;

  useEffect(() => {
    // server call for getting Permission by id
    if (_.isFunction(dataTableUIProps.getDetail)) {
      dataTableContext.startAction();
      dataTableUIProps
        .getDetail(id)
        .then(response => {
          if (response.data?.code === CODE_SUCCESS) {
            setFormData(response.result);
          } else {
            reactToastify.actionFail();
          }
        })
        .catch(error => {
          processError(error);
        })
        .then(rs => {
          dataTableContext.endAction();
        });
    } else {
      setFormData(item);
    }
  }, [id, item]);

  // server request for saving permission
  const saveData = (data, formik) => {
    if (_.isFunction(onSubmit)) {
      onSubmit({
        data,
        dataTableContext,
        dataTableUIContext
      });
      return;
    }
    if (!id) {
      // server request for updating permission
      dataTableContext.startAction();
      dataTableUIProps
        .newData(data)
        .then(response => {
          if (response.data?.code === CODE_SUCCESS) {
            reactToastify.addSuccess();
            dataTableContext.action(dataTableContext.reducers.entityCreated, {
              payload: {
                data: response.data.result
              }
            });
            onHide();
          } else {
            reactToastify.actionFail();
          }
        })
        .catch(error => {
          processErrorFormik(error, formik);
        })
        .then(rs => {
          dataTableContext.endAction();
        });
    } else {
      // server request for updating permission
      dataTableContext.startAction();
      dataTableUIProps
        .editData(data)
        .then(response => {
          if (response.data?.code === CODE_SUCCESS) {
            reactToastify.editSuccess();
            dataTableContext.action(dataTableContext.reducers.entityUpdated, {
              payload: {
                data: response.data.result
              }
            });
            onHide();
          } else {
            reactToastify.actionFail();
          }
        })
        .catch(error => {
          processErrorFormik(error, formik);
        })
        .then(rs => {
          dataTableContext.endAction();
        });
    }
  };
  return (
    <Modal
      size={size}
      backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <EditDialogHeader
        id={id}
        item={formData || dataTableUIProps.initData}
        title={title}
        actionsLoading={actionsLoading}
      />
      <div className={classList("overlay", actionsLoading && "overlay-block")}>
        {actionsLoading && (
          <div className="overlay-layer zindex-2 bg-transparent">
            <div className="spinner-border text-success" />
          </div>
        )}

        <ComponentForm
          show={show}
          save={saveData}
          actionsLoading={actionsLoading}
          item={formData || dataTableUIProps.initData}
          onHide={onHide}
          {...attrs}
          style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto'
          }}
        />
      </div>
    </Modal>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { classList } from '../../../../../../utils/DOM/class';

const DataIndication = (props) => {
  const {
    listLoading,
    entities
  } = props;

  if (entities?.length === 0 && !listLoading) {
    return (
      <div className="text-muted px-6 text-center">Không có dữ liệu để hiển thị</div>
    );
  }
  return null;
};

DataIndication.propTypes = {};

DataIndication.defaultProps = {};

export default DataIndication;

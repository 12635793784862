/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_theme/_partials/controls";
import reactToastify from '../../../../../../plugin/react-toastify';
import { classList } from '../../../../../../utils/DOM/class';
import { isFunction } from '../../../../../../utils/typeof';
import { useDataTableContext } from "../DataTableContext";
import { useDataTableUIContext } from "../DataTableUIContext";
import _ from "lodash";

export function DataTableConfirmDialog(props) {
  const { type = "btn-primary", item, show, onHide, title, content, event, labelConfirm, centered = false, messageSuccess, noShowMessage, onSuccess } = props;
  const dataTableUIContext = useDataTableUIContext();
  const dataTableContext = useDataTableContext();
  const [loading, setLoading] = useState(false);
  let actionsLoading = loading;
  if (dataTableUIContext) {
    actionsLoading = dataTableContext.state.actionsLoading;
  }

  // if !item we should close modal
  useEffect(() => {
    if (!item) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  // server request for saving permission
  const confirm = () => {
    if (dataTableUIContext && !isFunction(event)) {
      // server request for updating permission
      dataTableContext.startAction();
      event(item)
        .then(response => {
          dataTableContext.endAction();
          if (!response || response.data.code === 0) {
            dataTableContext.reloadPage();
            if (!noShowMessage) {
              if (messageSuccess) {
                reactToastify.success(messageSuccess)
              } else {
                reactToastify.editSuccess();
              }
            }
            if (isFunction(onSuccess)) {
              onSuccess(response.data.data)
            }
            setTimeout(() => {
              onHide();
            }, 100);
          }
        })
        .catch(error => {
          dataTableContext.endAction();
          if (!noShowMessage) {
            reactToastify.actionFail();
          }
          console.log(error);
        });
    } else {
      setLoading(true);
      event(item)
        .then(response => {
          setLoading(false);
          if (!response || response.data.code === 0) {
            if (!noShowMessage) {
              if (messageSuccess) {
                reactToastify.success(messageSuccess)
              } else {
                reactToastify.editSuccess();
              }
            }
            if (isFunction(onSuccess)) {
              onSuccess(response.data.data)
            }
            setTimeout(() => {
              onHide();
            }, 100);
          }
        })
        .catch(error => {
          setLoading(false);
          if (!noShowMessage) {
            reactToastify.actionFail();
          }
          console.log(error);
        });
    }
  };

  return (
    <Modal
      show={show}
      centered={centered}
      onHide={onHide}
    >
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!actionsLoading &&
          (_.isString(content) ? <span>{content}</span> : content)}
        {actionsLoading && <span>Đang xử lý...</span>}
      </Modal.Body>
      <Modal.Footer className="py-3">
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Hủy
          </button>
          <> </>
          <button
            type="button"
            disabled={actionsLoading}
            onClick={confirm}
            className={classList(type, "btn btn-elevate")}
          >
            {actionsLoading ? 'Đang xử lý...' : labelConfirm}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

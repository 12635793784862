import { green } from '@material-ui/core/colors';
import React, {useState} from 'react';
import {
  CLASS_CANDIDATE_RESOURCE,
  CLASS_RESOURCE, CLASS_STUDENT_RESOURCE,
} from "../../../../../../../constants/system/resource";
import { toDateSrt } from '../../../../../../../utils/filter';
import {DataTableProvider} from "../../../../../common/DataTable/pages/DataTableContext";
import { FormDialog } from '../../../../../common/DataTable/pages/form-dialog/FormDialog';
import {LoadingDialog} from "../../../../../common/DataTable/pages/loading-dialog/LoadingDialog";
import {DataTableDeleteDialog} from "../../../../../common/DataTable/pages/delete-dialog/DataTableDeleteDialog";
import {DataTableCard} from "../../../../../common/DataTable/pages/DataTableCard";
import {DataTableUIProvider} from "../../../../../common/DataTable/pages/DataTableUIContext";
import * as requestFromServer from "../../../../../common/DataTable/_redux/entitiesCrud";
import moment from "moment";
import {MMDDYYYY} from "../../../../../../../constants/system/dateformat";
import { SignUpLearnForm } from '../../../../../Student/pages/candidate/SignUpLearnForm';
import { SignUp2LearnForm } from './SignUp2LearnForm';

const StudentLearnTry = (props) => {
  const {
    classId,
    classInformation
  } = props;

  const [selectedData, setSelectedData] = useState();
  const [showDialogForm, setDialogForm] = useState(false);
  const [showSignUpDialog, setShowSignUpDialog] = useState(false);
  const [showDialogDelete, setDialogDelete] = useState(false);

  const openDialogForm = () => setDialogForm(true);
  const closeDialogForm = () => {
    setSelectedData(null);
    setDialogForm(false);
  };
  const openDialogDelete = () => setDialogDelete(true);
  const closeDialogDelete = () => {
    setSelectedData(null);
    setDialogDelete(false);
  };
  const openDialogSignUp = () => setShowSignUpDialog(true);
  const closeDialogSignUp = () => {
    setSelectedData(null);
    setShowSignUpDialog(false);
  };

  const uiEvents = {
    clickNewItem: () => {
      setSelectedData(null);
      openDialogForm(true);
    },
    clickSignUpItem: data => {
      openDialogSignUp();
      setSelectedData(data);
    },
    clickDeleteItem: dataDelete => {
      openDialogDelete();
      setSelectedData(dataDelete);
    },

    fetchData: query => {
      const url = CLASS_RESOURCE + `/${classId}/candidate`;
      return requestFromServer.findEntities(url, query);
    },
    newData: data => {
      return requestFromServer.createEntity(CLASS_CANDIDATE_RESOURCE, data);
    },
    editData: data => {
      return requestFromServer.updateEntity(CLASS_CANDIDATE_RESOURCE, data);
    },
    deleteData: id => {
      return requestFromServer.deleteEntity(CLASS_CANDIDATE_RESOURCE, id);
    }
  };

  const headers = [
    {
      dataField: "candidate.name",
      text: "Học viên",
    },
    {
      dataField: "start_time",
      text: "Ngày đăng ký",
      formatter: value => toDateSrt(value)
    },
    {
      dataField: "action",
      text: "Hành động",
      headerStyle: (column, colIndex) => {
        return { width: "120px", textAlign: "right" };
      },
      actions: [
        {
          color: "primary",
          icon: "assignment_turned_in",
          label: "Đăng ký học",
          event: uiEvents.clickSignUpItem
        },
        {
          color: "error",
          icon: "delete",
          label: "Xóa",
          event: uiEvents.clickDeleteItem
        }
      ]
    }
  ];

  const initData = {
    id: null,
    name: "",
    num_lesson: 1,
    start_time: moment(Date.now()).format(MMDDYYYY).toString(),
    payment_method: "",
    reason: ""
  };

  const signUpLearn = ({ data }) => {
    return requestFromServer.post(CLASS_STUDENT_RESOURCE, 'store', data);
  };
  const signUp2Learn = ({ data }) => {
    return requestFromServer.post(CLASS_CANDIDATE_RESOURCE, 'store', {
      ...data,
      class_id: classId,
      program_id: classInformation?.program_id,
      location_branch_id: classInformation?.location_branch_id,
      candidate_id: data?.candidate?.id
    });
  };

  return (
    <DataTableUIProvider
      headers={headers}
      dataTableUIEvents={uiEvents}
      initData={initData}
    >
      <DataTableProvider>
        <LoadingDialog/>
        <DataTableDeleteDialog
          id={selectedData && selectedData.id}
          show={showDialogDelete}
          onHide={closeDialogDelete}
          title="Xóa ứng viên học thử"
          content={
            <div>
              Bạn chắc chắn muốn xóa ứng viên học thử&nbsp;
              <span className="text-danger">
                {selectedData?.candidate?.name}
              </span>
            </div>
          }
        />
        <FormDialog
          show={showSignUpDialog}
          title={`Đăng ký học cho ứng viên: '${selectedData?.candidate?.name}'`}
          initData={{
            candidate: selectedData?.candidate,
            class: classInformation,
            program: classInformation?.program,
            location_branch: classInformation?.location_branch,
            fee: classInformation?.fee,
            document_cost: classInformation?.document_cost,
            start_time: classInformation?.start_time,
            student_id: selectedData?.candidate?.id,
            class_id: null,
            program_id: null,
            location_branch_id: null,
            "discount": 0
          }}
          selectedData={selectedData}
          onHide={closeDialogSignUp}
          onSubmit={signUpLearn}
          ComponentForm={SignUpLearnForm}
        />
        <FormDialog
          show={showDialogForm}
          title={'Đăng ký học thử'}
          initData={{
            candidate: selectedData,
            class: classInformation,
            program: classInformation?.program,
            location_branch: classInformation?.location_branch,
            fee: classInformation?.fee,
            document_cost: classInformation?.document_cost,
            start_time: classInformation?.start_time
          }}
          showSelectCandidate
          selectedData={selectedData}
          onHide={closeDialogForm}
          onSubmit={signUp2Learn}
          ComponentForm={SignUp2LearnForm}
        />
        <DataTableCard/>
      </DataTableProvider>
    </DataTableUIProvider>
  );
}

StudentLearnTry.propTypes = {};

StudentLearnTry.defaultProps = {};

export default StudentLearnTry;

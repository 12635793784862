import viLocale from '@fullcalendar/core/locales/vi';
import enLocale from '@fullcalendar/core/locales/en-gb';

import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/daygrid/main.min.css';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import '@fullcalendar/list/main.min.css';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';

import '@fullcalendar/timegrid/main.min.css';
import Tooltip from '@material-ui/core/Tooltip';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import {Field, Formik} from 'formik';
import moment from "moment";
import { isEqual, cloneDeep } from 'lodash'
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import * as shortid from 'shortid';

import {Card, CardBody} from '../../../../../_theme/_partials/controls';
import {SelectionSearch} from '../../../../../_theme/_partials/controls/forms/SelectionSearch';
import {keyMessages} from '../../../../../_theme/i18n';
import {EDIT} from '../../../../../constants/system/permission';
import {
    CLASS_RESOURCE,
    CLASS_SCHEDULE_RESOURCE,
    LOCATION_BRANCH_RESOURCE,
    PROGRAM_RESOURCE,
    TEACHER_RESOURCE
} from '../../../../../constants/system/resource';
import {processError} from '../../../../../utils/axios';
import {classList} from '../../../../../utils/DOM/class';
import {toDateSrt} from '../../../../../utils/filter';
import {FormDialog} from '../../../../components/form-dialog/FormDialog';
import FormikEffect from '../../../../components/FormikEffect';
import FullScreenWrapper from '../../../../components/FullScreenWrapper';
import {usePermissionContext} from '../../../../components/PermissionContext';
import * as requestFromServer from '../../../common/DataTable/_redux/entitiesCrud';
import {ScheduleEditForm} from './ScheduleFormEdit';
import {createProverItem, ScheduleShortView} from "./ScheduleShortView";
import {EmailSendingForm} from "./EmailSendingForm";

const ScheduleTeacher = (props) => {
    const intl = useIntl();
    const locale = intl?.locale;
    const messages = intl?.messages;
    const [isFullScreen, setFullScreen] = useState(false);
    const permissionContext = usePermissionContext();
    const toggleFullScreen = () => {
        setFullScreen(!isFullScreen);
    };

    const [loading, setLoading] = useState(false);
    const [selectedData, setSelectedData] = useState();
    const [showDialogEdit, setShowDialogEdit] = useState(false);
    const openDialogForm = () => setShowDialogEdit(true);
    const closeDialogForm = () => {
        setSelectedData(null);
        setShowDialogEdit(false);
    };
    const [schedules, setSchedules] = useState([]);
    const refTimeout = useRef();
    const refTimeoutChangeDate = useRef();
    const [filter, setFilter] = useState(() => ([
        {
            operator: "gte",
            property: "start_time",
            value: moment().startOf('week').toDate().getTime()
        },
        {
            operator: "lte",
            property: "end_time",
            value: moment().endOf('week').toDate().getTime()
        }
    ]));
    const [data, setData] = useState({});
    const [openPopper, setOpenPopper] = useState(false);

    const [anchorElPopper, setAnchorElPopper] = useState({});

    const [openSendmailConfirm, setOpenSendmailConfirm] = useState(false);
    const openConfirmEmailForm = () => setOpenSendmailConfirm(true);
    const closeConfirmForm = () => {
        setOpenSendmailConfirm(false);
    };
    const hiddenAllPopper = () => {
        Array.from(document.getElementsByClassName('popover')).forEach((el) => el.hidden = true)
    }

    const fetchData = () => {
        if (refTimeout.current) {
            clearTimeout(refTimeout.current);
        }
        refTimeout.current = setTimeout(() => {
            refTimeout.current = null;
            setLoading(true);
            requestFromServer.findEntities(CLASS_SCHEDULE_RESOURCE, {
                page_size: -1,
                filters: filter
            }).then(rs => {
                setLoading(false);
                hiddenAllPopper();
                if (rs.status === 200 && rs.data?.code === 0) {
                    const events = (rs.data?.data || []).map(d => {
                        const [start, end] = [d.start_time, d.end_time].sort();
                        if (!start || !end) return null;
                        let classE = "";

                        if (end < moment().startOf("day").toDate().getTime()) {
                            classE = "fc-event-danger";
                        } else if (toDateSrt(end) === toDateSrt(Date.now())) {
                            classE = "fc-event-success";
                        } else if (start > moment().endOf("day").toDate().getTime()) {
                            classE = "fc-event-warning"
                        }
                        d.className = `${classE}`;
                        return {
                            id: shortid.generate(),
                            title: `Buổi ${d.index} - ${d.class?.name || ''}`,
                            className: `${classE}`,
                            description: 'Lorem ipsum dolor eius mod tempor labore',
                            start: start,
                            end: end,
                            data: d,
                        }
                    }).filter(f => !!f);
                    setSchedules(events)
                }
            }).catch(e => {
                processError(e);
            });
        }, 300);
    }

    useEffect(() => {
        fetchData();
    }, [filter]);

    const eventContent = (info) => {
        console.log(info);
        const data = info?.event?.extendedProps?.data;
        if (!data) return null;
        const overlay = createProverItem(data, messages);
        return <ScheduleShortView data={data} overlay={overlay} messages={messages} />;
    }

    const eventClick = (eventClickInfo) => {
        setSelectedData(eventClickInfo.event.extendedProps.data);
        openDialogForm();
        hiddenAllPopper();
    }

    const datesSet = (datesSetArg) => {
        const {
            end: activeEnd,
            endStr,
            start: activeStart,
            startStr,
            timeZone,
            view
        } = datesSetArg;
        let filterNew = cloneDeep(filter);
        const startTimeIndex = filter.findIndex(f => f.property === "start_time");
        const startTime = filter?.[startTimeIndex]?.value;
        const endTimeIndex = filter.findIndex(f => f.property === "end_time");
        const endTime = filter?.[endTimeIndex]?.value;
        if (activeStart && (!startTime || startTime !== activeStart?.getTime())) {
            if (startTimeIndex === -1) {
                filterNew = [
                    {
                        operator: "gte",
                        property: "start_time",
                        value: activeStart?.getTime()
                    },
                    ...filterNew
                ];
            } else {
                filterNew = [
                    ...filterNew.slice(0, startTimeIndex),
                    {
                        operator: "gte",
                        property: "start_time",
                        value: activeStart?.getTime()
                    },
                    ...filterNew.slice(startTimeIndex + 1)
                ];
            }
        }

        if (activeEnd && (!endTime || endTime !== activeEnd?.getTime())) {
            if (endTimeIndex === -1) {
                filterNew = [
                    {
                        operator: "lte",
                        property: "end_time",
                        value: activeEnd?.getTime()
                    },
                    ...(cloneDeep(filterNew))
                ];
            } else {
                filterNew = [
                    ...filterNew.slice(0, endTimeIndex),
                    {
                        operator: "lte",
                        property: "end_time",
                        value: activeEnd?.getTime()
                    },
                    ...filterNew.slice(endTimeIndex + 1)
                ];
            }
        }

        if (!isEqual(filterNew, filter)) {
            setFilter(filterNew);
        }
    }

    const handleDateClick = (arg) => { // bind with an arrow function
        console.log(arg.dateStr)
    }

    const updateScheduleClass = value => {
        const data = value.data || {};
        return requestFromServer.updateEntity(CLASS_SCHEDULE_RESOURCE, {
            ...data,
            class_id: data?.class?.id,
            location_branch_id: data?.location_branch?.id,
            teacher_id: data?.teacher?.id,
            room_id: data?.room?.id
        })
    }

    const todayDate = moment().startOf('day');
    const YM = todayDate.format('YYYY-MM');
    const YESTERDAY = todayDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
    const TODAY = todayDate.format('YYYY-MM-DD');
    const TOMORROW = todayDate.clone().add(1, 'day').format('YYYY-MM-DD');
    const changeFilter = (values) => {
        const {
            teacher,
            location_branch,
            program,
            class: classOfTeacher
        } = values;
        const filterCurrent = filter.filter(f =>
            f.property !== "teacher_id" &&
            f.property !== "location_branch_id" &&
            f.property !== "class_id" &&
            f.property !== "program_id"
        );
        if (teacher) {
            filterCurrent.push(
                {
                    operator: "in",
                    property: "teacher_id",
                    value: [teacher?.id]
                }
            );
        }

        if (classOfTeacher) {
            filterCurrent.push(
                {
                    operator: "in",
                    property: "class_id",
                    value: [classOfTeacher?.id]
                }
            );
        }

        if (program) {
            filterCurrent.push(
                {
                    operator: "in",
                    property: "program_id",
                    value: [program?.id]
                }
            );
        }

        if (location_branch) {
            filterCurrent.push(
                {
                    operator: "in",
                    property: "location_branch_id",
                    value: [location_branch?.id]
                }
            );
        }
        setFilter(filterCurrent);
    };
    const initValue = {}
    const isPermissionEdit = permissionContext.checkCanPage('teacher-schedule', EDIT);

    const sendWeekMail = () => {
        setOpenSendmailConfirm(true);
    }

    return (
        <Card>
            <FullScreenWrapper isFullScreen={isFullScreen}>
                <div className="card-header flex-column">
                    <div className="card-title d-flex">
                        <h3 className="d-inline-flex card-label mt-4">Danh sách lịch dạy</h3>
                        <Tooltip title={isFullScreen ? 'Thu gọn' : 'Mở rộng'}>
                            <div
                                onClick={toggleFullScreen}
                                className="d-inline-flex flex-column align-items-center justify-content-center button-custom pointer px-1 ml-auto"
                            >
                                {
                                    isFullScreen &&
                                    (<FullscreenExitIcon
                                        className="pointer"
                                        style={{fontSize: 24, color: "#757575"}}
                                    />)
                                }
                                {
                                    !isFullScreen &&
                                    (<FullscreenIcon
                                        className="pointer"
                                        style={{fontSize: 24, color: "#757575"}}
                                    />)
                                }
                                <div className="line-height-initial">{isFullScreen ? 'Thu gọn' : 'Mở rộng'}</div>
                            </div>
                        </Tooltip>
                    </div>
                    <div className="form-group row my-4 mx-0">
                        <Formik
                            initialValues={initValue}
                        >
                            {({
                                  values,
                                  handleSubmit,
                                  handleBlur,
                                  handleChange,
                                  setFieldValue,
                                  setValues
                              }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    className="form form-label-right d-md-inline-flex"
                                >
                                    <FormikEffect onChange={changeFilter}/>

                                    <div className="form-group row my-0 mr-2">
                                        <div className="col-sm-12 min-w-230">
                                            <Field
                                                name="teacher"
                                                component={SelectionSearch}
                                                resource={TEACHER_RESOURCE}
                                                propertyName="name"
                                                propertyValue="id"
                                                placeholder="Chọn giáo viên"
                                                label=""
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row my-0 mr-2">
                                        <div className="col-sm-12 min-w-230">
                                            <Field
                                                name="location_branch"
                                                component={SelectionSearch}
                                                resource={LOCATION_BRANCH_RESOURCE}
                                                propertyName="name"
                                                propertyValue="id"
                                                placeholder="Chọn chi nhánh"
                                                label=""
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row my-0 mr-2">
                                        <div className="col-sm-12 min-w-230">
                                            <Field
                                                name="program"
                                                component={SelectionSearch}
                                                resource={PROGRAM_RESOURCE}
                                                propertyName="name"
                                                propertyValue="id"
                                                placeholder="Chọn khóa học"
                                                label=""
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row my-0 mr-2">
                                        <div className="col-sm-12 min-w-230">
                                            <Field
                                                name="class"
                                                component={SelectionSearch}
                                                resource={CLASS_RESOURCE}
                                                propertyName="name"
                                                propertyValue="id"
                                                placeholder="Choose class"
                                                label=""
                                            />
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>


                    </div>
                </div>
                {
                    isPermissionEdit &&
                    (<FormDialog
                        size="md"
                        show={showDialogEdit}
                        title="Thiết lập buổi học"
                        initData={{
                            ...(selectedData || {}),
                            id: selectedData?.id,
                            class: selectedData?.class,
                            location_branch: selectedData?.location_branch,
                            teacher: selectedData?.teacher,
                            room: selectedData?.room,
                            class_id: null,
                            location_branch_id: null,
                            teacher_id: null,
                            room_id: null,
                        }}
                        onSuccess={fetchData}
                        selectedData={selectedData}
                        onHide={closeDialogForm}
                        onSubmit={updateScheduleClass}
                        ComponentForm={ScheduleEditForm}
                    />)
                }

                {
                    isPermissionEdit &&
                    (<FormDialog
                        size="md"
                        show={openSendmailConfirm}
                        title="Gửi mail"
                        initData={filter}
                        onSuccess={fetchData}
                        selectedData={selectedData}
                        onHide={closeConfirmForm}
                        onSubmit={sendWeekMail}
                        ComponentForm={EmailSendingForm}
                    />)
                }
                <CardBody>
                    <div className={classList("overlay", loading && "overlay-block")}>
                        {loading && (
                            <div className="overlay-layer zindex-2 bg-transparent">
                                <div className="spinner-border text-success"/>
                            </div>
                        )}
                        <FullCalendar
                            plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]}
                            headerToolbar={{
                                start: 'prev,next today',
                                center: 'title',
                                end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                            }}
                            height={800}
                            contentHeight={750}
                            aspectRatio={3}
                            initialView="timeGridWeek"
                            themeSystem="unthemed"
                            initialDate={TODAY}
                            firstDay={1}
                            views={{
                                dayGridMonth: {
                                    eventMaxStack: 2, // adjust to 6 only for timeGridWeek/timeGridDay
                                    dayMaxEventRows: 2,
                                    dayMaxEvents: 2,
                                    buttonText: messages[keyMessages['MONTH']],
                                },
                                timeGridWeek: {
                                    eventMaxStack: 1, // adjust to 6 only for timeGridWeek/timeGridDay
                                    buttonText: messages[keyMessages['WEEK']]
                                },
                                timeGridDay: {
                                    eventMaxStack: 2, // adjust to 6 only for timeGridWeek/timeGridDay
                                    dayMaxEventRows: 3,
                                    dayMaxEvents: 3,
                                    buttonText: messages[keyMessages['DAY']]
                                },
                                listWeek: {
                                    buttonText: messages[keyMessages['SCHEDULE']]
                                }
                            }}
                            eventMaxStack={3}
                            dayMaxEventRows={5}
                            editable={false}
                            navLinks
                            locales={[viLocale, enLocale]}
                            locale={locale}
                            dateClick={handleDateClick}
                            timeZone="local"
                            events={schedules}
                            eventContent={eventContent}
                            eventClick={eventClick}
                            datesSet={datesSet}
                            // eventMouseLeave={(eventClickInfo) => {
                            //   setOpenPopper(false)
                            //   setAnchorElPopper(null);
                            //   setData({});
                            // }}
                        />
                    </div>
                    <br/>
                    <button
                        type="submit"
                        onClick={() => sendWeekMail()}
                        className="btn btn-danger btn-elevate"
                    >
                        Gửi email tuần
                    </button>
                </CardBody>
            </FullScreenWrapper>
        </Card>
    );
};

ScheduleTeacher.propTypes = {};

ScheduleTeacher.defaultProps = {};

export default ScheduleTeacher;

import Checkbox from "@material-ui/core/Checkbox";
import Drawer from "@material-ui/core/Drawer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useMemo } from "react";
import { Nav } from "react-bootstrap";
import { useDataTableUIContext } from "../DataTableUIContext";

export default function DataTableSettingColumn(props) {
  const { columns, displayColumn } = props;

  const dataTableUIContext = useDataTableUIContext();
  const dataTableUIProps = useMemo(() => {
    return {
      isOpenSettingColumns: dataTableUIContext.isOpenSettingColumns,
      setOpenSettingColumns: dataTableUIContext.setOpenSettingColumns
    };
  }, [dataTableUIContext]);

  const { isOpenSettingColumns, setOpenSettingColumns } = dataTableUIProps;
  const closeSettingColumn = () => setOpenSettingColumns(false);

  return (
    <div>
      <Drawer
        anchor="right"
        open={isOpenSettingColumns}
        onClose={closeSettingColumn}
      >
        <div className="pt-5 pb-10 min-w-md-250px">
          <div className="d-flex align-items-center justify-content-between mb-5">
            <Nav
              as="ul"
              role="tablist"
              className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
            >
              <Nav.Item as="li">
                <Nav.Link eventKey="AuditLogs" className={`font-size-h5 nav-link active`}>
                  Chọn các cột để ẩn
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <div
              className="offcanvas-close mt-n1 pr-5"
              style={{ position: "absolute", top: "15px", right: "10px" }}
              onClick={closeSettingColumn}
            >
              <a
                href="#"
                className="btn btn-xs btn-icon btn-light btn-hover-primary"
                id="kt_quick_panel_close"
              >
                <i className="ki ki-close icon-xs text-muted"></i>
              </a>
            </div>
          </div>
          <div>
            {columns.map((column, index) => {
              const { name, text, hidden = false } = column;
              return (
                <FormControlLabel
                  key={`column-${name}-${index}`}
                  className="mx-0 d-flex"
                  control={
                    <Checkbox
                      disabled={text === "STT"}
                      checked={hidden}
                      onChange={e => {
                        displayColumn(index, e.target.checked)
                      }}
                      name="gilad"
                    />
                  }
                  label={
                    <label className="font-weight-bold font-size-h5 mb-0 mt-1">
                      {text}
                    </label>
                  }
                />
              );
            })}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

// Validation schema
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {Modal} from "react-bootstrap";
import {classList} from "../../../../../../../utils/DOM/class";
import {DatePickerField, Input} from "../../../../../../../_theme/_partials/controls";
import {SelectionSearch} from "../../../../../../../_theme/_partials/controls/forms/SelectionSearch";
import {CLASS_RESOURCE} from "../../../../../../../constants/system/resource";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {TextArea} from "../../../../../../../_theme/_partials/controls/forms/TextArea";
import {MAP_STUDENT_CHANGE_STATUS} from "../../../../../../../constants/MapConvert";
import {useDataTableContext} from "../../../../../common/DataTable/pages/DataTableContext";
import {toDatetime} from "../../../../../../../utils/filter";
import {FieldInputCurrency} from "../../../../../../../_theme/_partials/controls/forms/FieldInputCurrency";

const EditSchema = Yup.object().shape({
  student: Yup.string()
    .required("Học sinh là bắt buộc"),
  num_hour: Yup.number()
    .required("Số tiền là bắt buộc")
    .min(1, "Số tiền phải lớn hơn 0")
});


export function StudentChangeEditForm({save, item, actionsLoading, onHide}) {

  const {classId} = useParams();
  const types = {
    reserve: "reserve",
    transfer: "transfer",
    move_in: "move_in"
  };
  const [isReserve, setIsReserve] = useState(true);
  const [typeChange, setTypeChange] = useState(types.reserve);
  const [isEdit, setIsEdit] = useState(false);
  const dataTableContext = useDataTableContext();

  const onChangeType = (e) => {
    const type = e.target.value;
    setTypeChange(type);
    setIsReserve(type === types.reserve)
  }

  useEffect(() => {
    if (item.id) {
      setTypeChange(item.code);
      setIsReserve(item.code === types.reserve);
      setIsEdit(true);
    }

  }, [item.id])

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item}
        validationSchema={EditSchema}
        onSubmit={values => {
          let entity = {
            id: values.id,
            student_id: values?.student?.id || null,
            class_source_id: parseInt(classId),
            amount: values.amount,
            note: values.note,
            code: typeChange,
            class_id: null,
            date_change: toDatetime(values?.date_change),
            num_hour: values.num_hour
          };

          if (typeChange === types.transfer) {
            if (!values.class) {
              alert("Bạn chưa chọn lớp chuyển đến");
              return;
            }
            if(values.class.id !== parseInt(classId)) {
              let message = "Bạn có chắc muốn chuyển học sinh " + values.student.name + " đến lớp " + values.class.name + " !!!"
              if (!window.confirm(message)) {
                return;
              }
            }
            else{
              alert("Học sinh này hiện tại đã học ở lớp này !!!");
              return;
            }

            entity = {
              ...entity,
              class_id: values?.class?.id || null,
            };
          }

          console.log(entity);
          save(entity);
        }}
      >
        {({handleSubmit}) => (
          <>
            <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
              <Form className="form form-label-right">
                <span hidden={isEdit}>
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <Field
                        name="student"
                        required
                        component={SelectionSearch}
                        resource={CLASS_RESOURCE + `/${classId}/student-info`}
                        propertyName="name"
                        propertyValue="id"
                        placeholder="Chọn học sinh"
                        label="Học sinh"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <div>Loại thay đổi<span className="text-danger">*</span></div>
                      <select className="form-control" onChange={onChangeType} name="type_change">
                        <option value="reserve" defaultChecked={true}>Bảo lưu</option>
                        <option value="transfer">Chuyển lớp</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <DatePickerField
                          required
                          name="date_change"
                          label="Ngày thay đổi"
                      />
                    </div>
                  </div>
                </span>
                <span hidden={!isEdit}>
                  <div>
                    <span
                      className="font-weight-bold font-size-lg">Tên học sinh: </span> {item.student && (item.student.name)}
                    <span className="float-right">
                        <span
                          className="font-weight-bold font-size-lg">Loại chuyển đổi: </span> {MAP_STUDENT_CHANGE_STATUS[item.code]}
                        </span>
                  </div>
                  <br/>
                </span>
                <div className="form-group row" hidden={isReserve}>
                  <div className="col-sm-12">
                    <Field
                      name="class"
                      required
                      component={SelectionSearch}
                      resource={CLASS_RESOURCE}
                      propertyName="name"
                      propertyValue="id"
                      placeholder="Chọn lớp chuyển đến"
                      label="Lớp chuyển đến"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-6">
                    <Field
                      name="amount"
                      type="number"
                      component={FieldInputCurrency}
                      placeholder="Số tiền được bảo lưu/chuyển lớp"
                      label="Số tiền(VNĐ)"
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                        name="num_hour"
                        type="number"
                        component={Input}
                        placeholder="Số giờ bảo lưu"
                        label="Số giờ bảo lưu"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="note"
                      component={TextArea}
                      placeholder="Nhập ghi chú"
                      label="Ghi chú"
                    />
                  </div>
                </div>

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Hủy
              </button>
              <> </>
              <button
                type="submit"
                disabled={actionsLoading}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                {actionsLoading ? "Đang lưu..." : "Lưu lại"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

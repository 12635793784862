import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { toAbsoluteUrl } from "../../../_theme/_helpers";
import { ChangeEmailForm } from './ChangeEmailForm';
import { ChangePasswordForm } from "./ChangePasswordForm";
import axios from "axios";
import { USER_SYSTEM_RESOURCE } from "../../../constants/system/resource";
import SettingsIcon from '@material-ui/icons/Settings';
import { UserProfileEditForm } from "./UserProfileEditForm";
import {useIntl} from "react-intl";
import {keyMessages} from "../../../_theme/i18n";
import Badge from "react-bootstrap/Badge";

const UserProfile = () => {

  const intl = useIntl();
  const messages = intl?.messages;
  const userProfile = useSelector(state => state?.auth?.user)
  const [showDialogChangePass, setShowDialogChangePass] = useState(false);
  const [showDialogChangeEmail, setShowDialogChangeEmail] = useState(false);
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  // useEffect(() => {
  //   axios.get(`${USER_SYSTEM_RESOURCE}/profile`)
  //     .then(res => {
  //       setUserProfile(res.data.data);
  //     })
  //     .catch(err => console.log(err));
  // }, [isUpdate]);

  const closeDialogChangePass = () => {
    setShowDialogChangePass(false);
  }

  const openDialogChangePass = () => {
    setShowDialogChangePass(true);
  }

  const closeDialogChangeEmail = () => {
    setShowDialogChangeEmail(false);
  }

  const openDialogChangeEmail = () => {
    setShowDialogChangeEmail(true);
  }

  const closeDialogUpdate = () => {
    setShowDialogUpdate(false);
    setIsUpdate(!isUpdate);
  }

  const openDialogUpdate = () => {
    setShowDialogUpdate(true);
  }

  return (
    <div className="flex-row-fluid ml-lg-8">
      <ChangePasswordForm
        dataInit={{
          "id": userProfile.id,
          "old_password": "",
          "password": ""
        }}
        isShow={showDialogChangePass}
        onHide={closeDialogChangePass}
      />
      <UserProfileEditForm
        dataInit={userProfile}
        isShow={showDialogUpdate}
        onHide={closeDialogUpdate}
      />
      <ChangeEmailForm
        dataInit={userProfile}
        isShow={showDialogChangeEmail}
        onHide={closeDialogChangeEmail}
      />
      {/*begin::Card*/}
      <div className="card card-custom card-stretch">
        {/*begin::Header*/}
        <div className="card-header py-3">
          <div className="card-title align-items-center flex-column">
            <h3 className="card-label font-weight-bolder text-dark">{ messages[keyMessages["PROFILE.PERSONAL"]]}</h3>
          </div>
          <div className="card-toolbar">
            <button
              className="btn btn-success"
              onClick={openDialogUpdate}
            >
              <SettingsIcon/>
            </button>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Form*/}
        <form className="form">
          {/*begin::Body*/}
          <div className="card-body">
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">Avatar</label>
              <div className="col-lg-9 col-xl-6">
                <div
                  className="image-input image-input-outline" id="kt_profile_avatar"
                  style={{
                    backgroundImage: 'url(/media/users/blank.png)',
                    borderRadius: '50%'
                  }}
                >
                  <div
                    className="image-input-wrapper"
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(userProfile?.avatar || "/media/users/default.jpg")})`,
                      borderRadius: '50%'
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">{ messages[keyMessages["PROFILE.NAME"]]}</label>
              <div className="col-lg-9 col-xl-6">
                <input disabled className="form-control form-control-lg form-control-solid" type="text"
                       defaultValue={userProfile.name}/>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">{ messages[keyMessages["PROFILE.EMAIL"]]}</label>
              <div className="col-lg-9 col-xl-6">
                <input disabled className="form-control form-control-lg form-control-solid" type="text"
                       defaultValue={userProfile.email}/>
                <a
                  href="#"
                  className="text-sm font-weight-bold"
                  onClick={openDialogChangeEmail}
                >
                  { messages[keyMessages["PROFILE.CHANGE_EMAIL"]]}
                </a>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">{ messages[keyMessages["PROFILE.PASSWORD"]]}</label>
              <div className="col-lg-9 col-xl-6">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold btn-sm"
                  onClick={openDialogChangePass}
                >
                  { messages[keyMessages["PROFILE.CHANGE_PASSWORD"]]}
                </button>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">{ messages[keyMessages["PROFILE.PHONE"]]}</label>
              <div className="col-lg-9 col-xl-6 col-form-label">
                {userProfile.phone || ""}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">{ messages[keyMessages["PROFILE.BRAND"]]}</label>
              <div className="col-lg-9 col-xl-6 col-form-label">
                {
                  userProfile?.location_branches &&
                  userProfile?.location_branches.map((location, index) => {
                    return (
                      <Badge variant="success" key={`location-${index}-${location?.id}`} className="font-weight-bold">{location.name}</Badge>
                    )
                  })
                }
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">{ messages[keyMessages["PROFILE.ROLE"]]}</label>
              <div className="col-lg-9 col-xl-6 col-form-label">
                {
                  userProfile?.roles &&
                  userProfile.roles.map((role, index) => {
                    return (
                      <div key={`role-${index}-${role?.id}`} className="font-weight-bold">{role.name}</div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          {/*end::Body*/}
        </form>
        {/*end::Form*/}
      </div>
    </div>
  );
};

UserProfile.propTypes = {};

UserProfile.defaultProps = {};

export default UserProfile;

import flatten from 'flat';
import { isEmpty } from './typeof';
import {htmlRegexG} from '../constants/regex'
export const replaceTextPattern = (text) => {
  return text?.replace(/\{([\w\W\d\D^\}]+)\}/gm, '<code class="px-1">$1</code>');
}

export const convertPatternToHTML = (item) =>  {
  // let htmlContent = item.text;
  // if (item.key) {
  //   htmlContent = item.text.replace(
  //     `{${item.key}}`,
  //     `<code class="px-1">${item.key}</code>`
  //   );
  // }

  let htmlContent = replaceTextPattern(item.text);

  if (item.tags) {
    htmlContent = item.tags.reduce((accumulator, tag) => {
      return `<${tag}>${accumulator}</${tag}>`;
    }, htmlContent);
  }

  return htmlContent;
}

export const convertMessageToHTML = (item) =>  {
  let htmlContent = item.text;

  if (item.tags) {
    htmlContent = item.tags.reduce((accumulator, tag) => {
      return `<${tag}>${accumulator}</${tag}>`;
    }, htmlContent);
  }

  return htmlContent;
}

export const interpolationString = (template, data = {}) => {
  const dataFlat = flatten(data);
  return template.replace(/\${([^}]+)}/g, (match, group) => {
    return dataFlat[group.trim()]
  });
}

export function truncate(value, length) {
  if (!isEmpty(value)) {
    if (value?.length <= length) return value;

    const index = value.indexOf(" ", length);
    if (index === -1) {
      return `${value.substring(0, length)}...`
    } else {
      return `${value.substring(0, index)}...`
    }
  }
  return "";
}
export const formatHtml = (text) => {
  return text.replace(htmlRegexG,' ').replace(/\s+/g,' ')
}
export const parseJSON = (value) => {
  try{
          if(isEmpty(value)){
            return  null; 
          }
          else {
            return JSON.parse(value)
          }
      }catch(err){
          return err
      }
}